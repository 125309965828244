import React, { useState, useEffect, useRef } from 'react';
import './GenomeReportGeneration.css';
import { faFileCsv, faTrashAlt, faCheckCircle, faSpinner, faPause, faX, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

function GenomeReportGeneration({ user }) {
  const [processList, setProcessList] = useState([]);
  const [editableList, setEditableList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newRowIndex, setNewRowIndex] = useState(null);
  const [errorRows, setErrorRows] = useState([]);
  const [availableReports, setAvailableReports] = useState([]);
  const fileInputRef = useRef(null);
  const [reportType, setReportType] = useState('Genomic Report');

  const tableRef = useRef(null);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      let workbook;
      if (file.name.endsWith('.csv')) {
        workbook = XLSX.read(data, { type: 'string' });
      } else {
        workbook = XLSX.read(data, { type: 'binary' });
      }
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 }).slice(1); // Skip the header row
  
      const newEntries = rows.map((row) => {
        const [firstName, lastName, recipient, genomicReport] = row;
        return { firstName, lastName, status: 'Pending', recipient, genomicReport: (genomicReport || '').trim() };
      });
  
      setEditableList((prevList) => [...prevList, ...newEntries]);
  
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };
  
    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  
  useEffect(() => {
    const thead = tableRef.current.querySelector('thead');
    thead.classList.add('floating-header');
  }, []);

  useEffect(() => {
    const fetchProcessList = () => {
      fetch(`https://service3.szapfs.org/process-list-endpoint?clinicianId=${user.id}`)
        .then(response => response.json())
        .then(data => setProcessList(data))
        .catch(error => console.error('Error fetching process list:', error));
    };

    const fetchAvailableReports = () => {
      fetch('https://service3.szapfs.org/available-reports')
        .then(response => response.json())
        .then(data => setAvailableReports(data))
        .catch(error => console.error('Error fetching available reports:', error));
    };

    fetchProcessList();
    fetchAvailableReports();
    const interval = setInterval(fetchProcessList, 1000);

    return () => clearInterval(interval);
  }, [user.id]);

  const addRow = () => {
    setEditableList(prevList => {
      const newList = [...prevList, { firstName: '', lastName: '', status: 'Pending', recipient: '', genomicReport: '' }];
      setNewRowIndex(newList.length - 1);
      return newList;
    });
  };

  useEffect(() => {
    if (newRowIndex !== null) {
      tableRef.current.scrollTo({
        top: tableRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setNewRowIndex(null);
    }
  }, [editableList, newRowIndex]);

  const handleRemoveRow = (index) => {
    const newList = editableList.filter((_, i) => i !== index);
    setEditableList(newList);
    setErrorRows(prevErrorRows => prevErrorRows.filter(rowIndex => rowIndex !== index));
  };

  const handleGenerate = () => {
    const validEntries = [];
    const invalidEntries = [];
  
    editableList.forEach((process, index) => {
      if (availableReports.includes(process.genomicReport)) {
        validEntries.push({
          patientEmail: '',
          recipients: [process.recipient || user.email],
          clinicianId: user.id,
          reportType: reportType,
          patientFirstName: process.firstName,
          patientLastName: process.lastName,
          genomicReport: process.genomicReport
        });
      } else {
        invalidEntries.push(index);
      }
    });
  
    if (validEntries.length > 0) {
      fetch('https://service3.szapfs.org/submitPRESETReportRequest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(validEntries)
      })
        .then(response => response.json())
        .then(result => {
          console.log('Report request submitted:', result);
          setEditableList(prevList => prevList.filter((_, index) => invalidEntries.includes(index)));
          setErrorRows(invalidEntries);
          if (invalidEntries.length === 0 && editableList.length > 0) {
            tableRef.current.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
        })
        .catch(error => console.error('Error submitting report request:', error));
    } else if (invalidEntries.length > 0) {
      setErrorRows(invalidEntries);
    }
  };
  
  const getLanguageIcon = (ReportType) => {
    switch (ReportType) {
      case 'Genomic Report':
        return (
          <div className="language_ind">
🇬🇧

            <div className="statustextcg">English</div>
          </div>
        );
        case 'Genomic Report (KOR)':
        return (
          <div className="language_ind">
           🇰🇷
            <div className="statustextcg">Korean</div>
          </div>
        );

        return (
          <div className="statuscontr">
            <FontAwesomeIcon icon={faX} color="red" />
            <div className="statustextr">Failed</div>
          </div>
        );
      default:
        return null;
    }
  };


  

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Done':
        return (
          <div className="statuscont">
            <FontAwesomeIcon icon={faCheckCircle} color="green" />
            <div className="statustextcg">Done</div>
          </div>
        );
      case 'Processing':
        return (
          <div className="statusconto">
            <FontAwesomeIcon icon={faSpinner} spin color="orange" />
            <div className="statustextco">Processing</div>
          </div>
        );
      case 'Pending':
        return (
          <div className="statuscontgr">
            <div className="statustextgr">Pending</div>
          </div>
        );
      case 'Failed':
        return (
          <div className="statuscontr">
            <FontAwesomeIcon icon={faX} color="red" />
            <div className="statustextr">Failed</div>
          </div>
        );
      default:
        return null;
    }
  };





  const filteredProcessList = processList
  .filter(process =>
    process.PatientFirstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    process.PatientLastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (process.Recipients && process.Recipients.join(', ').toLowerCase().includes(searchQuery.toLowerCase())) ||
    (process.Preset && process.Preset.toLowerCase().includes(searchQuery.toLowerCase()))
  )
  .sort((a, b) => {
    const priorityOrder = ['Processing', 'Pending', 'Done', 'Failed'];
    const statusComparison = priorityOrder.indexOf(a.Status) - priorityOrder.indexOf(b.Status);

    if (statusComparison !== 0) {
      return statusComparison;
    }

    if (a.Status === 'Done' && b.Status === 'Done') {
      const dateA = new Date(a.DateTimeInitialized);
      const dateB = new Date(b.DateTimeInitialized);
      return dateB - dateA; // Most recent Date first
    }

    return 0;
  });


  const totalReports = processList.length;
  const doneReports = processList.filter(process => process.Status === 'Done').length;
  const completionPercentage = totalReports > 0 ? (doneReports / totalReports) * 100 : 0;

  return (
    <div className="genome-container">
      <div className="process-search-bar">
        <input
          type="text"
          placeholder="Search Processes"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="process-list-space" ref={tableRef}>
        <table className="process-table" >
          <thead className="floating-header">
            <tr>
              <th style={{ width: '7%' }}>Status</th>
              <th style={{ width: '10%' }}>Date</th>
              <th style={{ width: '10%' }}>First Name</th>
              <th style={{ width: '10%' }}>Last Name</th>
              <th style={{ width: '25%', textAlign: 'start' }}>Recipient Email</th>
              <th style={{ width: '15%' }}>Genomic Report</th>
              <th style={{ width: '15%' }}>Language</th>

            </tr>
          </thead>
          <tbody>
            {filteredProcessList.length > 0 ? (
              filteredProcessList.map((process, index) => (
                <tr key={index}>
                  <td>{getStatusIcon(process.Status)}</td>
                  <td>{format(new Date(process.DateTimeInitialized), 'yyyy-MM-dd HH:mm')}</td>
                  <td>{process.PatientFirstName}</td>
                  <td>{process.PatientLastName}</td>
                  <td className="wide-column" style={{ textAlign: 'start', justifyContent: 'start', alignItems: 'start' }}>{process.Recipients ? process.Recipients.join(', ') : ''}</td>
                  <td className="wide-column">{process.Preset}</td>
                  <td >{getLanguageIcon(process.ReportType)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No processes</td>
              </tr>
            )}
            {editableList.map((process, index) => (
              <tr key={index} className={index === newRowIndex ? 'new-row' : ''}>
                <td>
                  <button className="remove-row-btn" onClick={() => handleRemoveRow(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
                <td>{process.DateTimeInitialized ? format(new Date(process.DateTimeInitialized), 'yyyy-MM-dd HH:mm') : ''}</td>
                <td>
                  <input
                    type="text"
                    value={process.firstName}
                    onChange={e => {
                      const newList = [...editableList];
                      newList[index].firstName = e.target.value;
                      setEditableList(newList);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={process.lastName}
                    onChange={e => {
                      const newList = [...editableList];
                      newList[index].lastName = e.target.value;
                      setEditableList(newList);
                    }}
                  />
                </td>
                <td className="wide-column" style={{ textAlign: 'start', justifyContent: 'start', alignItems: 'start' }}>
                  <input
                    type="email"
                    value={process.recipient}
                    onChange={e => {
                      const newList = [...editableList];
                      newList[index].recipient = e.target.value;
                      setEditableList(newList);
                    }}
                    placeholder={user.email}
                  />
                </td>
                <td className="wide-column">
                  <input
                    list={`genomicReports-${index}`}
                    value={process.genomicReport}
                    onChange={e => {
                      const newList = [...editableList];
                      newList[index].genomicReport = e.target.value;
                      setEditableList(newList);
                    }}
                    className={errorRows.includes(index) ? 'error-input' : ''}
                  />


                  <datalist id={`genomicReports-${index}`}>
                    {availableReports.map(report => (
                      <option key={report} value={report}>{report}</option>
                    ))}
                  </datalist>
                  
                  {availableReports.includes(process.genomicReport) ? (
  <FontAwesomeIcon icon={faCheck} color="green" className="checkmarkstatusgene" />
) : (
  process.genomicReport && (
    <FontAwesomeIcon icon={faTimes} color="red" className="checkmarkstatusgene" />
  )
)}


      
                </td>
                <td>{process.DateTimeInitialized ? format(new Date(process.DateTimeInitialized), 'yyyy-MM-dd HH:mm') : ''}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="control-button-space">
        <div className="endbuttonspace">
          <button className="add-patient-btn-" onClick={addRow}>Add Row</button>
          <label className="csvbuttonfile">
            <FontAwesomeIcon icon={faFileCsv} />
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              className="csv-input"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
          </label>
        </div>
        <div className="Loadingpart">

        <div className="EndLoading">
          <div className="LoadingbarContainer" style={{ marginRight: '10px' }}>
            <div className="Loadingbar" style={{ width: `${completionPercentage}%`, marginRight: '10px' }}>
              <div className="percentageindicator">
                <div className="percentage">
                  {completionPercentage.toFixed(0)}%
                </div>
                <div className="fraction">
                  ({doneReports}/{totalReports})
                </div>
              </div>
            </div>
          </div>

          </div>

          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className="report-type-dropdown"
          >
            <option value="Genomic Report">English</option>
            <option value="Genomic Report (KOR)">Korean</option>
          </select>
          <button className="add-patient-btn-" onClick={handleGenerate}>Generate</button>
        </div>


      </div>
    </div>
  );
}

export default GenomeReportGeneration;
