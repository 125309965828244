
import React from 'react';
import './transcriptomics.css';

function Transcriptomics({ searchQuery }) {
  return (
    <div className="Transcriptomics">
      {/* Transcriptomics-related content */}
      <p>Transcriptomics content based on search: {searchQuery}</p>
    </div>
  );
}

export default Transcriptomics;
