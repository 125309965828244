import React, { useState, useEffect } from 'react';
import './run.css';

const Run = ({ data, onClose }) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const calculateAF = async () => {
      try {
        const response = await fetch('https://service3.szapfs.org/calculate-af', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ variant: data }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setResult(result);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
      }
    };

    if (data) {
      calculateAF();
    }
  }, [data]);

  return (
    <div className="run-modal">
      <div className="run-modal-content">
        <div className="run-modal-header">
          <span className="close" onClick={onClose}>&times;</span>
          <h4>Run Information</h4>
          <span className="close" onClick={onClose}></span>

        </div>
        <div className="run-modal-body">
          {error ? (
            <p>Error: {error}</p>
          ) : result ? (
            <>
              <p>Variant: {result.variant}</p>
              <p>Allele Frequency: {result.alleleFrequency}%</p>
              <p>Affected IDs: {result.affectedIDs.join(', ')}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Run;