import React, { useState, useEffect } from 'react';
import './viewResultsModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faFileCsv, faFolderClosed, faHome, faUserFriends } from '@fortawesome/free-solid-svg-icons';

const ViewResultsModal = ({ onClose, initialTableName }) => {
  const [tables, setTables] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTable, setSelectedTable] = useState(initialTableName || null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableSearchTerm, setTableSearchTerm] = useState(''); // New state for table-specific search term

  const columns = [
    "ClinVar_CLNSIG", "SZAreportCategory", "Zygosity", "Genotype",
    "Gene.Disease.confidence.level", "Target.group", "Genes", "Disease",
    "X.CHROM", "POS", "REF", "ALT", "QUAL", "INFO", "FORMAT", "P0064_WGS_4",
    "Consequence", "IMPACT", "EXON", "INTRON", "HGVSc.NBCI.format.",
    "HGVSp", "cDNA_position", "Existing_variation.only.the.rsID.", "SIFT",
    "PolyPhen", "HGVS_OFFSET", "AF", "gnomADe_AF..latest.version.for.all.population..",
    "gnomADe_AFR_AF", "gnomADe_AMR_AF", "gnomADe_ASJ_AF", "gnomADe_EAS_AF",
    "gnomADe_FIN_AF", "gnomADe_NFE_AF", "gnomADe_OTH_AF", "gnomADe_SAS_AF",
    "gnomADg_AF", "gnomADg_AFR_AF", "gnomADg_AMI_AF", "gnomADg_AMR_AF",
    "gnomADg_ASJ_AF", "gnomADg_EAS_AF", "gnomADg_FIN_AF", "gnomADg_MID_AF",
    "gnomADg_NFE_AF", "gnomADg_OTH_AF", "gnomADg_SAS_AF", "MAX_AF",
    "MAX_AF_POPS", "CLIN_SIG", "PHENO", "ada_score", "rf_score", "REVEL",
    "BayesDel_addAF_pred", "BayesDel_addAF_rankscore", "BayesDel_addAF_score",
    "BayesDel_noAF_pred", "BayesDel_noAF_rankscore", "BayesDel_noAF_score",
    "REVEL_rankscore", "REVEL_score", "SpliceAI_pred_DP_AG", "SpliceAI_pred_DP_AL",
    "SpliceAI_pred_DP_DG", "SpliceAI_pred_DP_DL", "SpliceAI_pred_DS_AG",
    "SpliceAI_pred_DS_AL", "SpliceAI_pred_DS_DG", "SpliceAI_pred_DS_DL",
    "Inhouse.AF", "Inheritances", "DiseaseInfo"
  ];

  useEffect(() => {
    fetchTables();
    if (initialTableName) {
      fetchTableData(initialTableName);
    } else {
      setLoading(false);
    }
  }, [initialTableName]);

  const fetchTables = async () => {
    try {
      const response = await fetch('https://service3.szapfs.org/getTables');
      const data = await response.json();
      setTables(data);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };

  const fetchTableData = async (tableName) => {
    setLoading(true);
    try {
      const response = await fetch('https://service3.szapfs.org/getTableData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tableName }),
      });
      const data = await response.json();
      setTableData(data);
      setSelectedTable(tableName);
    } catch (error) {
      console.error('Error fetching table data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableClick = (tableName) => {
    fetchTableData(tableName);
  };

  const handleTableSearch = (e) => {
    setTableSearchTerm(e.target.value);
  };

  const filteredTables = tables.filter(table =>
    table.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredTableData = tableData.filter(row =>
    columns.some(column => row[column]?.toString().toLowerCase().includes(tableSearchTerm.toLowerCase()))
  );
  const downloadCSV = () => {
    const csvRows = [];
  
    // Add the headers
    const headers = columns.filter(col => tableData[0].hasOwnProperty(col));
    csvRows.push(headers.join(','));
  
    // Add the rows
    tableData.forEach(row => {
      const values = headers.map(header => `"${row[header] || ''}"`);
      csvRows.push(values.join(','));
    });
  
    // Create CSV string
    const csvString = csvRows.join('\n');
  
    // Create a blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });
  
    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${selectedTable}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  
  return (
    <div className="viewallgenes-modal-overlay">
      <div className="viewallgenes-modal-content">
        <button className="viewallgenes-modal-close-button" onClick={onClose}>X</button>
        <div className="viewallgenes-modal-body">
          {loading ? (
            <p>Loading...</p>
          ) : !selectedTable ? (
            <>
              <div className="VATOP">
                <input
                  type="text"
                  placeholder="Search tables..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="viewallgenes-search-bar"
                />
              </div>
              <div className="VABOT">
                {filteredTables.map((table, index) => (
                  <div key={index} className="patienttablecontainerkeep">
                    <ul className="viewallgenes-table-item" onClick={() => handleTableClick(table)}>
                      {table}
                    </ul>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="VGT">
                <button className="V1" onClick={() => setSelectedTable(null)}>Back</button>
                <h3 className="V2">{selectedTable}</h3>
                <input
                  type="text"
                  placeholder="Search in table..."
                  value={tableSearchTerm}
                  onChange={handleTableSearch}
                  className="viewallgenes-search-bar"
                />
<h3 className="V2CSV" onClick={downloadCSV}>
  <FontAwesomeIcon icon={faFileCsv} />
</h3>
              </div>
              <div className="viewallgenes-table-container">
                {filteredTableData && filteredTableData.length > 0 ? (
                  <table className="viewallgenes-data-table">
                    <thead>
                      <tr>
                        {columns.filter(col => tableData[0].hasOwnProperty(col)).map((column, index) => (
                          <th key={index} className="viewallgenes-table-header">{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTableData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="viewallgenes-table-row">
                          {columns.filter(col => row.hasOwnProperty(col)).map((column, cellIndex) => (
                            <td key={cellIndex} className="viewallgenes-table-cell">{row[column]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No data matches your search.</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewResultsModal;
