import React from 'react';
import './proteomics.css';

function Proteomics({ searchQuery }) {
  return (
    <div className="Proteomics">
      {/* Proteomics-related content */}
      <p>Proteomics content based on search: {searchQuery}</p>
    </div>
  );
}

export default Proteomics;
