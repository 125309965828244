import React, { useState } from 'react';
import './Discoveromics.css';
import logo from '../2222.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faBacteria, faFlask, faTooth, faAtom, faChartLine } from '@fortawesome/free-solid-svg-icons';
import Genomics from './genomics/genomics';
import Gut from './gut/gut';
import Metabolomics from './metabolomics/metabolomics';
import Oral from './oral/oral';
import Proteomics from './proteomics/proteomics';
import Transcriptomics from './transcriptomics/transcriptomics';
function Discoveromics() {
  const [inputValue, setInputValue] = useState('');
  const [searchQueries, setSearchQueries] = useState([]);
  const [selectedOmics, setSelectedOmics] = useState('genomics'); // Default to genomics

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      addSearchQuery(inputValue.trim());
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && inputValue === '' && searchQueries.length > 0) {
      // Remove the last tag if Backspace is pressed and input is empty
      setSearchQueries(searchQueries.slice(0, -1));
    }
  };

  const addSearchQuery = (query) => {
    setSearchQueries([...searchQueries, query]);
    setInputValue(''); // Clear the input field after adding the query
  };

  const removeQuery = (index) => {
    setSearchQueries(searchQueries.filter((_, i) => i !== index));
  };

  const renderSelectedOmics = () => {
    switch (selectedOmics) {
      case 'genomics':
        return <Genomics searchQuery={searchQueries} addSearchQuery={addSearchQuery} />;
      case 'gut':
        return <Gut searchQuery={searchQueries} />;
      case 'metabolomics':
        return <Metabolomics searchQuery={searchQueries} />;
      case 'oral':
        return <Oral searchQuery={searchQueries} />;
      case 'proteomics':
        return <Proteomics searchQuery={searchQueries} />;
      case 'transcriptomics':
        return <Transcriptomics searchQuery={searchQueries} />;
      default:
        return <Genomics searchQuery={searchQueries} addSearchQuery={addSearchQuery} />; // Fallback to genomics
    }
  };

  const renderButton = (omicType, icon, label) => (
    <button
      className={`DisI ${selectedOmics === omicType ? 'selected' : 'un'}`}
      onClick={() => setSelectedOmics(omicType)}
    >
      <FontAwesomeIcon icon={icon} className="icon" />
      {label}
    </button>
  );

  const renderQueries = () => (
    <div className="query-container">
      {searchQueries.map((query, index) => (
        <div key={index} className="query-tag">
          {query}
          <span className="remove-query" onClick={() => removeQuery(index)}>×</span>
        </div>
      ))}
      <input
        className="query-input"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        placeholder="Search DiscoverOmics..."
      />
    </div>
  );

  return (
    <div className="Discover">
      <div className="DiscoverTop">
        <div className="Dis1">
          <img src={logo} alt="Logo" />
        </div>

        <div className="Dis2">
          {renderQueries()}
        </div>
        
        <div className="Dis3">
          {renderButton('genomics', faDna, 'Genomics')}
          {renderButton('gut', faBacteria, 'Gut')}
          {renderButton('metabolomics', faFlask, 'Metabolomics')}
          {renderButton('oral', faTooth, 'Oral')}
          {renderButton('proteomics', faAtom, 'Proteomics')}
          {renderButton('transcriptomics', faChartLine, 'Transcriptomics')}
        </div>
      </div>
      <div className="DiscoverBottom">
        <div className="DiscoverC">
          {renderSelectedOmics()}
        </div>
      </div>
    </div>
  );
}

export default Discoveromics;
