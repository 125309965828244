import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDna, 
  faLaptopCode,
  faDiagramProject,
  faFlask,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import './Sections.css';
import './About.css';
import KTH from '../Partners/kthlogo.png';
import Kings from '../Partners/kings.png';
import Acibadem from '../Partners/acibadem.png';
import Ataturk from '../Partners/Ataturk.png';

const AboutSection = () => {
  return (
    <section id="about" className="section about-section">
      <div className="section-content">
        <motion.div 
          className="about-container"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="about-text">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="about-title"
            >
              Our Partners
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="about-description"
            >
              SZA Portal is a comprehensive platform designed for advanced multi-omics data analysis 
              and integration. Our suite of tools empowers researchers to unlock deeper insights 
              from complex biological datasets, accelerating discoveries in genomics, transcriptomics, 
              and proteomics research.
            </motion.p>
          </div>
          
          <div className="stats-grid">
            <motion.div 
              className="stat-item"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
          <img src={KTH} className="partnerimg" />
          <h3 className="partnerimgtitle">KTH Royal Institute of Technology</h3>
            </motion.div>
            
            <motion.div 
              className="stat-item"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <img src={Kings} className="partnerimg" />
              <h3 className="partnerimgtitle">King’s College London</h3>
            </motion.div>
            <motion.div 
              className="stat-item"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <img src={Acibadem} className="partnerimg" />
              <h3 className="partnerimgtitle">Acibadem Healthcare Group</h3>
            </motion.div>
            

            <motion.div 
              className="stat-item"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <img src={Ataturk} className="partnerimg" />
              <h3 className="partnerimgtitle">Atatürk University</h3>
            </motion.div>

   



          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutSection;
