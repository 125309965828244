import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUpload, 
  faFile, 
  faSpinner, 
  faCheck, 
  faTimes,
  faFilePdf,
  faHome,
  faCode
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import szaLogo from '../2222.png';
import './GeneRiskPro.css';

const GeneRiskPro = () => {
  const [processes, setProcesses] = useState([
    { 
      id: 1, 
      name: 'patient_tumor_sample.vcf', 
      status: 'running',
      progress: 45,
      message: 'Processing variants...',
      timeRemaining: '3 min',
      rawData: '##fileformat=VCFv4.2\n#CHROM\tPOS\tID\tREF\tALT\tQUAL\tFILTER\tINFO\nchr1\t69511\trs62636508\tA\tG\t100\tPASS\tAF=0.342'
    },
    { 
      id: 2, 
      name: 'BRCA1_analysis.vcf', 
      status: 'completed',
      progress: 100,
      message: 'Analysis complete',
      timeRemaining: '0 min',
      rawData: '##fileformat=VCFv4.2\n#CHROM\tPOS\tID\tREF\tALT\tQUAL\tFILTER\tINFO\nBRCA1\t69511\trs62636508\tA\tG\t100\tPASS\tAF=0.342'
    },
    { 
      id: 3, 
      name: 'WGS_sample_001.vcf', 
      status: 'running',
      progress: 78,
      message: 'Analyzing structural variants',
      timeRemaining: '8 min',
      rawData: '##fileformat=VCFv4.2\n#CHROM\tPOS\tID\tREF\tALT\tQUAL\tFILTER\tINFO\nchr2\t69511\trs62636508\tA\tG\t100\tPASS\tAF=0.342'
    },
    { 
      id: 4, 
      name: 'exome_cancer_panel.vcf', 
      status: 'completed',
      progress: 100,
      message: 'Analysis complete',
      timeRemaining: '0 min',
      rawData: '##fileformat=VCFv4.2\n#CHROM\tPOS\tID\tREF\tALT\tQUAL\tFILTER\tINFO\nchr17\t69511\trs62636508\tA\tG\t100\tPASS\tAF=0.342'
    },
    { 
      id: 5, 
      name: 'germline_variants.vcf', 
      status: 'running',
      progress: 23,
      message: 'Filtering variants',
      timeRemaining: '15 min',
      rawData: '##fileformat=VCFv4.2\n#CHROM\tPOS\tID\tREF\tALT\tQUAL\tFILTER\tINFO\nchr5\t69511\trs62636508\tA\tG\t100\tPASS\tAF=0.342'
    }
  ]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [viewMode, setViewMode] = useState('pdf');

  const handleDrop = (e) => {
    e.preventDefault();
    // Handle file drop
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'pdf' ? 'raw' : 'pdf');
  };

  return (
    <div className="grp-container">
      <div className="grp-header">
        <div className="grp-header-content">
          <img src={szaLogo} alt="SZA Logo" className="grp-logo" />
          <Link to="/" className="grp-home-link">
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </Link>
        </div>
      </div>

      <div className="grp-main">
        <div className="grp-left-panel">
          <div className="grp-title-section">
            <h1>GeneRiskPro Analysis</h1>
            <p>Advanced genomic variant analysis platform</p>
          </div>

          <div className="grp-top-section">
            <div className="grp-section-header">
              <div className="grp-section-title">
                <FontAwesomeIcon icon={faSpinner} />
                <span>Active Processes</span>
              </div>
              <button className="grp-upload-button">
                <FontAwesomeIcon icon={faUpload} />
                <span>Upload VCF</span>
              </button>
            </div>

            <div className="grp-process-list">
              {processes.map(process => (
                <div 
                  key={process.id} 
                  className={`grp-process-item ${process.status === 'completed' ? 'clickable' : ''} ${selectedFile?.id === process.id ? 'selected' : ''}`}
                  onClick={() => process.status === 'completed' && setSelectedFile(process)}
                >
                  <div className="grp-process-info">
                    <div className="grp-process-header">
                      <div className="grp-process-name-status">
                        <div className={`grp-process-status grp-status-${process.status}`} />
                        <span className="grp-process-name">{process.name}</span>
                      </div>
                      {process.status === 'running' && (
                        <div className="grp-process-time">
                          {process.timeRemaining}
                        </div>
                      )}
                      {process.status === 'completed' && (
                        <FontAwesomeIcon icon={faCheck} className="grp-icon-success" />
                      )}
                    </div>
                    <div className="grp-process-message">{process.message}</div>
                    <div className="grp-progress-bar">
                      <div 
                        className="grp-progress-fill" 
                        style={{ width: `${process.progress}%` }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grp-right-panel">
          <div className="grp-viewer-section">
            {selectedFile ? (
              <>
                <div className="grp-viewer-header">
                  <h2>{selectedFile.name}</h2>
                  <button className="grp-view-toggle" onClick={toggleViewMode}>
                    <FontAwesomeIcon icon={viewMode === 'pdf' ? faCode : faFilePdf} />
                    {viewMode === 'pdf' ? 'View Raw Data' : 'View PDF Report'}
                  </button>
                </div>
                {viewMode === 'pdf' ? (
                  <div className="grp-pdf-container">
                    <iframe 
                      src="/sample-report.pdf"
                      className="grp-pdf-viewer"
                      title="PDF Viewer"
                    />
                  </div>
                ) : (
                  <div className="grp-raw-viewer">
                    <pre>{selectedFile.rawData}</pre>
                  </div>
                )}
              </>
            ) : (
              <div className="grp-no-file">
                <FontAwesomeIcon icon={faFile} />
                <p>Select a completed analysis to view results</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneRiskPro;
