// Orders.js
import React from 'react';
import './Orders.css';
import email from './emailphoto.png';

function Communications() {
  return (
    <div>
            <div className="Orders">
      <div className="ordercard">Unauthorized</div>

    </div>
    </div>
  );
}

export default Communications;
