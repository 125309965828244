import React, { useState, useEffect } from 'react';
import './RunMutation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faFileCsv, faPen, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const RunMutation = ({ mutation, closeRunMutationModal }) => {
    const [results, setResults] = useState([]);
    const [displayResults, setDisplayResults] = useState([]);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalFound, setTotalFound] = useState(0);
    const [overlappingFound, setOverlappingFound] = useState(0);
    const [error, setError] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [editedMutation, setEditedMutation] = useState({
        chr: mutation.chr,
        start: mutation.start,
        end: mutation.end,
    });
    const [currentMutation, setCurrentMutation] = useState({
        chr: mutation.chr,
        start: mutation.start,
        end: mutation.end,
    });

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                console.log('Fetching data for mutation:', currentMutation);
                const response = await fetch('https://service3.szapfs.org/api/query-variants', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        chrom: 'chr' + currentMutation.chr,
                        start_pos: currentMutation.start,
                        end_pos: currentMutation.end
                    }),
                });
            
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
    
                let buffer = '';
                let allResults = [];
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) break;
                    
                    buffer += decoder.decode(value, { stream: true });
                    const lines = buffer.split('\n');
                    buffer = lines.pop();
                    
                    lines.forEach(line => {
                        if (line) {
                            try {
                                const data = JSON.parse(line);
                                console.log('Received data:', data);
                                if (data.progress) {
                                    setProgress(data.progress);
                                } else if (data.results) {
                                    console.log('Adding results:', data.results.length);
                                    allResults = allResults.concat(data.results);
                                    setResults(allResults);
                                    setDisplayResults(allResults.slice(0, 100));
                                    setTotalFound(data.totalFound);
                                    setOverlappingFound(data.overlappingFound);
                                }
                            } catch (e) {
                                console.error('Error parsing line:', line, e);
                            }
                        }
                    });
                }
    
                if (buffer) {
                    try {
                        const data = JSON.parse(buffer);
                        console.log('Final data:', data);
                        if (data.results) {
                            allResults = allResults.concat(data.results);
                            setResults(allResults);
                            setDisplayResults(allResults.slice(0, 100));
                            setTotalFound(data.totalFound);
                            setOverlappingFound(data.overlappingFound);
                        }
                    } catch (e) {
                        console.error('Error parsing final buffer:', buffer, e);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data. Please try again.');
            }
            setLoading(false);
        };
    
        fetchData();
    }, [currentMutation]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        setCurrentMutation({ ...editedMutation });
    };

    const handleCancelClick = () => {
        setEditedMutation({ ...currentMutation });
        setIsEditing(false);
    };

    const handleChange = (e) => {
        setEditedMutation({
            ...editedMutation,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setDisplayResults(results.slice(0, 100));
        } else {
            const filteredResults = results.filter(variant =>
                Object.values(variant).some(value =>
                    String(value).toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            setDisplayResults(filteredResults.slice(0, 100));
        }
    }, [searchQuery, results]);

    const escapeCSV = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        field = String(field);
        if (field.includes('"') || field.includes(',') || field.includes('\n')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    };

    const exportToCSV = () => {
        const headers = ['#CHROM', 'POS', 'ID', 'REF', 'ALT', 'QUAL', 'FILTER', 'INFO', 'FORMAT', 'SampleData', 'Patient'];
        const csvData = [
            headers.join(','), // Header row
            ...results.map(variant => [
                escapeCSV(variant['#CHROM']),
                escapeCSV(variant.POS),
                escapeCSV(variant.ID),
                escapeCSV(variant.REF),
                escapeCSV(variant.ALT),
                escapeCSV(variant.QUAL),
                escapeCSV(variant.FILTER),
                escapeCSV(variant.INFO),
                escapeCSV(variant.FORMAT),
                escapeCSV(variant.SampleData),
                escapeCSV(variant.Patient)
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const fileName = `chr${editedMutation.chr}_${editedMutation.start}-${editedMutation.end}.csv`;
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="RunMutOverlay" onClick={closeRunMutationModal}></div>
            <div className="RunMutModal">
            <div className="RunMutContent">
                    <div className="RMCT">
                        <div onClick={closeRunMutationModal} className="RunMutClose">X</div>

                        <div className="RunCenter"> 

                        {isEditing ? (
                            <>
                                      <div className="RunMutCancel" onClick={handleCancelClick}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                                <input
                                    className="RunMutInput"
                                    type="text"
                                    name="chr"
                                    value={editedMutation.chr}
                                    onChange={handleChange}
                                />
                                :
                                <input
                                    className="RunMutInput"
                                    type="text"
                                    name="start"
                                    value={editedMutation.start}
                                    onChange={handleChange}
                                />
                                -
                                <input
                                    className="RunMutInput"
                                    type="text"
                                    name="end"
                                    value={editedMutation.end}
                                    onChange={handleChange}
                                />
                                <div className="RunMutSave" onClick={handleSaveClick}>
                                    <FontAwesomeIcon icon={faSave} />
                                </div>
                      
                            </>
                        ) : (
                            <>
                                    <div className="RunMutEdit" onClick={handleEditClick}>
                                    <FontAwesomeIcon icon={faPen} />
                                </div>
                                <div className="RunMutClose">chr{currentMutation.chr}:{currentMutation.start}-{currentMutation.end}</div>
                        
                            </>
                        )}

                        </div>


                        <div className="RunMutContentR">
                        <div className="RMCBLTotalResults">
                                    ({overlappingFound}) Results Found
                                </div>
                        <div 
  className={`RunMutCSV ${loading ? 'disabled' : ''}`} 
  onClick={loading ? null : exportToCSV}
  style={{ 
    color: loading ? 'gray' : '#20558a', 
    cursor: loading ? 'not-allowed' : 'pointer' 
  }}>
    <FontAwesomeIcon icon={faFileCsv} />
</div>

</div>


                    </div>
                    <div className="RMCB">                    
                        <div className="RMCBL">    
                            <div className="RMCBLU">                  
                                <div className="RunMutItemTitle">Pathogenicity</div>
                                <div className="RunMutItemContent">{String(mutation.pathogenicity).charAt(0).toUpperCase() + String(mutation.pathogenicity).slice(1)}</div>
                                <div className="RunMutItemTitle">Genotype</div>
                                <div className="RunMutItemContent">{String(mutation.genotype).charAt(0).toUpperCase() + String(mutation.genotype).slice(1)}</div>
                                <div className="RunMutItemTitle">Inheritance</div>
                                <div className="RunMutItemContent">{String(mutation.inheritance).charAt(0).toUpperCase() + String(mutation.inheritance).slice(1)}</div>
                                <div className="RunMutItemTitle">Decipher ID</div>
                                <div className="RunMutItemContent">{String(mutation.variant_id).charAt(0).toUpperCase() + String(mutation.variant_id).slice(1)}</div>
                                <div className="RunMutItemTitle">Patient ID</div>
                                <div className="RunMutItemContent">{String(mutation.patient_id).charAt(0).toUpperCase() + String(mutation.patient_id).slice(1)}</div>
                                <div className="RunMutItemTitle">Variant Class</div>
                                <div className="RunMutItemContent">{String(mutation.variant_class).charAt(0).toUpperCase() + String(mutation.variant_class).slice(1)}</div>
                            </div>
                            <div className="RMCBLB">
                                <div className="RMCBLBU">Total Files</div>
                                <div className="RMCBLBU1"> 
                                    <div className="RMCBLBU1I">1994</div>
                                </div>
                                <div className="RMBL1"> 
                                    <div className="RMBL2">Total SV Files</div>
                                    <div className="RMBL2">Total CNV Files</div>
                                </div>
                                <div className="RMBL3"> 
                                    <div className="RMR1">997</div>
                                    <div className="RMR1">997</div>
                                </div>
  
                            </div>
                        </div>
                        <div className="RMCBR">
                            <div className="RMCBRT">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="RMCBRS"
                                />
                            </div>
                            <div className="RMCBRB">
                                {loading ? (
                                    <div className="RMLOADING">
                                        <div className="RMLOADINGIN">
                                            <div className="RMLOADINGIN3"></div>
                                            <div className="RMLOADINGIN2" style={{ width: `${progress}%` }}>
                                                <div className="RMLOADINGIN1">{progress}%</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <table className="mutsearchtable">
                                            <thead>
                                                <tr>
                                                    <th>#CHROM</th>
                                                    <th>POS</th>
                                                    <th>ID</th>
                                                    <th>REF</th>
                                                    <th>ALT</th>
                                                    <th>QUAL</th>
                                                    <th>FILTER</th>
                                                    <th>INFO</th>
                                                    <th>FORMAT</th>
                                                    <th>SampleData</th>
                                                    <th>Patient</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayResults.map((variant, index) => (
                                                    <tr key={index}>
                                                        <td>{variant['#CHROM']}</td>
                                                        <td>{variant.POS}</td>
                                                        <td>{variant.ID}</td>
                                                        <td>{variant.REF}</td>
                                                        <td>{variant.ALT}</td>
                                                        <td>{variant.QUAL}</td>
                                                        <td>{variant.FILTER}</td>
                                                        <td>{variant.INFO}</td>
                                                        <td>{variant.FORMAT}</td>
                                                        <td>{variant.SampleData}</td>
                                                        <td>{variant.Patient}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RunMutation;
