import React from 'react';
import './HomePage.css';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashCalendar from './DashCalendar';

  const data = [
    { name: '1/2/2024', uv: 40 },
    { name: '2/2/2024', uv: 30 },
    { name: '3/2/2024', uv: 20 },
    { name: '4/2/2024', uv: 20 },
    { name: '5/2/2024', uv: 10 },
    { name: '6/2/2024', uv: 20 },
    { name: '7/2/2024', uv: 30 },
  ];

  const legends = [
    { title: "Untested", color: "#6288AD" }, 
    { title: "Received", color: "#4C77A1" },
    { title: "Processing", color: "#366695" },
    { title: "Delivered", color: "#20558A" }  
  ];
  
  const sections = [
    { patients: 50, color: legends[0].color },
    { patients: 25, color: legends[1].color },
    { patients: 25, color: legends[2].color },
    { patients: 37, color: legends[3].color }
  ];
  
  const totalPatients = sections.reduce((total, section) => total + section.patients, 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#20558a', padding: '10px', border: '1px solid #fff', borderRadius: '5px', textAlign: 'center' }}>
          <div style={{ color: '#fff', fontSize: '11px' }}>{`${payload[0].value} registrations`}</div>
          <div style={{ color: 'lightgray', fontSize: '10px', marginTop: '3px' }}>{label}</div>
        </div>
      );
    }
    return null; 
  };
  
  const currentHour = new Date().getHours();
  const greeting = currentHour < 12 ? 'Good morning' : 'Good afternoon';

function HomePage({ user }) {   
    return (
      <div className="dash-container">

      </div>
    );
  }
  
  export default HomePage;
  