import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

const Counter = ({ from, to, duration = 1 }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false
  });
  
  const [phase, setPhase] = useState(1);
  
  useEffect(() => {
    if (inView) {
      setPhase(1);
      const timer = setTimeout(() => {
        setPhase(2);
      }, duration * 1000);
      return () => clearTimeout(timer);
    } else {
      setPhase(1);
    }
  }, [inView, duration]);

  const firstPhase = useSpring({
    from: { number: from },
    number: inView ? 10500 : from,
    config: { duration: duration * 1000 }
  });

  const secondPhase = useSpring({
    from: { number: 10500 },
    number: phase === 2 ? 10547 : 10500,
    config: { duration: 25000 }
  });

  return (
    <animated.span ref={ref}>
      {phase === 1 
        ? firstPhase.number.to(n => Math.floor(n).toLocaleString())
        : secondPhase.number.to(n => Math.floor(n).toLocaleString())
      }
    </animated.span>
  );
};

export default Counter;
