import React from 'react';
import './ecol.css';
import Obox from './Obox';
import MutationItem from './MutationItem';

const ECOL = ({ geneData }) => {
  if (!geneData) return <div className="ECOL">Loading...</div>;
  const { additional_data } = geneData;

  return (
    <div className="ECOL">
      <div className="ECOLWR">
        <div className='ECOLTOP'>
          <div className='ECOLTOPL'>      
            <div className='ECOLTOPLC'>{geneData.current_hgnc_symbol}</div>
          </div>
          <div className='ECOLTOPR'>   
            <div className='ECOLTOPRM'>{geneData.ensembl_gene_ensg}</div>
            <div className='ECOLTOPRT'>{geneData.chr}:{geneData.start}-{geneData.end}</div>
            <div className='ECOLTOPRT'>{geneData.hgnc_description}</div>
          </div>
        </div>



        <div className='ECOLHGMD'>      



        <div className='ECOLMID'>
          {additional_data.gene_info && additional_data.gene_info[0] && (
     <div className='ECOLMIDT'>


<div className='ECOLMIDTB'>         
<div className='ECOLMIDTButton'> View cDNA </div>

    
    <div className='ECOLMIDTButton'> Genomic Sequence </div>
    




</div>
<Obox title="Alias" content={additional_data.gene_info[0]['Gene Symbol'].split(": ")[1].slice(0, -1)}/>
            
<Obox title="Location" content={additional_data.gene_info[0]['Location']}/>
<Obox title="Protein Coding" content= {geneData.is_protein_coding ? 'Yes' : 'No'}/>
<Obox title="Strand" content={geneData.is_reverse_strand ? 'Reverse' : 'Forward'}/>

<div className='ETT'>Mutations</div>






     {additional_data.cdna_sequence && (
         <div>{additional_data.cdna_sequence.slice(0, 50).map(codon => codon.cDNA).join('')}</div>
     )}
 
     {additional_data.external_links && (
         <ul>
             {additional_data.external_links.slice(0, 5).map((link, index) => (
                 <li key={index}>{link[`External links - ${geneData.current_hgnc_symbol}`]}</li>
             ))}
         </ul>
     )}
 
     <div>Gene Ontology (first 3)</div>
     {additional_data.gene_ontology && (
         <ul>
             {additional_data.gene_ontology.slice(0, 3).map((go, index) => (
                 <li key={index}>{go['Ontology term']}: {go['Ontology type']}</li>
             ))}
         </ul>
     )}
 </div>
 
          )}


        </div>

        <div className='ECOLBOT'>
        <div className='ECOLBOTM'>
      {additional_data.mutations && additional_data.mutations.length > 0 && (
        <div className='ECOLBOTMF'>
          {additional_data.mutations.map((mutation, index) => (
            <MutationItem key={index} mutation={mutation} />
          ))}
        </div>
      )}
    </div>
   

        <div className='ECOLBOTL'>        </div>


        </div>
        </div>


      </div>
    </div>
  );
};

export default ECOL;