// ClinicalInsights.js
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './ClinicalInsights.css';  // Ensure your CSS styles are defined as per provided examples
function BioParameterCard({ title, value, unit, onValueChange, date, HRange1, HRange2, handleEditClick }) {
    // Convert the value and ranges to numbers for comparison
    const numericValue = Number(value);
    const numericHRange1 = Number(HRange1);
    const numericHRange2 = Number(HRange2);
    const isValueWithinRange = numericValue >= numericHRange1 && numericValue <= numericHRange2;

    const statusIcon = isValueWithinRange ? '✅' : '⛔'; // Replace with actual icons

    return (
        <div className="card-container">
            <div className="card-title">
        {title} ({unit})   
            </div>
            <div className="card-body">
            <div className="card-contentt">
  {value ? (
    <>
    </>
  ) : (
    ''
  )}{value} <span onClick={() => handleEditClick(title, value, onValueChange, 'bioParameter')} className="edit-shi">✎</span>



</div>


            </div>
            <div className="card-footer">
                <div className="card-footer-left">
                    <span className="healthy-range">{HRange1} - {HRange2}      {value ? (
    <>
          {statusIcon}

    </>
  ) : (
    ''
  )}
</span>
                </div>
                <div className="card-footer-right">
                    {date && <span className="card-date">{date}</span>}
                </div>
            </div>
        </div>
    );
}



function ClinicalInsights({ userId }) {
    const [ethnicity, setEthnicity] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [occupation, setOccupation] = useState('');
    const [ethnicityDate, setEthnicityDate] = useState('');
    const [maritalStatusDate, setMaritalStatusDate] = useState('');
    const [occupationDate, setOccupationDate] = useState('');
    const [familyHealthHistory, setFamilyHealthHistory] = useState('');
    const [personalMedicalHistory, setPersonalMedicalHistory] = useState('');
        const [familyHealthHistoryDate, setFamilyHealthHistoryDate] = useState('');
    const [personalMedicalHistoryDate, setPersonalMedicalHistoryDate] = useState('');
        const [alcoholConsumption, setAlcoholConsumption] = useState('');
    const [tobaccoUse, setTobaccoUse] = useState('');
    const [stressLevels, setStressLevels] = useState('');
    const [travelHistory, setTravelHistory] = useState('');
        const [alcoholConsumptionDate, setAlcoholConsumptionDate] = useState('');
    const [tobaccoUseDate, setTobaccoUseDate] = useState('');
    const [stressLevelsDate, setStressLevelsDate] = useState('');
    const [travelHistoryDate, setTravelHistoryDate] = useState('');
    const [height, setHeight] = useState('');
const [weight, setWeight] = useState('');
const [bmi, setBmi] = useState('');
const [bloodPressure, setBloodPressure] = useState('');
const [pulseRate, setPulseRate] = useState('');
const [bodyTemperature, setBodyTemperature] = useState('');
const [hormoneLevels, setHormoneLevels] = useState('');
const [vitaminDLevel, setVitaminDLevel] = useState('');
const [ironLevel, setIronLevel] = useState('');
const [heightDate, setHeightDate] = useState('');
const [weightDate, setWeightDate] = useState('');
const [bmiDate, setBmiDate] = useState('');
const [bloodPressureDate, setBloodPressureDate] = useState('');
const [pulseRateDate, setPulseRateDate] = useState('');
const [bodyTemperatureDate, setBodyTemperatureDate] = useState('');
const [hormoneLevelsDate, setHormoneLevelsDate] = useState('');
const [vitaminDLevelDate, setVitaminDLevelDate] = useState('');
const [ironLevelDate, setIronLevelDate] = useState('');
const [cbc, setCbc] = useState('');
const [bloodLipidProfile, setBloodLipidProfile] = useState('');
const [fastingBloodGlucose, setFastingBloodGlucose] = useState('');
const [liverFunctionTests, setLiverFunctionTests] = useState('');
const [cbcDate, setCbcDate] = useState('');
const [bloodLipidProfileDate, setBloodLipidProfileDate] = useState('');
const [fastingBloodGlucoseDate, setFastingBloodGlucoseDate] = useState('');
const [liverFunctionTestsDate, setLiverFunctionTestsDate] = useState('');
const [educationLevel, setEducationLevel] = useState('');

const [educationLevelDate, setEducationLevelDate] = useState('');
function CustomModal({ show, title, initialValue, onSave, onClose }) {
  const [localValue, setLocalValue] = useState(initialValue);

  useEffect(() => {
      setLocalValue(initialValue);
  }, [initialValue]);

  const handleSave = () => {
      onSave(localValue);
      onClose();
      
  };

  if (!show) return null;

  return (
      <div className="custom-modal-backdrop">
          <div className="custom-modal">
              <div className="custom-modal-header">
                  <h3>{title}</h3>
                  <button onClick={onClose} className="custom-modal-close-button">✖</button>
              </div>
              <div className="custom-modal-body">
                  <input type="text" value={localValue} onChange={e => setLocalValue(e.target.value)} className="custom-modal-input"/>
              </div>
              <div className="custom-modal-footer">
                  <button onClick={onClose} className="custom-modal-footer-button">Close</button>
                  <button onClick={handleSave} className="custom-modal-footer-button primary">Save Changes</button>
              </div>
          </div>
      </div>
  );
}



const [modalInfo, setModalInfo] = useState({ show: false, title: '', value: '', setValue: null, type: null });

    useEffect(() => {
        fetchClinicalInsights();
    }, []);

    const fetchClinicalInsights = async () => {
      try {
          const response = await fetch(`https://service3.szapfs.org/getClinicalInsights?userID=${userId}`);
          if (response.ok) {
              const data = await response.json();
              const latestEntries = {};
  
              data.forEach((entry) => {
                  const metric = entry.Metric;
                  if (!latestEntries.hasOwnProperty(metric) || new Date(latestEntries[metric].DateTimeCreated) < new Date(entry.DateTimeCreated)) {
                      latestEntries[metric] = {
                          value: entry.Value,
                          date: entry.DateTimeCreated,
                      };
                  }
              });
  
              setEthnicity(latestEntries['Ethnicity']?.value || '');
              setMaritalStatus(latestEntries['Marital Status']?.value || '');
              setOccupation(latestEntries['Occupation']?.value || '');
              setFamilyHealthHistory(latestEntries['Family Health History']?.value || '');
              setPersonalMedicalHistory(latestEntries['Personal Medical History']?.value || '');
              setAlcoholConsumption(latestEntries['Alcohol Consumption']?.value || '');
              setTobaccoUse(latestEntries['Tobacco Use']?.value || '');
              setStressLevels(latestEntries['Stress Levels']?.value || '');
              setTravelHistory(latestEntries['Travel History']?.value || '');
              setHeight(latestEntries['Height']?.value || '');
              setWeight(latestEntries['Weight']?.value || '');
              setBmi(latestEntries['BMI']?.value || '');
              setBloodPressure(latestEntries['Blood Pressure']?.value || '');
              setPulseRate(latestEntries['Pulse Rate']?.value || '');
              setBodyTemperature(latestEntries['Body Temperature']?.value || '');
              setHormoneLevels(latestEntries['Hormone Levels']?.value || '');
              setVitaminDLevel(latestEntries['Vitamin D Level']?.value || '');
              setIronLevel(latestEntries['Iron Level']?.value || '');
              setCbc(latestEntries['CBC']?.value || '');
              setBloodLipidProfile(latestEntries['Blood Lipid Profile']?.value || '');
              setFastingBloodGlucose(latestEntries['Fasting Blood Glucose']?.value || '');
              setLiverFunctionTests(latestEntries['Liver Function Tests']?.value || '');
              setEducationLevel(latestEntries['Education Level']?.value || '');
  
              setEthnicityDate(new Date(latestEntries['Ethnicity']?.date).toLocaleDateString() || '');
              setMaritalStatusDate(new Date(latestEntries['Marital Status']?.date).toLocaleDateString() || '');
              setOccupationDate(new Date(latestEntries['Occupation']?.date).toLocaleDateString() || '');
              setFamilyHealthHistoryDate(new Date(latestEntries['Family Health History']?.date).toLocaleDateString() || '');
              setPersonalMedicalHistoryDate(new Date(latestEntries['Personal Medical History']?.date).toLocaleDateString() || '');
              setAlcoholConsumptionDate(new Date(latestEntries['Alcohol Consumption']?.date).toLocaleDateString() || '');
              setTobaccoUseDate(new Date(latestEntries['Tobacco Use']?.date).toLocaleDateString() || '');
              setStressLevelsDate(new Date(latestEntries['Stress Levels']?.date).toLocaleDateString() || '');
              setTravelHistoryDate(new Date(latestEntries['Travel History']?.date).toLocaleDateString() || '');
              setHeightDate(new Date(latestEntries['Height']?.date).toLocaleDateString() || '');
              setWeightDate(new Date(latestEntries['Weight']?.date).toLocaleDateString() || '');
              setBmiDate(new Date(latestEntries['BMI']?.date).toLocaleDateString() || '');
              setBloodPressureDate(new Date(latestEntries['Blood Pressure']?.date).toLocaleDateString() || '');
              setPulseRateDate(new Date(latestEntries['Pulse Rate']?.date).toLocaleDateString() || '');
              setBodyTemperatureDate(new Date(latestEntries['Body Temperature']?.date).toLocaleDateString() || '');
              setHormoneLevelsDate(new Date(latestEntries['Hormone Levels']?.date).toLocaleDateString() || '');
              setVitaminDLevelDate(new Date(latestEntries['Vitamin D Level']?.date).toLocaleDateString() || '');
              setIronLevelDate(new Date(latestEntries['Iron Level']?.date).toLocaleDateString() || '');
              setCbcDate(new Date(latestEntries['CBC']?.date).toLocaleDateString() || '');
              setBloodLipidProfileDate(new Date(latestEntries['Blood Lipid Profile']?.date).toLocaleDateString() || '');
              setFastingBloodGlucoseDate(new Date(latestEntries['Fasting Blood Glucose']?.date).toLocaleDateString() || '');
              setLiverFunctionTestsDate(new Date(latestEntries['Liver Function Tests']?.date).toLocaleDateString() || '');
              setEducationLevelDate(new Date(latestEntries['Education Level']?.date).toLocaleDateString() || '');
          } else {
              toast.error('Failed to fetch clinical insights');
          }
      } catch (error) {
          toast.error(`Error fetching clinical insights: ${error}`);
      }
    };
    const [bioParameters, setBioParameters] = useState([]);
    useEffect(() => {
        fetchClinicalInsights();
        fetchBioParameters();
    }, []);
    const fetchBioParameters = async () => {
        try {
            // Fetch the bio parameters
            const paramResponse = await fetch('https://service3.szapfs.org/getClinicalBioParameters');
            let bioParams = paramResponse.ok ? await paramResponse.json() : [];
    
            // Fetch the bio values for the current user
            const valueResponse = await fetch(`https://service3.szapfs.org/getClinicalBioValues?userID=${userId}`);
            let bioValues = valueResponse.ok ? await valueResponse.json() : [];
    
            // Create a map to track the most recent value for each ClinicalVariable
            const latestBioValues = new Map();
            bioValues.forEach(value => {
                if (!latestBioValues.has(value.ClinicalVariable)) {
                    latestBioValues.set(value.ClinicalVariable, value);
                }
            });
    
            // Integrate the most recent values with parameters
            const integratedParams = bioParams.map(param => {
                const matchedValue = latestBioValues.get(param.ClinicalVariables);
                return {
                    ...param,
                    value: matchedValue ? matchedValue.Value : '',
                    date: matchedValue ? new Date(matchedValue.Date).toLocaleDateString() : ''
                };
            });
    
            // Update state with integrated data
            setBioParameters(integratedParams);
    
        } catch (error) {
            toast.error(`Error fetching bio parameters and values: ${error}`);
        }
    };
    
    const submitBioMetric = async (userId, date, clinicalVariable, value) => {
        try {
            const response = await fetch('https://service3.szapfs.org/insertClinicalBioValue', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    User_ID: userId,
                    Date: date,
                    ClinicalVariable: clinicalVariable,
                    Value: value,
                })
            });
    
            if (response.ok) {
                toast.success('Bio parameter value updated successfully.');
                fetchBioParameters(); // Refetch to update UI
            } else {
                const errorMsg = await response.text();
                toast.error(`Failed to update bio parameter value: ${errorMsg}`);
            }
        } catch (error) {
            toast.error(`Error updating bio parameter value: ${error}`);
        }
    };
    
    
    const submitSingleMetric = async (metricName, value) => {
        try {
            const response = await fetch('https://service3.szapfs.org/updateSingleClinicalInsight', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userID: userId,
                    metric: metricName,
                    value: value,
                })
            });

            if (response.ok) {
                toast.success('Clinical insight updated successfully.');
            } else {
                const errorMsg = await response.text();
                toast.error(`Failed to update clinical insight: ${errorMsg}`);
            }
        } catch (error) {
            toast.error(`Error submitting clinical insight: ${error}`);
        }
    };

    const handleEditClick = (title, value, setValue, type) => {
        setModalInfo({ show: true, title, value, setValue, type });
    };
    
    const handleCloseModal = () => {
        setModalInfo({ ...modalInfo, show: false });
    };
    const handleSaveModalValue = (newValue) => {
        const { title, setValue, type } = modalInfo;
        setValue(newValue); // Set the new state value
        handleCloseModal();
        if (type === 'bioParameter') {
            submitBioMetric(userId, new Date().toISOString().split('T')[0], title, newValue); // Assuming we use today's date for the bio metric
        } else {
            submitSingleMetric(title, newValue);
        }
    };
    
    function ClinicalCard({ title, value, unit, onValueChange, date }) {
      const displayText = value ? `${value} ` : '';
      const unitDisplay = unit ? ` (${unit})` : ''; 
      return (
          <div className="card-container">
              <div className="card-title" onClick={() => handleEditClick(title, value, onValueChange)}>
                  {title}{unitDisplay}
                  <span className="edit-icon">✎</span>
              </div>
              <div className="card-body">
                  <div className="card-content">
                      <span className={displayText ? 'card-text-filled' : 'card-text-empty'}>
                          {displayText}
                      </span>
                  </div>
              </div>
              {date !== "Invalid Date" && <div className="card-date">{date}</div>}
          </div>
      );
  }
  
    return (
        <div className='clinicalstuff'>
<div>

<div>
    <div className="group-title">Personal and Demographic Information</div>
    
    <div className="group three-per-row">
        <ClinicalCard title="Height" value={height} unit="cm" onValueChange={setHeight} date={heightDate} />
        <ClinicalCard title="Weight" value={weight} unit="kg" onValueChange={setWeight} date={weightDate} />
        <ClinicalCard title="BMI" value={bmi} unit="" onValueChange={setBmi} date={bmiDate} />

    </div>
    <div className="group">
        <ClinicalCard title="Marital Status" value={maritalStatus} unit="" onValueChange={setMaritalStatus} date={maritalStatusDate} />
        <ClinicalCard title="Education Level" value={educationLevel} unit="" onValueChange={setEducationLevel} date={educationLevelDate} />
        <ClinicalCard title="Ethnicity" value={ethnicity} unit="" onValueChange={setEthnicity} date={ethnicityDate} />

        
    </div>
    <div className="group">
        <ClinicalCard title="Family Health History" value={familyHealthHistory} unit="" onValueChange={setFamilyHealthHistory} date={familyHealthHistoryDate} />
        <ClinicalCard title="Personal Medical History" value={personalMedicalHistory} unit="" onValueChange={setPersonalMedicalHistory} date={personalMedicalHistoryDate} />
        <ClinicalCard title="Alcohol Consumption" value={alcoholConsumption} unit="" onValueChange={setAlcoholConsumption} date={alcoholConsumptionDate} />
        <ClinicalCard title="Tobacco Use" value={tobaccoUse} unit="" onValueChange={setTobaccoUse} date={tobaccoUseDate} />
    </div>
    <div className="group-title">Clinical Parameters</div>

    <div className='bioparameters'>
    {bioParameters.map((param, index) => (
        <div key={param.ParameterID} className={`bio-parameter-card ${index % 4 === 0 ? 'start' : ''}`}>
            <BioParameterCard 
                title={param.ClinicalVariables}
                value={param.value}
                unit={param.Unit}
                onValueChange={newValue => submitSingleMetric(param.ClinicalVariables, newValue)}
                date={param.date}
                HRange1={param.HRange1}
                HRange2={param.HRange2}
                    handleEditClick={handleEditClick}

            />
        </div>
    ))}
</div>






</div>
</div>

<CustomModal
    show={modalInfo.show}
    title={modalInfo.title}
    initialValue={modalInfo.value}
    onSave={handleSaveModalValue}
    onClose={handleCloseModal}
/>
        </div>
    );
}

export default ClinicalInsights;