import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import logo from './2222.png';
import Cookies from 'js-cookie';

function OncologyLanding() {
    const navigate = useNavigate();
    const token = Cookies.get('token');
    
    return (
        <div className="landing-container">
            <nav className="landing-nav">
                <img src={logo} alt="Logo" className="landing-logo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
                <div className="nav-buttons">
                    <button onClick={() => navigate('/')} className="back-btn">Back to Home</button>
                    <button onClick={() => navigate(token ? '/dashboard' : '/login')} className="login-btn">
                        {token ? 'Access Portal' : 'Login'}
                    </button>
                </div>
            </nav>
            
            <main className="landing-main">
                <h1>Oncology Tools</h1>
                <p className="landing-description">
                    Advanced cancer diagnostics and treatment planning tools designed for healthcare professionals
                </p>
                
                <div className="features-section">
                    <div className="feature-card">
                        <h3>Diagnostic Support</h3>
                        <p>AI-powered diagnostic assistance and pattern recognition</p>
                    </div>
                    <div className="feature-card">
                        <h3>Treatment Planning</h3>
                        <p>Comprehensive treatment planning and monitoring tools</p>
                    </div>
                    <div className="feature-card">
                        <h3>Research Integration</h3>
                        <p>Access to latest research and clinical trials</p>
                    </div>
                </div>
                
                <button onClick={() => navigate(token ? '/dashboard' : '/login')} className="cta-button">
                    {token ? 'Access Your Portal' : 'Login to Access Full Features'}
                </button>
            </main>
        </div>
    );
}

export default OncologyLanding;