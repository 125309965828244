import React, { useState, useCallback, useEffect } from 'react';
import './Invitepatients.css';
import { faDeleteLeft, faFileCsv, faRemove, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';

  
function Invitepatients() {
  const [selectedTab, setSelectedTab] = useState('invite'); // 'invite' or 'pending'
  const [patients, setPatients] = useState([]);
  const [pendingPatients, setPendingPatients] = useState([]);
  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await fetch('https://service3.szapfs.org/platformGetPatients');
      if (response.ok) {
        const data = await response.json();
        const filteredPatients = data.filter(pendingp => 
            !pendingp.Password && pendingp.First_Name && pendingp.Last_Name
            && pendingp.First_Name.trim() !== '' && pendingp.Last_Name.trim() !== ''
          );
          setPendingPatients(filteredPatients);        
          setPendingPatients(filteredPatients);
      } else {
        console.error('Failed to fetch patients');
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  // Helper function to get initials
  const getInitials = (firstName, lastName) => {
    // Ensure both firstName and lastName are not null, undefined, or empty strings
    const initials = `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;
    return initials.toUpperCase(); // Optionally, make initials uppercase for consistency
  };
  const addPatient = () => {
    setPatients([...patients, { email: '', firstName: '', lastName: '', genomicReport: '', gutHealthReport: '', oralHealthReport: '' }]);
  };
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = XLSX.read(event.target.result, { type: 'binary' });
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
        const newPatients = rows.map(row => ({
          email: row.Email || '',
          firstName: row.FirstName || '',
          lastName: row.LastName || '',
          genomicReport: row.GenomicReport || '',
          gutHealthReport: row.GutHealthReport || '',
          oralHealthReport: row.OralHealthReport || ''
        }));
        setPatients([...patients, ...newPatients]);
      }
    };
    reader.readAsBinaryString(file);
    fetchPatients();

  }, [patients]);

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: '.xlsx, .xls, .csv'});


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = XLSX.read(event.target.result, { type: 'binary' });
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
        const newPatients = rows.map(row => ({
          email: row.Email || '',
          firstName: row.FirstName || '',
          lastName: row.LastName || '',
          genomicReport: row.GenomicReport || '',
          gutHealthReport: row.GutHealthReport || '',
          oralHealthReport: row.OralHealthReport || ''
        }));
        setPatients([...patients, ...newPatients]);
      }
    };
    reader.readAsBinaryString(file);
  };
    const fileInputRef = React.useRef();
  const validatePatients = () => {
    return patients.every(patient => patient.email && patient.firstName && patient.lastName);
  };
  
  
  const removePatient = (index) => {
    const newPatients = patients.filter((_, i) => i !== index);
    setPatients(newPatients);
  };
  
  const updatePatientField = (index, field, value) => {
    const newPatients = [...patients];
    newPatients[index][field] = value;
    setPatients(newPatients);
  };
  const sendInvites = async () => {
    try {
      const responses = await Promise.all(patients.map(patient =>
        fetch('https://service3.szapfs.org/createUser', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: patient.email,
            firstName: patient.firstName,
            lastName: patient.lastName,
            GenomicReport: patient.genomicReport || "Purchase",
            GMReport: patient.gutHealthReport || "Purchase",
            OMReport: patient.oralHealthReport || "Purchase",
          }),
        })
      ));
      
      console.log('Invites sent successfully!');
      setPatients([]);
      fetchPatients();

    } catch (error) {
      console.error('Error sending invites:', error);
    }
  };
    
  return (
    <div  className="invite-patients-container">

      <div className="topp-bar">
        <button
          className={`tab-button ${selectedTab === 'invite' ? 'selected' : ''}`}
          onClick={() => setSelectedTab('invite')}
        >
          Invite
        </button>
        <button
          className={`tab-button ${selectedTab === 'pending' ? 'selected' : ''}`}
          onClick={() => setSelectedTab('pending')}
        >
  Pending Invites ({pendingPatients.length})
        </button>
      </div>
      <div className="content">
        {selectedTab === 'invite' ? (
          <div className="invitepage">
            <div className="invitescroll">
              <div className="patient-form-titles">
              </div>
              {patients.map((patient, index) => (
  <div key={index} className="patient-row">
    <FontAwesomeIcon className="trash" icon={faTrashAlt} onClick={() => removePatient(index)} />

    <div className="field-container">
      <div className="field-title">Email</div>
      <input
        className="pinput-box"
        type="email"
        value={patient.email}
        onChange={(e) => updatePatientField(index, 'email', e.target.value)}
        placeholder="*"
      />
    </div>
    <div className="field-container">
      <div className="field-title">First Name</div>
      <input
        className="pinput-box"
        type="text"
        value={patient.firstName}
        onChange={(e) => updatePatientField(index, 'firstName', e.target.value)}
        placeholder="*"
      />
    </div>
    <div className="field-container">
      <div className="field-title">Last Name</div>
      <input
        className="pinput-box"
        type="text"
        value={patient.lastName}
        onChange={(e) => updatePatientField(index, 'lastName', e.target.value)}
        placeholder="*"
      />
    </div>
    <div className="field-container">
      <div className="field-title">Genomic</div>
      <input
        className="oinput-box"
        type="text"
        value={patient.genomicReport}
        onChange={(e) => updatePatientField(index, 'genomicReport', e.target.value)}
        placeholder="Optional"
      />
    </div>
    <div className="field-container">
      <div className="field-title">Gut Health</div>
      <input
        className="oinput-box"
        type="text"
        value={patient.gutHealthReport}
        onChange={(e) => updatePatientField(index, 'gutHealthReport', e.target.value)}
        placeholder="Optional"
      />
    </div>
    <div className="field-container">
      <div className="field-title">Oral Health</div>
      <input
        className="oinput-box"
        type="text"
        value={patient.oralHealthReport}
        onChange={(e) => updatePatientField(index, 'oralHealthReport', e.target.value)}
        placeholder="Optional"
      />
    </div>
  </div>
))}

            </div>

            <div className="button-container">
              <button className="add-patient-btn" onClick={addPatient}>
                + Add Patient
              </button>
              <input
    type="file"
    ref={fileInputRef}
    onChange={handleFileUpload}
    style={{ display: 'none' }}
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  />

<div {...getRootProps()} className="csvadd">
<input {...getInputProps()} />

    <button
    className="csv-icon-btn"
    onClick={() => fileInputRef.current.click()} 
  >
    <FontAwesomeIcon icon={faFileCsv} />
  </button>

  </div>

              <button className="send-btn" onClick={sendInvites} disabled={!validatePatients()}>Send Invite</button>
            </div>
          </div>
        ) : (
          <div className="pendingpage">{selectedTab === 'pending' && (
            <div className="pendingpage">
              <table className="patient-table">
                <tbody>
                  {pendingPatients.map(pendingp => (
                    <tr key={pendingp.User_ID} className="patient-table-row">
                      <td className="patient-table-cell">
                        <div className="patient-details">
                          <div className="patient-initials" style={{ background: 'linear-gradient(to bottom, #20558a, #2596be)', opacity: 0.9 }}>
                            {getInitials(pendingp.First_Name, pendingp.Last_Name)} 
                          </div>
                          <div>
                            <div className="patient-name">{pendingp.First_Name} {pendingp.Last_Name} {/* pendingp.identifier*/}   <div class="loader"> 
  <li class="ball"></li>
  <li class="ball"></li>
  <li class="ball"></li>
  
</div>


</div>
                            
                          </div>
                          
                        </div>
                        
                      </td>
                      
                      <td className="patient-table-cell">                           


</td>
<td className="patient-table-cell">{pendingp.Email}</td>


<td className="patient-table-cell">
  
  <button className="resend-btn" onClick={sendInvites} >↻</button>
  <button className="trash-btn" onClick={sendInvites} >🗑️</button>

  
  </td>


                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}</div>
        )}
      </div>
    </div>
  );
}

export default Invitepatients;
