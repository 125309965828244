import React, { useState } from 'react';
import useUserData from '../useUserData'; // Correct import path
import './filetransfer.css';
import FileDashboard from './FileDashboard';
import ManageProjects from './ManageProjects';
import ManageAccounts from './ManageAccounts';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Filetransfer() {
  const [activeComponent, setActiveComponent] = useState('FileDashboard');
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const user = useUserData();

  const renderComponent = () => {
    switch (activeComponent) {
      case 'FileDashboard':
        return <FileDashboard />;
      case 'ManageProjects':
        return <ManageProjects />;
      case 'ManageAccounts':
        return <ManageAccounts />;
      default:
        return <FileDashboard />;
    }
  };

  const slideOutSidebar = () => {
    setIsSidebarVisible(false);
  };

  const slideInSidebar = () => {
    setIsSidebarVisible(true);
  };

  return (
    <div className="tbackfull">
      <div className="tback">
        <div className="tbackin">
          {!isSidebarVisible && (
            <button className="sidebar-toggle-button" onClick={slideInSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          )}
          <div className="transferfiles">
            <div className={`tfs ${isSidebarVisible ? 'visible' : 'hidden'}`}>
              <Sidebar setActiveComponent={setActiveComponent} slideOutSidebar={slideOutSidebar} />
            </div>
            <div className={`tfsm ${!isSidebarVisible ? 'full-widthw' : ''}`}>
              <div className="user-info">
                
              </div>
              {renderComponent()}
            </div>
          </div>
        </div>
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}

export default Filetransfer;
