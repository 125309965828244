import React, { useState } from 'react';
import './MetaUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Metadata from './Metadata';  // Import the new Metadata popup component

function MetaUpload({ userId }) {
  const [isMetadataOpen, setIsMetadataOpen] = useState(false);

  const openMetadataPopup = () => {
    setIsMetadataOpen(true);
  };

  const closeMetadataPopup = () => {
    setIsMetadataOpen(false);
  };

  return (
    <div className="MetaUpload">
      <div className="UploadDrop1">
        <div className="UploadDropin">
          <FontAwesomeIcon icon={faFileUpload} className="outline-icon" />
          <div className="MUText">Please Drag and Drop Raw Data</div>
          <div className="MUText1">OmicsType-PatientID-Iteration</div>
          <div className="MUText2">Ex.</div>
          <div className="MUText2">Transcriptome-P001_167-4</div>
          <div className="MUText2">Plasma-P001_167-1</div>
        </div>
      </div>

      <div className="UploadProgress1">
        <div className="UploadProgressTOP">
          <div className="UploadProgressTOPLeft">
            <div className="UploadProgressTOPLeftButton" onClick={openMetadataPopup}>
              <FontAwesomeIcon icon={faCloud} className="outline-icon" />
              <span style={{ marginLeft: '5px' }}>Metadata</span>
            </div>
          </div>
        </div>

        <div className="UploadProgressBOT"></div>
      </div>

      {/* Metadata Popup */}
      <Metadata isOpen={isMetadataOpen} onClose={closeMetadataPopup} />
    </div>
  );
}

export default MetaUpload;
