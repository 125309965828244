import React, { useState, useEffect, useRef } from 'react';
import './Scanner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faBoxesPacking, faHistory, faChevronDown, faFilter, faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import CamScan from './CamScan';
import StatusUpdateNotification from './StatusUpdateNotification';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Scanner() {
  const [showSlide, setShowSlide] = useState(false);
  const [selectedTab, setSelectedTab] = useState('orders');
  const [purchases, setPurchases] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [filter, setFilter] = useState('All');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  const filterLabels = {
    'All': 'All',
    '1': 'Order received',
    '2': 'Sent cargo',
    '3': 'Cargo delivered',
    '4': 'Lab received',
    '5': 'Data delivered'
  };

  useEffect(() => {
    fetch('https://service3.szapfs.org/active_purchases')
      .then(response => response.json())
      .then(data => {
        setPurchases(data);
        setFilteredPurchases(data);
      })
      .catch(error => console.error('Error fetching purchases:', error));
  }, []);

  useEffect(() => {
    let filtered = purchases;

    if (filter !== 'All') {
      filtered = filtered.filter(p => p.purchase.Status.toString() === filter);
    }

    if (searchTerm) {
      filtered = filtered.filter(p => 
        p.user.First_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        p.user.Last_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        p.purchase.Address.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter(p => {
        const purchaseDate = new Date(p.purchase.created_at).toDateString();
        return purchaseDate === selectedDate.toDateString();
      });
    }

    setFilteredPurchases(filtered);
  }, [filter, purchases, searchTerm, selectedDate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  const handleButtonClick = () => {
    setShowSlide(true);
  };

  const handleBackClick = () => {
    setShowSlide(false);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };

  const clearSelectedDate = () => {
    setSelectedDate(null);
  };

  return (
    <div className="S_Back">
      <div className="S_Full">
        <div className={`slide ${showSlide ? 'active' : ''}`}>
          <div className="topsl">
            <div className="chevd" onClick={handleBackClick}>
              <FontAwesomeIcon icon={faChevronDown} className="IconPage" />
            </div>
          </div>
          <div className="botsl">
            <CamScan active={showSlide} onBackClick={handleBackClick} />
          </div>
        </div>

        {selectedTab === 'orders' ? (
          <div className="S_UP">
            <div className="sct">
              <div className="sctt">
                <div className="view">View Active</div>
                <div className="allorders">Orders</div>
              </div>
            </div>
            <div className="psc">
              <input
                type="text"
                placeholder="Search Orders"
                className="searchorder"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="pscfu">
            <div className="pscfuin">

            <div className={`OFS ${selectedDate ? 'selected' : ''}`} ref={datePickerRef}>


    <FontAwesomeIcon icon={faCalendar} onClick={() => setShowDatePicker(true)}/>
    {showDatePicker && (
      <div className="date-picker-popup">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          inline
          
        />
      </div>
    )}
    {selectedDate && (
      <>
        <div className="OFSD">{selectedDate.toDateString()}</div>
        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedDate} className="clear-date-icon" />
      </>
    )}



</div>

              <div onClick={toggleDropdown} className={`OFS ${filter === 'All' ? 'unselected' : 'selected'}`}>
                {filter !== 'All' && <span className="filter-text">{filterLabels[filter]}</span>}
                <FontAwesomeIcon
                  icon={faFilter}
                  className={`surr ${filter === 'All' ? 'gray-filter' : 'blue-filter'}`}
                />
              </div>
              {showDropdown && (
                <div className="dropdown-menu">
                  {Object.keys(filterLabels).map((key) => (
                    <div key={key} className="dropdown-item" onClick={() => handleFilterChange(key)}>
                      {filterLabels[key]}
                    </div>
                  ))}
                </div>
              )}
            </div>
            </div>

            <div className="pslc">
              {filteredPurchases.map((item) => (
                <div key={item.purchase.id} className="pslcl"> 
                  <StatusUpdateNotification
                    isVisible={true}
                    userDetails={item.user}
                    statusMessage=""
                    onConfirm={() => {}}
                    onClose={() => {}}
                    purchaseDetails={item.purchase}
                    key={item.purchase.id}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="S_UP2">
            <div className="sct">
              <div className="sctt">
                <div className="view">View Order</div>
                <div className="allorders">History</div>
              </div>
            </div>
            
            <div className="psld">No Available History</div>
          </div>
        )}

        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="S_LOWER">
          <div className="footer">
            <div className="choose">
              <button className={`chosen ${selectedTab === 'orders' ? 'selected' : ''}`} onClick={() => setSelectedTab('orders')}>
                <FontAwesomeIcon icon={faBoxesPacking} className="IconPage" />
              </button>
              <button className={`chosen ${selectedTab === 'history' ? 'selected' : ''}`} onClick={() => setSelectedTab('history')}>
                <FontAwesomeIcon icon={faHistory} className="IconPage" />
              </button>
            </div>
            <button className="main-button" onClick={handleButtonClick}>
              <FontAwesomeIcon icon={faCamera} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scanner;
