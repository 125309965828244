
import React, { useState, useEffect, useRef } from "react";
import "./TempInputRow.css";
import { faChevronDown, faCircleMinus, faCirclePlus, faGear, faMinusCircle, faPlugCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewbornModal from "./ReportConfigs/Newborn.js"; // Ensure the modal is imported

const COMPANY_LANGUAGE_MAP = {
  "Whole Genome": {
    "SZA Longevity": ["English"],
    "Silk Longevity": ["Korean"]
  },
  "Newborn": {
    "Acıbadem": ["Turkish"]
  },
  "Plasma Metabolome": {
    "SZA Longevity": ["English"],
  },
  "Urine Metabolome": {
    "SZA Longevity": ["English"],

  },
  "Lipidome": {
    "SZA Longevity": ["English"],

  },
  "Transcriptome": {
    "SZA Longevity": ["English"],

  },

  "Proteomics": {
    "SZA Longevity": ["English"],
  },
  "Gut Health": {
    "SZA Longevity": ["English", "Turkish"],
    "Silk Longevity": ["Korean"],

  },
  "Oral Health": {
    "Acibadem 1": ["English", "Spanish"],
    "Company 2": ["French", "German"]
  },

};

const TempInputRow = ({ report, setNewReport, handleDeleteReport, isInvalid, flash }) => {
  const [validIds, setValidIds] = useState([]);
  const [isValidId, setIsValidId] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredIds, setFilteredIds] = useState([]);
  const [isLoadingPatientDetails, setIsLoadingPatientDetails] = useState(false);
  const [availableReports, setAvailableReports] = useState([]);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [isNewbornModalOpen, setIsNewbornModalOpen] = useState(false); // State for modal visibility

  // Update the report with validation state whenever it changes
  useEffect(() => {
    setNewReport(report.id, {
      ...report,
      isValidId: isValidId // Add validation state to the report object
    });
  }, [isValidId]);

  const fields = [
    { label: "Patient ID", type: "text", key: "Preset" },
    { label: "Report Type", type: "select", key: "ReportType", options: availableReports },
    { label: "Company", type: "select", key: "Company", options: report.ReportType ? Object.keys(COMPANY_LANGUAGE_MAP[report.ReportType] || {}) : [] },
    { label: "Language", type: "select", key: "Language", options: report.ReportType && report.Company ? COMPANY_LANGUAGE_MAP[report.ReportType][report.Company] : [] },
    { label: "Separator", type: "separator" },
    { label: "Name", type: "text", key: "PatientFirstName" },
    { label: "Date of Birth", type: "text", key: "PatientDateOfBirth" },
    { label: "Gender", type: "select", key: "PatientGender", options: ["MALE", "FEMALE", "Other"] },
    { label: "Newborn", type: "button", key: "NewbornDetails" }

  ];

  const areMandatoryFieldsValid = () => {
    return (
      report.Preset &&
      isValidId &&
      report.ReportType &&
      report.Company &&
      report.Language
    );
  };

  useEffect(() => {
    fetch("https://service3.szapfs.org/metadata:validids")
      .then((response) => response.json())
      .then((data) => {
        setValidIds((data.patient_ids || []).sort());
      })
      .catch((error) => {
        console.error("Error fetching valid IDs:", error);
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchAvailableReports = async (patientId) => {
    try {
      const response = await fetch(`https://service3.szapfs.org/availablereport/${patientId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch available reports');
      }
      const data = await response.json();
      setAvailableReports(data.reports || []);
      return data.reports || [];
    } catch (error) {
      console.error("Error fetching available reports:", error);
      setAvailableReports([]);
      console.log(availableReports)
      return [];
    }
  };

  const fetchPatientMetadata = async (patientId) => {
    try {
      const response = await fetch(`https://service3.szapfs.org/metadata/patient/${patientId}`);
      /// "sample_acceptance_date" & "sample_type"
      if (!response.ok) {
        throw new Error('Failed to fetch patient metadata');
      }
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error("Error fetching patient metadata:", error);
      return null;
    }
  };

  // Add debounce function to prevent too many API calls
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Create debounced version of fetchAvailableReports
  const debouncedFetchReports = useRef(
    debounce((patientId) => fetchAvailableReports(patientId), 300)
  ).current;

  const handleFieldChange = async (key, value) => {
    if (key === "Preset") {
      const valid = validIds.includes(value);
      setIsValidId(valid);
      
      const filtered = validIds.filter(id => 
        id.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredIds(filtered);
      setShowSuggestions(true);

      const reportUpdate = {
        ...report,
        Preset: value,
        ReportType: "",
        Company: "",
        Language: "",
        isValidId: valid, // Include isValidId here
        Newborn: "", // Clear Newborn data when Patient ID changes

      };

      // Search for available reports on each valid character update
      if (valid && value) {
        setIsLoadingPatientDetails(true);
        try {
          // Fetch patient metadata
          const patientMetadata = await fetchPatientMetadata(value);
          if (patientMetadata) {
            reportUpdate.PatientFirstName = patientMetadata.name || "";
            reportUpdate.PatientDateOfBirth = patientMetadata.date_of_birth || "";
            reportUpdate.PatientGender = patientMetadata.gender || "";
          }
          
          // Trigger debounced report search
          debouncedFetchReports(value);
        } finally {
          setIsLoadingPatientDetails(false);
        }
      } else {
        // Clear fields if ID becomes invalid
        reportUpdate.PatientFirstName = "";
        reportUpdate.PatientDateOfBirth = "";
        reportUpdate.PatientGender = "";
        setAvailableReports([]);
      }

      setNewReport(report.id, reportUpdate);
    } else if (key === "ReportType") {
      const companies = COMPANY_LANGUAGE_MAP[value] ? Object.keys(COMPANY_LANGUAGE_MAP[value]) : [];
      const defaultCompany = companies[0] || "";
      const defaultLanguage = defaultCompany ? COMPANY_LANGUAGE_MAP[value][defaultCompany][0] : "";
      
      setNewReport(report.id, {
        ...report,
        ReportType: value,
        Company: defaultCompany,
        Language: defaultLanguage,
        Newborn: "", // Clear Newborn data when ReportType changes

      });
    } else if (key === "Company") {
      const languages = COMPANY_LANGUAGE_MAP[report.ReportType][value] || [];
      const defaultLanguage = languages[0] || "";
      
      setNewReport(report.id, {
        ...report,
        Company: value,
        Language: defaultLanguage,
      });
    } else {
      setNewReport(report.id, { ...report, [key]: value });
    }
  };

  const handleInputFocus = () => {
    if (!report.Preset) {
      setFilteredIds(validIds);
      setShowSuggestions(true);
    }
  };

















  const openNewbornModal = () => setIsNewbornModalOpen(true);
  const closeNewbornModal = () => setIsNewbornModalOpen(false);

  const handleNewbornSave = (newbornData) => {
    setNewReport(report.id, {
      ...report,
      Newborn: newbornData, // Save Newborn data to report
    });
    closeNewbornModal();
  };


  const renderField = ({ label, type, key, options }) => {
    const isMandatoryField = ["Preset", "ReportType", "Company", "Language"].includes(key);
  
    let isDisabled = false;
    if (key === "ReportType") {
      isDisabled = !report.Preset || !isValidId;
    } else if (key === "Company" || key === "Language") {
      isDisabled = !report.ReportType;
    } else if (!isMandatoryField) {
      isDisabled = !areMandatoryFieldsValid();
    }
  
    const fieldClass = `grid-item-temp-row ${isDisabled ? "disabled" : ""}`;
  
    if (key === "NewbornDetails" && report.ReportType === "Newborn") {
      const cleanedNewbornData = report.Newborn
        ? report.Newborn.replace(/!.*?!/g, "").replace(/,/g, "").trim()
        : "";
    
      return (
        <div key={key} className={fieldClass}>
          <button
            className="newborn-button"
            onClick={openNewbornModal}
            style={{ opacity: cleanedNewbornData ? 1 : 0.5 }}
          >
            <FontAwesomeIcon
              icon={cleanedNewbornData ? faCirclePlus : faCircleMinus}
              color="white"
            />
            {label}
            <FontAwesomeIcon
              icon={cleanedNewbornData ? faCirclePlus : faCircleMinus}
              color="transparent"
            />
          </button>
 
        </div>
      );
    }
    
    
    
    if (type === "separator") {
      return <hr className="field-separator" key={key} />;
    }
  
    if (key === "Preset") {
      return (
        <div className={fieldClass} key={key} ref={wrapperRef}>
          <div className="tempgridtitle">{label}</div>
          <div className="autocomplete-wrapper">
            <input
              ref={inputRef}
              type="text"
              value={report[key] || ""}
              onChange={(e) => handleFieldChange(key, e.target.value)}
              onFocus={handleInputFocus}
              className="optionchoice"
              placeholder="Select Patient ID"
            />
            {showSuggestions && filteredIds.length > 0 && (
              <div className="suggestions-list">
                {filteredIds.map((id) => (
                  <div
                    key={id}
                    className="suggestion-item"
                    onClick={() => {
                      handleFieldChange(key, id);
                      setShowSuggestions(false);
                    }}
                  >
                    {id}
                  </div>
                ))}
              </div>
            )}
            <span className="validation-icon">
              {report[key] ? (
                isValidId ? (
                  <span style={{ color: "green" }}>✔</span>
                ) : (
                  <span style={{ color: "red" }}>✘</span>
                )
              ) : (
                <FontAwesomeIcon icon={faChevronDown} color="white" />
              )}
            </span>
          </div>
        </div>
      );
    }
  
    switch (type) {
      case "select":
        return (
          <div className={fieldClass} key={key}>
            <div className="tempgridtitle">{label}</div>
            <select
              value={report[key] ?? ""}
              onChange={(e) => handleFieldChange(key, e.target.value)}
              disabled={isDisabled}
              className="optionchoice"
            >
              <option className="optionchoice" value="" key="default">
                Select {label}
              </option>
              {options?.map((option) => (
                <option className="optionchoice" value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
  
      default:
        return (
          <div className={fieldClass} key={key}>
            <div className="tempgridtitle">{label}</div>
            <input
              type={type}
              value={report[key] || ""}
              onChange={(e) => handleFieldChange(key, e.target.value)}
              disabled={isDisabled}
            />
          </div>
        );
    }
  };
  
  // Main rendering logic
  return (
    <div className="container">
      <div className={`card_box ${flash ? "flash-invalid" : ""} ${isInvalid ? "invalid" : ""}`}>
        <div className="gridtop">
          <button className="gridtopx" onClick={() => handleDeleteReport(report.id)}>
            X
          </button>
        </div>
        <div className={`grid-container-temp-row ${isInvalid ? "invalid" : ""}`}>
          {fields.map((field) =>
            field.key === "NewbornDetails" && report.ReportType !== "Newborn"
              ? null
              : renderField(field)
          )}
        </div>
      </div>
  
      {/* Render the NewbornModal conditionally */}
      {isNewbornModalOpen && (
  <NewbornModal
    isOpen={isNewbornModalOpen}
    onClose={closeNewbornModal}
    onSave={handleNewbornSave}
    initialData={report.Newborn || ""}
    currentFields={report} // Pass all current fields as a prop
  />
)}
    </div>
  );
};

export default TempInputRow;