import React, { useState, useRef, useEffect } from 'react';
import './ExpandableItemO.css';
import ECOL from './ecol';  // Import the new ECOL component
import SmrComponent from './smr';
import GmrComponent from './gmr';

function ExpandableItemO({ title, content }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState('0px');
  const contentRef = useRef(null);
  const [geneData, setGeneData] = useState(null);
  const [bandData, setBandData] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [chrGenes, setChrGenes] = useState([]);
  const [selectedGene, setSelectedGene] = useState(null);
  const [zoomRange, setZoomRange] = useState({ start: 0, end: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [isResizing, setIsResizing] = useState(null);
  const ecorcRef = useRef(null);
  const ecortRef = useRef(null);
  const [isDraggingMap, setIsDraggingMap] = useState(false);
  const [mutationData, setMutationData] = useState([]);
  const [selectedMutation, setSelectedMutation] = useState(null);
  const ecorbRef = useRef(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [selectedGenes, setSelectedGenes] = useState([]);
  const [selectedMutations, setSelectedMutations] = useState([]);
  const [isGeneSearch, setIsGeneSearch] = useState(true);
  const [hasData, setHasData] = useState(true);

  const parseSearchInput = async (input) => {
    // First, try to fetch as a band ID
    try {
      const bandResponse = await fetch(`https://service3.szapfs.org/api/search_band/${input}`);
      if (bandResponse.ok) {
        const bandData = await bandResponse.json();
        return {
          type: 'band',
          chromosome: bandData.chromosome,
          start: bandData.band_start,
          end: bandData.band_end
        };
      }
    } catch (error) {
      console.error('Error fetching band data:', error);
    }
  
    // If not a band, check if it's a chromosome range
    const chromosomeRangeMatch = input.match(/^(\d+|[XY]):(\d+)-(\d+)$/);
    if (chromosomeRangeMatch) {
      const [, chromosome, start, end] = chromosomeRangeMatch;
      return {
        type: 'range',
        chromosome,
        start: parseInt(start),
        end: parseInt(end)
      };
    }
  
    // If not a band or range, try as a gene name
    try {
      const geneResponse = await fetch(`https://service3.szapfs.org/api/gene/${input}`);
      if (geneResponse.ok) {
        const geneData = await geneResponse.json();
        return {
          type: 'gene',
          geneName: input,
          geneData: geneData
        };
      }
    } catch (error) {
      console.error('Error fetching gene data:', error);
    }
  
    // If all else fails, return no data
    return {
      type: 'nodata'
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      if (isExpanded) {
        try {
          const searchResult = await parseSearchInput(title);

          if (searchResult.type === 'nodata') {
            setHasData(false);
            return;
          }

          setHasData(true);
          let chromosome, start, end;

          if (searchResult.type === 'gene') {
            setIsGeneSearch(true);
            setGeneData(searchResult.geneData);
            chromosome = searchResult.geneData.chr;
            start = searchResult.geneData.start;
            end = searchResult.geneData.end;
          } else {
            // For range and band searches
            setIsGeneSearch(false);
            ({ chromosome, start, end } = searchResult);
            setGeneData({ chr: chromosome, start, end });
          }

          // Fetch common data for all search types
          const bandResponse = await fetch(`https://service3.szapfs.org/api/chrbands/${chromosome}`);
          const bandData = await bandResponse.json();
          setBandData(bandData);

          const lastBand = bandData[bandData.length - 1];
          setTotalLength(lastBand.band_end);

          const chrGenesResponse = await fetch(`https://service3.szapfs.org/api/chrgenes/${chromosome}`);
          const chrGenesData = await chrGenesResponse.json();
          setChrGenes(chrGenesData);

          const mutationResponse = await fetch(`https://service3.szapfs.org/api/mutation_list/${chromosome}`);
          const mutationData = await mutationResponse.json();
          setMutationData(mutationData);

          if (totalLength) {
            setInitialZoom({ start, end });
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setHasData(false);
        }
      }
    };

    fetchData();
  }, [isExpanded, title, totalLength]);
 












  
  const setInitialZoom = (gene) => {
    const geneLength = gene.end - gene.start;
    const geneCenter = (gene.start + gene.end) / 2;
    let viewRange = 100000; // Minimum view range

    if (geneLength > 100000) {
      viewRange = geneLength * 1.5; // 50% extra on each side
    }

    const halfViewRange = viewRange / 2;
    
    setZoomRange({
      start: Math.max(0, geneCenter - halfViewRange),
      end: Math.min(totalLength, geneCenter + halfViewRange)
    });
  };

  const handleTitleClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setContentHeight(isExpanded ? `100%` : '0px');
  }, [isExpanded]);

  const calculateWidthPercentage = (start, end) => {
    return ((end - start + 1) / totalLength) * 100;
  };
  const calculateZoomedPosition = (position) => {
    if (zoomRange.start === zoomRange.end) return 0;
    const zoomWidth = zoomRange.end - zoomRange.start;
    return ((position - zoomRange.start) / zoomWidth) * 100;
  };

  const handleGeneClick = (gene, event) => {
    setSelectedGenes(prev => [...prev, {
      gene,
      position: {
        x: event.clientX + 10,
        y: event.clientY
      }
    }]);
  };

  const handleMutationClick = (mutation, event) => {
    setSelectedMutations(prev => [...prev, {
      mutation,
      position: {
        x: event.clientX + 10,
        y: event.clientY
      }
    }]);
  };
  const closeGeneModal = (index) => {
    setSelectedGenes(prev => prev.filter((_, i) => i !== index));
  };

  const closeMutationModal = (index) => {
    setSelectedMutations(prev => prev.filter((_, i) => i !== index));
  };
  const handleECORCINMouseDown = (e) => {
    if (e.target.classList.contains('resizer')) {
      setIsResizing(e.target.dataset.direction);
    } else if (e.target.classList.contains('gene-position-indicator')) {
      setIsDragging(true);
    }
    setStartX(e.clientX);
  };

  const handleECORCINMouseMove = (e) => {
    if (!isDragging && !isResizing) return;

    const dx = e.clientX - startX;
    const changeAmount = dx * (totalLength / ecorcRef.current.clientWidth);

    if (isDragging) {
      setZoomRange(prev => ({
        start: Math.max(0, prev.start + changeAmount),
        end: Math.min(totalLength, prev.end + changeAmount)
      }));
    } else if (isResizing) {
      setZoomRange(prev => {
        if (isResizing === 'left') {
          return {
            start: Math.max(0, Math.min(prev.end - 1000, prev.start + changeAmount)),
            end: prev.end
          };
        } else {
          return {
            start: prev.start,
            end: Math.min(totalLength, Math.max(prev.start + 1000, prev.end + changeAmount))
          };
        }
      });
    }
    setStartX(e.clientX);
  };

  const handleECORCINMouseUp = () => {
    setIsDragging(false);
    setIsResizing(null);
  };

  const handleECORTMouseDown = (e) => {
    setIsDraggingMap(true);
    setStartX(e.clientX);
  };

  const handleECORTMouseMove = (e) => {
    if (!isDraggingMap) return;

    const dx = e.clientX - startX;
    const changeAmount = dx * ((zoomRange.end - zoomRange.start) / ecortRef.current.clientWidth);

    setZoomRange(prev => ({
      start: Math.max(0, prev.start - changeAmount),
      end: Math.min(totalLength, prev.end - changeAmount)
    }));

    setStartX(e.clientX);
  };

  const handleECORTMouseUp = () => {
    setIsDraggingMap(false);
  };

  const arrangeGenes = (genes) => {
    const rows = [];
    genes.forEach(gene => {
      let row = 0;
      while (rows[row] && rows[row].some(g => 
        (gene.start >= g.start && gene.start <= g.end) || 
        (gene.end >= g.start && gene.end <= g.end) ||
        (gene.start <= g.start && gene.end >= g.end) ||
        (Math.abs(calculateZoomedPosition(gene.start) - calculateZoomedPosition(g.end)) < 5)
      )) {
        row++;
      }
      if (!rows[row]) rows[row] = [];
      rows[row].push(gene);
    });
    return rows;
  };

  const visibleGenes = chrGenes.filter(gene => 
    gene.start <= zoomRange.end && gene.end >= zoomRange.start
  );

  const arrangedGenes = arrangeGenes(visibleGenes);

  const getPathogenicityScore = (pathogenicity) => {
    switch (pathogenicity) {
      case 'pathogenic': return 5;
      case 'likely_pathogenic': return 4;
      case 'uncertain': return 3;
      case 'likely_benign': return 2;
      case null: return 1;
      default: return 0;
    }
  };


  const arrangeMutations = (mutations) => {
    // Sort mutations first by pathogenicity (most pathogenic at the top),
    // then by start position
    const sortedMutations = [...mutations].sort((a, b) => {
      const scoreDiff = getPathogenicityScore(b.pathogenicity) - getPathogenicityScore(a.pathogenicity);
      if (scoreDiff !== 0) return scoreDiff;
      return a.start - b.start;
    });
    
    const rows = [];
    sortedMutations.forEach(mutation => {
      let row = 0;
      while (rows[row] && rows[row].some(m => 
        (mutation.start >= m.start && mutation.start <= m.end) || 
        (mutation.end >= m.start && mutation.end <= m.end) ||
        (mutation.start <= m.start && mutation.end >= m.end) ||
        (Math.abs(calculateZoomedPosition(mutation.start) - calculateZoomedPosition(m.end)) < 5)
      )) {
        row++;
      }
      if (!rows[row]) rows[row] = [];
      rows[row].push(mutation);
    });
    return rows;
  };


  const visibleMutations = mutationData.filter(mutation => 
    mutation.start <= zoomRange.end && mutation.end >= zoomRange.start
  );

  const arrangedMutations = arrangeMutations(visibleMutations);
  const handleBandClick = (band) => {
    setZoomRange({
      start: band.band_start,
      end: band.band_end
    });
  };


  const getColorForMutation = (mutation) => {
    const { variant_class, pathogenicity } = mutation;
    let baseColor, opacity;

    if (variant_class === 'duplication') {
      baseColor = 'rgb(0, 0, 255)'; // Blue for duplications
    } else if (variant_class === 'deletion') {
      baseColor = 'rgb(255, 0, 0)'; // Red for deletions
    } else {
      return 'purple'; // Default color for other variant classes
    }

    switch (pathogenicity) {
      case 'pathogenic':
        opacity = 1;
        break;
      case 'likely_pathogenic':
        opacity = 0.8;
        break;
      case 'likely_benign':
        opacity = 0.6;
        break;
      case 'uncertain':
        opacity = 0.4;
        break;
      case null:
        opacity = 0.2;
        break;
      default:
        opacity = 1;
    }

    return baseColor.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
  };



  const [geneHighlightHeight, setGeneHighlightHeight] = useState(0);
  const [mutationHighlightHeight, setMutationHighlightHeight] = useState(0);

  useEffect(() => {
    if (isExpanded) {
      setGeneHighlightHeight(arrangedGenes.length * 20 + 10); // 10px extra padding
    }
  }, [isExpanded, arrangedGenes]);

  useEffect(() => {
    if (isExpanded) {
      setMutationHighlightHeight(arrangedMutations.length * 20 + 10); // 10px extra padding
    }
  }, [isExpanded, arrangedMutations]);

  
















  return (
    <div className={`ExpandableItemO ${isExpanded ? 'expandedO' : ''}`}>
    <div className="ExpandableTitleO" onClick={handleTitleClick}>
      {title}
    </div>
    <div className="ExpandableContentO" ref={contentRef} style={{ height: contentHeight, opacity: isExpanded ? 1 : 0 }}>
      {hasData ? (
        <div className="ECO">
          {isGeneSearch && geneData && (
            <ECOL geneData={geneData} mutationData={mutationData} />
          )}
          <div className="ECOR">
            <div 
              className="ECORC" 
              ref={ecorcRef}
              onMouseDown={handleECORCINMouseDown}
              onMouseMove={handleECORCINMouseMove}
              onMouseUp={handleECORCINMouseUp}
              onMouseLeave={handleECORCINMouseUp}
            >
              <div className="ECORCIN">
                {bandData.map((band, index) => (
                  <div
                    key={index}
                    className={`band-item ${band.band_type}`}
                    style={{ width: `${calculateWidthPercentage(band.band_start, band.band_end)}%` }}
                    title={`${band.band_id} (${band.band_start}-${band.band_end})`}
                    onClick={() => handleBandClick(band)}
                  >
                    {band.band_id}  
                  </div>
                ))}
                {geneData && geneData.start !== undefined && geneData.end !== undefined && (
                  <div 
                    className="gene-position-indicator"
                    style={{
                      position: 'absolute',
                      left: `${calculateWidthPercentage(1, zoomRange.start)}%`,
                      width: `${calculateWidthPercentage(zoomRange.start, zoomRange.end)}%`,
                      height: '100%',
                      backgroundColor: 'rgba(255, 0, 0, 0.3)',
                      border: '1px solid red',
                      cursor: 'move',
                    }}
                  >
                    <div className="resizer left" data-direction="left"></div>
                    <div className="resizer right" data-direction="right"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="ECORT" 
              ref={ecortRef}
              onMouseDown={handleECORTMouseDown}
              onMouseMove={handleECORTMouseMove}
              onMouseUp={handleECORTMouseUp}
              onMouseLeave={handleECORTMouseUp}
            >
              {arrangedGenes.map((row, rowIndex) => 
                row.map((gene, index) => (
                  <div key={gene.current_hgnc_symbol} className="gene-container">
                    <div
                      className="gene-line"
                      style={{
                        position: 'absolute',
                        left: `${calculateZoomedPosition(gene.start)}%`,
                        width: `${calculateZoomedPosition(gene.end) - calculateZoomedPosition(gene.start)}%`,
                        height: '7px',
                        backgroundColor: gene.current_hgnc_symbol === title ? '#2596be' : '#20558a',
                        top: `${rowIndex * 20}px`,
                        borderRadius: '5vh',
                      }}
                      onClick={(event) => handleGeneClick(gene, event)}
                    />
                    <div
                      className="gene-label"
                      style={{
                        position: 'absolute',
                        left: `${calculateZoomedPosition(gene.start)}%`,
                        top: `${rowIndex * 20 + 7}px`,
                        fontSize: '8px',
                        whiteSpace: 'nowrap',
                        fontWeight: '500'
                      }}
                    >
                      {gene.current_hgnc_symbol}
                    </div>
                  </div>
                ))
              )}
              {geneData && geneData.start !== undefined && geneData.end !== undefined && (
                <div 
                  style={{
                    position: 'absolute',
                    left: `${calculateZoomedPosition(geneData.start)}%`,
                    width: `${calculateZoomedPosition(geneData.end) - calculateZoomedPosition(geneData.start)}%`,
                    height: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    fontSize: '1.1vh',
                    fontWeight: 'bolder',
                    color: 'darkgray',
                    overflow: 'hidden', 
                    pointerEvents: 'none',

                  }}
                >
                  Your Query Position
                </div>
              )}
              {geneData && geneData.start !== undefined && geneData.end !== undefined && (
               <div 
               className="query-gene-highlight"
               style={{
                 position: 'absolute',
                 left: `${calculateZoomedPosition(geneData.start)}%`,
                 width: `${calculateZoomedPosition(geneData.end) - calculateZoomedPosition(geneData.start)}%`,
                 height: `${geneHighlightHeight}px`,
                 backgroundColor: 'rgba(0, 0, 0, 0.1)',
                 border: '1px solid gray',
                 minHeight: '100%',
                 pointerEvents: 'none',  // Add this line to allow clicks to pass through
               }}
             />
             
              )}
            </div>
            <div className="ECORB" ref={ecorbRef}>
            {geneData && (
            <div 
              className="query-gene-highlight"
              style={{
                position: 'absolute',
                left: `${calculateZoomedPosition(geneData.start)}%`,
                width: `${calculateZoomedPosition(geneData.end) - calculateZoomedPosition(geneData.start)}%`,
                height: `${mutationHighlightHeight}px`,
                minHeight: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                border: '1px solid gray',
                pointerEvents: 'none',  // Add this line to allow clicks to pass through
              }}
            />
          )}
        {arrangedMutations.map((row, rowIndex) => 
          row.map((mutation, index) => (
            <div key={`${mutation.start}-${mutation.end}`} className="mutation-container">
              <div
                className="mutation-line"
                style={{
                  position: 'absolute',
                  left: `${calculateZoomedPosition(mutation.start)}%`,
                  width: `${calculateZoomedPosition(mutation.end) - calculateZoomedPosition(mutation.start)}%`,
                  height: '7px',
                  backgroundColor: getColorForMutation(mutation),
                  top: `${rowIndex * 20}px`,

                }}
                onClick={(event) => handleMutationClick(mutation, event)}
              />
              <div
                className="mutation-label"
                style={{
                  position: 'absolute',
                  left: `${calculateZoomedPosition(mutation.start)}%`,
                  top: `${rowIndex * 20 + 5}px`,
                  fontSize: '10px',
                  whiteSpace: 'nowrap',
                  color: getColorForMutation(mutation),

                }}
              >
                {`${mutation.patient_id}`}
              </div>
            </div>
            ))
          )}          
          



        </div>      
        
        
        
        
        
            </div>
          </div>
        ) : (
          <div className="no-data-message">No data available for this query.</div>
        )}
      </div>
      <GmrComponent 
    selectedGenes={selectedGenes} 
    closeGeneModal={closeGeneModal} 
/>


<SmrComponent 
                selectedMutations={selectedMutations} 
                closeMutationModal={closeMutationModal} 
            />

    </div>
  );
}

export default ExpandableItemO;