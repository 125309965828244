import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame} from '@react-three/fiber';
import { PerspectiveCamera, OrbitControls, useGLTF, Text, Environment } from '@react-three/drei';
import brainModel from './brain_areas.glb'; // Adjust the path as necessary
import { MeshPhysicalMaterial, Color, DoubleSide } from 'three';
import { Sphere } from '@react-three/drei';
import { extend, useThree } from '@react-three/fiber';
import { useMemo } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';



function InteractiveOverlays({ overlays }) {
  const { camera } = useThree();  // Correct context access
  const controlsRef = useRef();   // Ref for OrbitControls

  const handleOverlayClick = (position) => {
    const radius = 5; // Distance from the center of the sphere to the camera
    const theta = Math.PI / 4; // Angle for 'bottom right' viewing, adjust as needed
    const phi = Math.PI / 4; // Elevation angle from the bottom, adjust for 'looking down'
  
    const newPosition = [
      position[0] + radius * Math.sin(phi) * Math.cos(theta), // x
      position[1] + radius * Math.cos(phi), // y, adjusted to ensure the camera is elevated
      position[2] + radius * Math.sin(phi) * Math.sin(theta), // z
    ];
  
    const newTarget = [...position]; // Target remains the overlay's position
    // Rotation will be handled by 'lookAt' method
  
    // GSAP animation for smooth camera and controls transition
    gsap.to(camera.position, {
      x: newPosition[0],
      y: newPosition[1],
      z: newPosition[2],
      duration: 1.5,
      onUpdate: () => camera.updateProjectionMatrix()
    });
  
    if (controlsRef.current) {
      gsap.to(controlsRef.current.target, {
        x: newTarget[0],
        y: newTarget[1],
        z: newTarget[2],
        duration: 1.5,
        onUpdate: () => {
          controlsRef.current.update();
          camera.lookAt(newTarget[0], newTarget[1], newTarget[2]);
        }
      });
    }
  };
  




  return (
    <>
      {overlays.map((overlay, index) => (
        <HeatmapOverlay
          key={index}
          position={overlay.position}
          color={overlay.color}
          scale={overlay.scale}
          rotation={overlay.rotation}
          onClick={() => handleOverlayClick(overlay.position)}
        />
      ))}
      <OrbitControls ref={controlsRef} enableRotate={true} enableZoom={false} enablePan={false} minPolarAngle={Math.PI / 2.5} maxPolarAngle={Math.PI / 2.5} />
    </>
  );
}




class GradientShaderMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        color: { value: new THREE.Color() }, 
        opacity: { value: 1 }, 
      },
      vertexShader: `
        varying vec3 vNormal;
        void main() {
          vNormal = normalize(normalMatrix * normal); // Transform the normal to camera space
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        uniform float opacity;
        varying vec3 vNormal;
        void main() {
          float intensity = pow(1.0 - abs(dot(vNormal, vec3(0.0, 1.0, 0.0))), 1.5);
          gl_FragColor = vec4(color, opacity * intensity);
        }
      `,
    });
  }
}

// Make sure React Three Fiber recognizes our custom shader as a component
extend({ GradientShaderMaterial });

function HeatmapOverlay({ position, color, scale, rotation = [0, 0, 0], onClick }) {
  // Use useMemo to prepare the color for the shader material
  const materialProps = useMemo(() => ({
    uniforms: {
      color: { value: new THREE.Color(color) }, // Use the passed color for the shader
      opacity: { value: 0.5}, // You can adjust the base opacity as necessary
    },
    vertexShader: `
      varying vec3 vNormal;
      void main() {
        vNormal = normalize(normalMatrix * normal); // Transform the normal to camera space
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 color;
      uniform float opacity;
      varying vec3 vNormal;
      void main() {
        // Calculate the intensity of the gradient effect
        float intensity = pow(1.0 - abs(dot(vNormal, vec3(0.0, 1.0, 0.0))), 2.0);
        // Apply the color and calculated opacity based on the intensity
        gl_FragColor = vec4(color, opacity * intensity);
      }
    `,
    transparent: true, // Ensure material is transparent to support fading
  }), [color]);

  return (
    <Sphere args={[0.1, 32, 32]} position={position} scale={scale} rotation={rotation} onClick={onClick}>
      <shaderMaterial attach="material" {...materialProps} />
    </Sphere>
  );
}


function BrainModel() {
  const { scene } = useGLTF(brainModel);

  React.useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh) {
        const glassMaterial = new MeshPhysicalMaterial({
          color: new Color(1, 1, 1), // Neutral color
          metalness: 0, // Slight metallic look
          roughness: 0, // Smooth surface
          transmission: 0.9, // High transparency, adjust to reduce "see-through" effect if needed
          transparent: true, // Required for transparency
          clearcoat: 1, // Clear coat effect
          side: DoubleSide, // Render both sides of the mesh
        });
        child.material = glassMaterial;
      }
    });
  }, [scene]);

  return <primitive object={scene} scale={2.7} />;
}
extend({ Text }); // This makes sure Text is recognized as a valid JSX component

function Annotation({ position, text }) {
  const meshRef = useRef();
  const textRef = useRef();
  const [hovered, setHovered] = useState(false);

  // Update annotation to always face the camera
  useFrame(({ camera }) => {
    if (meshRef.current) {
      meshRef.current.lookAt(camera.position);
    }
  });

  // Style changes for hover state
  const onPointerOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const onPointerOut = () => setHovered(false);

  return (
    <mesh
      position={position}
      ref={meshRef}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <Text
        ref={textRef}
        color={hovered ? 'red' : 'black'} // Change color on hover
        fontSize={0.05}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        anchorX="center" // Horizontal anchor
        anchorY="middle" // Vertical anchor
      >
        {text}
      </Text>
    </mesh>
  );
}

function Cognitive() {
  const cameraRef = useRef();
  const controlsRef = useRef();

  
  const annotations = [
    { position: [1, 1.5, 2], text: ' ' },
    { position: [-0.5, 1, 0], text: 'Parietal Lobe' },
    { position: [0.5, 0.8, 0], text: 'Temporal Lobe' },
  ];
  
  const heatmapOverlays = [
    {
      position: [0, 2.1, 1.35],
      color: 'lightgreen',
      scale: [8, 8, 4.9],
      rotation: [-30 * Math.PI / 180, 0, 0]
    },
  
    {
      position: [0, 1.8, -1.2],
      color: 'lightgreen',
      scale: [10, 12, 6],
      rotation: [20 * Math.PI / 180, 0, 0]
    },    
    
    
    
  ];
  return (
    <div style={{ height: '120vh', background: 'white' }}>
      <Canvas>
        <ambientLight intensity={5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <PerspectiveCamera
  ref={cameraRef}
  makeDefault
  position={[3, 7, 3]} // Keeping the camera elevated
  rotation={[-Math.PI / 3, 0, 0]} // Tilting the camera down at a steeper angle, more than 45° but less than 90°
/>

        <Environment preset="city" background={false} />
        <BrainModel />
        {annotations.map((annotation, index) => (
          <Annotation key={index} position={annotation.position} text={annotation.text} />
        ))}
        <InteractiveOverlays overlays={heatmapOverlays} />
      </Canvas>
    </div>
  );
}

export default Cognitive;