import React, { useState, useEffect, useRef } from 'react';
import './FileManager.css';
import useUserData from '../../useUserData'; // Correct import path
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faArrowLeft, faFileUpload, faEllipsisV, faFolderPlus, faRotate, faCross, faClose } from '@fortawesome/free-solid-svg-icons';
import FmProgressLoader from './FmProgressLoader';

function FileManager() {
    const user = useUserData();
    const [items, setItems] = useState([]);
    const [newFolderName, setNewFolderName] = useState('');
    const [parentFolder, setParentFolder] = useState('null'); // Set to 'null' to represent the base directory
    const [folderStack, setFolderStack] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [renamingItem, setRenamingItem] = useState(null);
    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownItem(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (user) {
            fetchItems();
        }
    }, [user, parentFolder]);

    const fetchItems = async () => {
        try {
            const [filesResponse, foldersResponse] = await Promise.all([
                fetch(`service1.szapfs.org/api/files?parentFolder=${parentFolder}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                }),
                fetch(`service1.szapfs.org/api/folders?parentFolder=${parentFolder}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`
                    }
                })
            ]);

            if (!filesResponse.ok || !foldersResponse.ok) {
                throw new Error('HTTP error! status: ' + (filesResponse.statusText || foldersResponse.statusText));
            }

            const files = await filesResponse.json();
            const folders = await foldersResponse.json();

            setItems([
                ...folders.map(folder => ({ ...folder, type: 'folder', key: `folder-${folder.Identifier}` })),
                ...files.map(file => ({ ...file, type: 'file', key: `file-${file.ID}` }))
            ]);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) return;
    
        const newUploadProgress = {};
        files.forEach(file => {
            newUploadProgress[file.name] = 0;
        });
    
        setUploadProgress(newUploadProgress);
        setUploadingFiles(files);
        setIsUploading(true);
    
        let completedUploads = 0;
    
        files.forEach(file => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('parentFolder', parentFolder);
    
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'service1.szapfs.org/api/upload', true);
            xhr.setRequestHeader('Authorization', `Bearer ${Cookies.get('token')}`);
    
            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: percentComplete
                    }));
                }
            };
    
            xhr.onload = () => {
                if (xhr.status === 200) {
                    fetchItems();
                    completedUploads++;
    
                    if (completedUploads === files.length) {
                        setUploadingFiles([]);
setUploadProgress({});
setIsUploading(false);
fetchItems();

                    } else {
                        setUploadingFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
                        setUploadProgress(prevProgress => {
                            const { [file.name]: _, ...rest } = prevProgress;
                            return rest;
                        });
                    }
                } else {
                    console.error('Error uploading files:', xhr.statusText);
                    completedUploads++;
                    if (completedUploads === files.length) {
                        setIsUploading(false);
                        fetchItems();

                    }
                }
            };
    
            xhr.onerror = () => {
                console.error('Error uploading files:', xhr.statusText);
                completedUploads++;
                if (completedUploads === files.length) {
                    setIsUploading(false);
                }
            };
    
            xhr.send(formData);
            fetchItems();
        });
    };
    
    const calculateOverallProgress = () => {
        if (uploadingFiles.length === 0) return 0;
        const totalProgress = uploadingFiles.reduce((acc, file) => acc + (uploadProgress[file.name] || 0), 0);
        return totalProgress / uploadingFiles.length;
    };
    

    const handleCreateFolder = async () => {
        const folderNameBase = 'New Folder';
        let newFolderName = folderNameBase;
        let index = 1;

        while (items.some(item => item.Name === newFolderName && item.type === 'folder')) {
            newFolderName = `${folderNameBase} (${index++})`;
        }

        try {
            const response = await fetch('service1.szapfs.org/api/folders', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: newFolderName, parentFolder })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            fetchItems();
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const handleEnterFolder = (folderId, folderName) => {
        setFolderStack([...folderStack, { id: parentFolder, name: folderName }]);
        setParentFolder(folderId);
    };

    const handleBack = () => {
        const lastFolder = folderStack.pop();
        setParentFolder(lastFolder.id);
        setFolderStack([...folderStack]);
        fetchItems()
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleRename = async (item) => {
        try {
            const idField = item.type === 'folder' ? item.Identifier : item.ID;
            const response = await fetch(`service1.szapfs.org/api/${item.type}s/${idField}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: renamingItem.newName })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            fetchItems();
            setRenamingItem(null);
        } catch (error) {
            console.error(`Error renaming ${item.type}:`, error);
        }
    };

    const handleDelete = async (item) => {
        try {
            const idField = item.type === 'folder' ? item.Identifier : item.ID;
            const response = await fetch(`service1.szapfs.org/api/${item.type}s/${idField}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            fetchItems();
        } catch (error) {
            console.error(`Error deleting ${item.type}:`, error);
        }
    };

    const filteredItems = items.filter(item =>
        item.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="file-manager-w">
            <div className="FMWIN">
                <div className="FMT">
                    <div className="FMTS">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search"
                            className="SEARCHINNER"
                        />
                    </div>
                    <div className="FMTF">
                        <input
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                            multiple // Allow multiple file selection
                        />
                        <div onClick={() => document.getElementById('file-upload').click()}><FontAwesomeIcon icon={faFileUpload} /></div>
                    </div>
                    <div className="FMTU">
                        <div onClick={handleCreateFolder}><FontAwesomeIcon icon={faFolderPlus} /></div>
                    </div>
                </div>
                <div className="FMB">
                    <div className="FMBT">
                        {folderStack.length > 0 && <div onClick={handleBack}><FontAwesomeIcon icon={faArrowLeft} className="FMBACK" /></div>}
                        <div className="FMROOT">{parentFolder === 'null' ? 'Root' : folderStack.map(folder => folder.name).join(' / ')}</div>
                    </div>
                    <div className="FMBL">
                    


                        {filteredItems.map(item => (
                            <div key={item.key} className="FIC">
                                <div className="FM-dropdown">
                                    <FontAwesomeIcon icon={item.type === 'folder' ? faFolder : faFile} />
                                </div>
                                {renamingItem && renamingItem.key === item.key ? (
                                    <input
                                        type="text"
                                        value={renamingItem.newName}
                                        onChange={(e) => setRenamingItem({ ...renamingItem, newName: e.target.value })}
                                        onBlur={() => handleRename(item)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleRename(item);
                                                setDropdownItem(null);
                                            } else if (e.key === 'Escape') {
                                                setRenamingItem(null); // Cancel renaming on "Esc"
                                            }
                                        }}
                                        className="rename-input"
                                        onClick={(e) => e.stopPropagation()} // Prevent click from propagating to parent div
                                    />
                                ) : (
                                    <>
                                        <div className="fmtext"  onClick={() => item.type === 'folder' ? handleEnterFolder(item.Identifier, item.Name) : null}>
                                            {item.Name}
                                        </div>
                                        <div className="file-empty-space">
                                            {/* Empty space div */}
                                        </div>
                                        <div className="FM-dropdown"onClick={() => setDropdownItem(dropdownItem === item.key ? null : item.key)} 
>
                                            <FontAwesomeIcon 
                                                icon={faEllipsisV} 
                                            />
                                            {dropdownItem === item.key && (
                                                <div className="FM-dropdown-content"  ref={dropdownRef}>
                                                    <div onClick={() => setRenamingItem({ ...item, newName: item.Name })}>Rename</div>
                                                    <div onClick={() => handleDelete(item)}>Delete</div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    {isUploading && (

                    <div className='FDINRI3I'>



<div className='PRTTT'>
<div className="fmload">
  <FontAwesomeIcon icon={faRotate} className="fa-spin" />
</div>

    Uploading
    <div className="fmload"><FontAwesomeIcon icon={faClose}/></div>

    
</div>

                    <div className='PRTT'>
    <div className="fmupload-progress">
        {uploadingFiles.map(file => (
            <div key={file.name} className="fmfmpg">
              <div className='fmfilel'> <span>{file.name}</span>    </div>

              <div className='fmfiler'>  
              
              <FmProgressLoader key={file.name} progress={uploadProgress[file.name] || 0} />



                </div>


            </div>
        ))}
    </div>

 </div>

 <div className='PRTB'>
 <div className='PRTBT'> 
 
 
 Uploading Progresss 
 </div>

 <div className='PRTBB'>    <FmProgressLoader progress={calculateOverallProgress()} />
 </div>


</div>




                                   
                        </div>
)}



                </div>
            </div>
        </div>
    );
}

export default FileManager;
