import React from 'react';
import './gut.css';

function Gut({ searchQuery }) {
  return (
    <div className="Gut">
      {/* Gut-related content */}
      <p>Gut content based on search: {searchQuery}</p>
    </div>
  );
}

export default Gut;
