import React from 'react';
import './ecol.css';

const Obox = ({ title, content }) => {
  return (
    <div className="obox">
      <div className="obox-title">
        {title}
      </div>
      <div className="obox-content">
        {content}
      </div>
    </div>
  );
};

export default Obox;
