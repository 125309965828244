// Orders.js
import React from 'react';
import './Orders.css';

function Orders({ userId }) {
  return (
    <div>
            <div className="Orders">
      <div className="ordercard">No Orders</div>
    </div>
    </div>
  );
}

export default Orders;
