import React, { useState, useRef, useEffect } from 'react';
import './mutation.css';
import Run from './Run';

const MutationItem = ({ mutation }) => {
  const [showRunModal, setShowRunModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const runButtonRef = useRef(null);
  const modalRef = useRef(null);

  const updateModalPosition = () => {
    if (runButtonRef.current && modalRef.current) {
      const buttonRect = runButtonRef.current.getBoundingClientRect();
      const modalRect = modalRef.current.getBoundingClientRect();

      // Position the modal to the right of the button
      let left = buttonRect.right + 10; // 10px gap
      let top = buttonRect.top;

      // Check if the modal would go off-screen to the right
      if (left + modalRect.width > window.innerWidth) {
        // If so, position it to the left of the button instead
        left = buttonRect.left - modalRect.width - 10;
      }

      // Ensure the modal doesn't go off-screen at the bottom
      if (top + modalRect.height > window.innerHeight) {
        top = window.innerHeight - modalRect.height - 10;
      }

      setModalPosition({ top: `${top}px`, left: `${left}px` });
    }
  };

  const handleRunClick = () => {
    setShowRunModal(true);
    // Use setTimeout to ensure the modal is rendered before calculating its position
    setTimeout(updateModalPosition, 0);
  };

  const handleCloseModal = () => {
    setShowRunModal(false);
  };

  useEffect(() => {
    window.addEventListener('resize', updateModalPosition);
    window.addEventListener('scroll', updateModalPosition);

    return () => {
      window.removeEventListener('resize', updateModalPosition);
      window.removeEventListener('scroll', updateModalPosition);
    };
  }, []);
  return (
    <div className="mutation-item-box">
      <div className="mutationin"> 
        <div className="M-mutation-type">
          <div className="HASC">{mutation['HGMD accession']}</div>
          <div className="HGT">{mutation['Mutation Type']}</div>
          <div className="HDM">{mutation['Variant class']}</div>
        </div>
        <div className="M-1">
          <div className="M-1B">
            <div className="M1T">CHR:POS:REF/ALT</div>
            <div className="M1B">{mutation['VCF CHROM POS REF/ALT']}</div>
          </div>
          <div className="M-1B">
            <div className="M1T">Phenotype</div>
            <div className="M1B">{mutation['Reported phenotype']}</div>
          </div>
        </div>
        <div className="M-1">
          <div className="M-1B">
            <div className="M1T">Reference</div>
            <div className="M1B">{mutation['Reference'].substring(0, 20)}</div>
          </div>
          <div className="M-1B">
            <div className="M1T">HGVS (protein)</div>
            <div className="M1B">{mutation['HGVS (protein)']}</div>
          </div>
        </div>
        <div className="M-2">
          <div className="M2cs">+ Information</div>
          <div className="M2cs" ref={runButtonRef} onClick={handleRunClick}>Run</div>
          </div>
      </div>

      {showRunModal && (
        <div ref={modalRef} style={{
          position: 'fixed',
          top: modalPosition.top,
          left: modalPosition.left,
          zIndex: 9999,
        }}>
          <Run 
            data={mutation['VCF CHROM POS REF/ALT']} 
            onClose={handleCloseModal}
          />
        </div>
      )}
    </div>
  );
};

export default MutationItem;
