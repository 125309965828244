import React, { useState, useEffect } from 'react';
import './PatientInsights.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gut from './Gut'; // Adjust the path as necessary
import Genomics from './Genomics'; // Adjust the path as necessary
import OralHealth from './OralHealth'; // Adjust the path as necessary
import Cognitive from './Cognitive'; // Adjust the path as necessary

import { faBrain, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { faDna, faBacteria, faGrinTongue, faGrinTongueWink  } from '@fortawesome/free-solid-svg-icons';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import ClinicalInsights from './ClinicalInsights';
import Orders from './Orders';


function PatientInsights({ userId, onClose, showModal, user }) {
  const [patientDetails, setPatientDetails] = useState(null);
  const [selectedKit, setSelectedKit] = useState(null);
  const tabs = ['General', 'Clinical Insights', 'Orders']; // Add your tab names here
  const [activeTab, setActiveTab] = useState('General'); // Default to 'Home'
  const [notes, setNotes] = useState([]);
  const [selectedTreatmentsDate, setSelectedTreatmentsDate] = useState('');
  const [treatmentAdded, setTreatmentAdded] = useState(false);

  const getInitials = (firstName, lastName) => {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }
  const flatData = [{ name: '', value: 5 }, { name: '', value: 5 }, { name: '', value: 5 }];
  const [treatments, setTreatments] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [dates, setDates] = useState([]);
  const [selectedNotesDate, setSelectedNotesDate] = useState('');
  const [notesDates, setNotesDates] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // New state for edit modal visibility
  const [editableDetails, setEditableDetails] = useState({
    GMReport: '',
    GenomicReport: '',
    OMReport: '',
    MLReport: '',
    MPReport: '',
    MUReport: '',
    TReport: '',
    PReport: '',
    First_Name: '',
    Last_Name: '',
    Gender: '',
    Age: '',
    Weight: '',
    Height: '',
    Email: ''
  });
  
  useEffect(() => {
    // Update editableDetails when patientDetails is fetched
    if (patientDetails) {
      setEditableDetails({
        GMReport: patientDetails.GMReport || '',
        GenomicReport: patientDetails.GenomicReport || '',
        OMReport: patientDetails.OMReport || '',
        MLReport: patientDetails.MLReport || '',
        MPReport: patientDetails.MPReport || '',
        MUReport: patientDetails.MUReport || '',
        TReport: patientDetails.TReport || '',
        PReport: patientDetails.PReport || '',
        First_Name: patientDetails.First_Name || '',
        Last_Name: patientDetails.Last_Name || '',
        Gender: patientDetails.Sex || '',
        Age: patientDetails.Age || '',
        Weight: patientDetails.Weight || '',
        Height: patientDetails.Height || '',
        Email: patientDetails.Email || ''
      });
    }
  }, [patientDetails]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    
  };

  const deleteNote = async (noteID) => {
    try {
      const response = await fetch(`https://service3.szapfs.org/deleteNote`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ NoteID: noteID }) // Sending the NoteID to be deleted
      });
  
      if (response.ok) {
        // Refresh the notes to show the updates
        setNotes(notes.filter(note => note.NoteID !== noteID));
        setNoteAdded(true); // Trigger a re-fetch to update UI
      } else {
        // Handle error...
        const errorResult = await response.json();
        console.error('Failed to delete note', errorResult.message);
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };
  

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditableDetails({
      GMReport: patientDetails.GMReport || '',
      GenomicReport: patientDetails.GenomicReport || '',
      OMReport: patientDetails.OMReport || '',
      MLReport: patientDetails.MLReport || '',
      MPReport: patientDetails.MPReport || '',
      MUReport: patientDetails.MUReport || '',
      TReport: patientDetails.TReport || '',
      PReport: patientDetails.PReport || '',
      First_Name: patientDetails.First_Name || '',
      Last_Name: patientDetails.Last_Name || '',
      Gender: patientDetails.Sex || '',
      Age: patientDetails.Age || '',
      Weight: patientDetails.Weight || '',
      Height: patientDetails.Height || '',
      Email: patientDetails.Email || ''
    });
  };
  
  
  const savePatientDetails = async () => {
    try {
      const response = await fetch(`https://service3.szapfs.org/updatePatientDetails`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, ...editableDetails })
      });
      // Handle response...
    } catch (error) {
      console.error('Error updating patient details:', error);
    }
    toggleEditModal();

    fetch(`https://service3.szapfs.org/platformGetPatientInsights?userId=${userId}`)
    .then(response => response.json())
    .then(data => setPatientDetails(data))
    .catch(error => {
      console.error('There was an error fetching the patient details:', error);
    });
  };



  const reportNameMapping = {
    OMReport: 'Oral Health',
    GMReport: 'Gut Health',
    MLReport: 'Lipidome Report',
    MPReport: 'Plasma Report',
    MUReport: 'Urine Report',
    TReport: 'Transcriptome Report',
    PReport: 'Proteomic Report',
  };
  
  const renderEditModal = () => {
    return (
      <div className={`edit-modal ${isEditModalOpen ? 'open' : ''}`}>
        <div className="edit-modal-content">
          <div className="close-modal" onClick={closeEditModal}>✖</div>
          <div className="edit-modal-grid">
            {Object.entries(editableDetails).map(([key, value]) => {
              if (key !== 'Email') {
                return (
                  <div key={key} className="edit-modal-input-container">
                    <label className="edit-modal-label">
                      {reportNameMapping[key] || key}: {/* Display mapped name or fallback to key */}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={handleInputChange}
                      className="editinput"
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {editableDetails.Email && (
            <div className="edit-modal-email-container">
              <label className="edit-modal-input-container">Email: </label>
              <input
                type="text"
                name="Email"
                value={editableDetails.Email}
                onChange={handleInputChange}
                className="editinput"
              />
            </div>
          )}
          <button className="savechanges" onClick={savePatientDetails}>Save Changes</button>
        </div>
      </div>
    );
  };
  


  useEffect(() => {
    fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTreatments(data);
        const treatmentDates = data.map(treatment => treatment.Date);
        setDates(treatmentDates);
        if (treatmentDates.length > 0) {
          setSelectedDate(treatmentDates[0]); // Set the selected date to the most recent one
        }
      })
      .catch((error) => console.error('Error fetching treatments:', error));
  }, [userId, treatmentAdded]); // Added treatmentAdded to dependencies
  

useEffect(() => {
  fetch(`https://service3.szapfs.org/getNotes/${userId}`)
    .then((response) => response.json())
    .then((data) => {
      setNotes(data);
      const notesDates = data.map(note => note.Date);
      setNotesDates(notesDates);
      if (notesDates.length > 0) {
        setSelectedNotesDate(notesDates[0]);
      }
    })
    .catch((error) => console.error('Error fetching notes:', error));
}, [userId]);

const [diagnoses, setDiagnoses] = useState([]);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};
const [noteAdded, setNoteAdded] = useState(false);



useEffect(() => {
  fetch(`https://service3.szapfs.org/getDiagnoses/${userId}`)
    .then((response) => response.json())
    .then((data) => setDiagnoses(data))
    .catch((error) => console.error('Error fetching diagnoses:', error));
}, [userId]);

const [treatmentsDates, setTreatmentsDates] = useState([]);

const renderNoteModal = () => {
  return (
    <div className={`note-modal ${isNoteModalOpen ? 'open' : ''}`}>
     <div className="custom-modal-backdrop">
<div className="custom-modal">
    <div className="custom-modal-header">
        <h3>Note</h3>
        <div className="close-modal" onClick={() => setIsNoteModalOpen(false)}>✖</div>
    </div>
    <div className="custom-modal-body">
    <textarea className="custom-modal-input"
          placeholder="Enter new note here..."
          value={newNoteText}
          onChange={(e) => setNewNoteText(e.target.value)}
        />         </div>
    <div className="custom-modal-footer">
        <button onClick={onClose} className="custom-modal-footer-button">Close</button>
<button className="custom-modal-footer-button primary" onClick={submitNote}>Add Treatment</button>
    </div>
</div>
</div>
    </div>
  );
};


useEffect(() => {
  if (noteAdded) {
    fetch(`https://service3.szapfs.org/getNotes/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setNotes(data);
        // with the most recent date first:
        const mostRecentDate = data[0] ? data[0].Date : '';
        setNotesDates(data.map(note => note.Date));
        setSelectedNotesDate(mostRecentDate); // Set to most recent date
        setNoteAdded(false); // Reset noteAdded to false
      })
      .catch((error) => console.error('Error fetching notes:', error));
  }
}, [noteAdded, userId, selectedNotesDate]); // Add selectedNotesDate if needed


useEffect(() => {
  if (treatmentAdded) {
    fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setNotes(data);
        // Assuming your backend returns the notes sorted by date,
        // with the most recent date first:
        const mostRecentDate = data[0] ? data[0].Date : '';
        setTreatmentsDates(data.map(treatment => treatment.Date));
        setSelectedTreatmentsDate(mostRecentDate); // Set to most recent date
        setTreatmentAdded(false); // Reset noteAdded to false
      })
      .catch((error) => console.error('Error fetching notes:', error));
  }
}, [treatmentAdded, userId, selectedTreatmentsDate]); // Add selectedNotesDate if needed

const [isTreatmentModalOpen, setIsTreatmentModalOpen] = useState(false);
const [newTreatment, setNewTreatment] = useState('');
const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);
const [newDiagnosis, setNewDiagnosis] = useState('');


// For treatments



const deleteTreatment = async (treatmentID) => {
  try {
    const response = await fetch(`https://service3.szapfs.org/deleteTreatment`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ TreatmentID: treatmentID })
    });

    if (response.ok) {
      // Refresh the treatments
      fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
        .then((response) => response.json())
        .then((data) => setTreatments(data))
        .catch((error) => console.error('Error fetching treatments:', error));
        setTreatmentAdded(true); // Trigger a re-fetch to update UI

    } else {
      const errorResult = await response.json();
      console.error('Failed to delete treatment', errorResult.message);
    }
  } catch (error) {
    console.error('Error deleting treatment:', error);
  }
};


const submitDiagnosis = async () => {
  const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

  try {
    const response = await fetch(`https://service3.szapfs.org/insertDiagnosis`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        UserID: userId,
        Diagnosis: newDiagnosis,
        Date: currentDate
      })
    });

    if (response.ok) {
      setNewDiagnosis(''); // Clear the input
      setIsDiagnosisModalOpen(false); // Close the modal
      // Refresh the diagnoses
      fetch(`https://service3.szapfs.org/getDiagnoses/${userId}`)
        .then((response) => response.json())
        .then((data) => setDiagnoses(data))
        .catch((error) => console.error('Error fetching diagnoses:', error));
    } else {
      const result = await response.json();
      console.error('Failed to add diagnosis', result.message);
    }
  } catch (error) {
    console.error('Error inserting diagnosis:', error);
  }
};


useEffect(() => {
  if (treatmentAdded) {
    fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTreatments(data);
        // Possibly process the data to set the selected date
      })
      .catch((error) => console.error('Error fetching treatments:', error));
    setTreatmentAdded(false); // Reset treatmentAdded to false
  }
}, [treatmentAdded, userId]); // Add treatmentAdded to the dependency array

const deleteDiagnosis = async (diagnosisID) => {
  try {
    const response = await fetch(`https://service3.szapfs.org/deleteDiagnosis`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ DiagnosisID: diagnosisID })
    });

    if (response.ok) {
      // Refresh the diagnoses
      fetch(`https://service3.szapfs.org/getDiagnoses/${userId}`)
        .then((response) => response.json())
        .then((data) => setDiagnoses(data))
        .catch((error) => console.error('Error fetching diagnoses:', error));
    } else {
      const errorResult = await response.json();
      console.error('Failed to delete diagnosis', errorResult.message);
    }
  } catch (error) {
    console.error('Error deleting diagnosis:', error);
  }
};





const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
const [newNoteText, setNewNoteText] = useState('');
const toggleNoteModal = () => {
  setIsNoteModalOpen(!isNoteModalOpen);
};
const submitNote = async () => {
  const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

  try {
    const response = await fetch(`https://service3.szapfs.org/insertNote`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        UserID: userId,
        Note: newNoteText, // Changed from Description to Note
        Date: currentDate
      })
    });

    if (response.ok) {
      setNewNoteText(''); // Clear the input after successful submission
      setIsNoteModalOpen(false); // Close the modal
      setNoteAdded(true); // Set noteAdded to true to trigger the re-fetch
      fetch(`https://service3.szapfs.org/getNotes/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setNotes(data);
          setSelectedNotesDate(currentDate); // Update the selected date to show the new note
        })
        .catch((error) => console.error('Error fetching notes:', error));
    } else {
      const result = await response.json();
      console.error('Failed to add note', result.message);
    }
  } catch (error) {
    console.error('Error inserting note:', error);
  }
};



const submitTreatment = async () => {
  const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

  try {
    const response = await fetch(`https://service3.szapfs.org/insertTreatment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        UserID: userId,
        Treatment: newTreatment,
        Date: currentDate
      })
    });

    if (response.ok) {
      setNewTreatment(''); // Clear the input after successful submission
      setIsTreatmentModalOpen(false); // Close the modal
      setTreatmentAdded(true); // Set treatmentAdded to true to trigger the re-fetch
      fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setTreatments(data);
          setSelectedTreatmentsDate(currentDate); // Update the selected date to show the new note
        })        
        .catch((error) => console.error('Error fetching treatments:', error));
    } else {
      const result = await response.json();
      console.error('Failed to add treatment', result.message);
    }
  } catch (error) {
    console.error('Error inserting treatment:', error);
  }
};










  const renderTabContent = () => {
    switch (activeTab) {
      case 'Clinical Insights':
        return <ClinicalInsights userId={userId} />;
        case 'Orders':
          return <Orders userId={userId} />;
          default: 
        return (
          <>
<div className="top-boxes">
  {['Mood', 'Blood Pressure', 'Sleep', 'Exercise'].map((title, index) => (
    <div key={index} className="chart-container">
      <div className="chart-title">{title}</div>
      <div className="box">
      <div className="no-data-overlay">No Data Registered</div>        
      <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={flatData} padding='10px'>
            <defs>
            <linearGradient id={`colorValue${index}`} x1="0" y1="0" x2="0" y2="1">
  <stop offset="0%" stopColor="#71b0f0" stopOpacity={1}/> {/* light pink/purple */}
  <stop offset="25%" stopColor="#71b0f0" stopOpacity={0.85}/> {/* light pink/purple */}
  <stop offset="50%" stopColor="#71b0f0" stopOpacity={0.7}/> {/* deep purple */}
  <stop offset="75%" stopColor="#71b0f0" stopOpacity={0.2}/> {/* deep purple */}
  <stop offset="100%" stopColor="#71b0f0" stopOpacity={0}/> {/* deep purple */}
</linearGradient>


            </defs>
            <XAxis dataKey="name" hide />
            <YAxis hide />
            <Area type="monotone" dataKey="value" stroke="#71b0f0" fillOpacity={0.8} fill={`url(#colorValue${index})`} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  ))}
</div>
<div className="middle-rectangle" style={{ overflowY: 'auto', minHeight: '20vh' }}>
<div className="table-header">
<div className="table-title">
  Treatments 
  <div className="add-button" onClick={() => setIsTreatmentModalOpen(true)}>+</div>
</div>  <select 
    className="date-dropdown" 
    value={selectedDate} 
    onChange={(e) => setSelectedDate(e.target.value)}
  >
    {[...new Set(dates)].map((date, index) => (
      <option key={index} value={date}>
        {formatDate(date)}
      </option>
    ))}
  </select>
</div>

{isTreatmentModalOpen && (
  <div className="custom-modal-backdrop">
          <div className="custom-modal">
              <div className="custom-modal-header">
                  <h3>Treatment</h3>
                  <div className="close-modal" onClick={() => setIsTreatmentModalOpen(false)}>✖</div>
              </div>
              <div className="custom-modal-body">
              <textarea className="custom-modal-input"
        placeholder="Enter new treatment here..."
        value={newTreatment}
        onChange={(e) => setNewTreatment(e.target.value)}
      />              </div>
              <div className="custom-modal-footer">
                  <button onClick={onClose} className="custom-modal-footer-button">Close</button>
      <button className="custom-modal-footer-button primary" onClick={submitTreatment}>Add Treatment</button>
              </div>
          </div>
      </div>
)}
<div className="treatments-container">
  {treatments.filter(treatment => treatment.Date === selectedDate).map((treatment, index) => (
    <div key={index} className="treatment-card">
      <div className="treatment-line"></div>
      <p>{treatment.Description}</p>
      <FontAwesomeIcon icon={faTrashAlt}  className="deleteicon" onClick={() => deleteTreatment(treatment.TreatmentID)} />
    </div>
  ))}
</div>

    </div>

    <div className="bottom-rectangle" style={{ overflowY: 'auto', minHeight: '20vh' }}>
    <div className="table-header">
    <div className="table-title">
  Notes 
  <div className="add-button" onClick={toggleNoteModal}>+</div>
</div>
{isNoteModalOpen && renderNoteModal()}

  
  <select 
    className="date-dropdown" 
    value={selectedNotesDate} 
    onChange={(e) => setSelectedNotesDate(e.target.value)}
  >

    
    {[...new Set(notesDates)].map((date, index) => (
      <option key={index} value={date}>
        {formatDate(date)}
      </option>
    ))}
  </select>

  
</div>
            <div className="treatments-container">
            {notes
  .filter(note => note.Date === selectedNotesDate)
  .map((note, index) => (
    <div key={note.NoteID} className="treatment-card">
      <div className="treatment-line"></div>
      <p>{note.Description}</p>
      <FontAwesomeIcon className="deleteicon" icon={faTrashAlt} onClick={() => deleteNote(note.NoteID)} />
    </div>
  ))
}

            </div>
          </div>










            
          </>
        );
    }
  };
  
  useEffect(() => {
    if (showModal) {
      fetch(`https://service3.szapfs.org/platformGetPatientInsights?userId=${userId}`)
        .then(response => response.json())
        .then(data => setPatientDetails(data))
        .catch(error => {
          console.error('There was an error fetching the patient details:', error);
        });
    }
  }, [userId, showModal]);

  const modalStyle = showModal ? { display: 'flex' } : { display: 'none' };

  return (

    <div className="modal" style={modalStyle}>
      
      
      <div className="modal-content">
  
        <div className="content-container">
<div className="left-section">
<div>

  <div className="allp" onClick={onClose}>  ← All Patients  </div>
  
  </div>

  {patientDetails ? (
    <>


<div className="patient-header">
  <div className="profile-section">
  <div className="patient-photo">
  {patientDetails.profile_picture ? (
  <img 
    src={/^https?:\/\//.test(patientDetails.profile_picture) ? 
         patientDetails.profile_picture : 
         `https://service3.szapfs.org/profile_pics/${patientDetails.profile_picture}`} 
    alt="Profile" 
    className="Ipatient-profile-pic" 
  />
) : (
  <div className="Ipatient-initials">
{patientDetails && patientDetails.First_Name && patientDetails.Last_Name
  ? `${patientDetails.First_Name.charAt(0).toUpperCase()}${patientDetails.Last_Name.charAt(0).toUpperCase()}`
  : ''} 
  </div>
)}

</div>

    <div className="patient-info">
<div className="patienti-name">
  {patientDetails.First_Name.charAt(0).toUpperCase() + patientDetails.First_Name.slice(1)}   {''} 
  {patientDetails.Last_Name.charAt(0).toUpperCase() + patientDetails.Last_Name.slice(1)}

  <span className="edit" onClick={toggleEditModal}>✎</span>

</div>

      <div className="patient-age">{patientDetails.Email}</div>

      <div className="patient-age">{patientDetails.Age} years</div>

    </div>
    {isEditModalOpen && renderEditModal()}

  </div>
  <div className="patient-stats">
    
    <div className="wstat-card">
      <div className="wstat-title">Gender</div>
    </div>
    <div className="wstat-card">
      <div className="wstat-title">Weight</div>
    </div>
    <div className="wstat-card">
      <div className="wstat-title">Height</div>
    </div>
  </div>


  <div className="patient-stats">
    
    <div className="stat-card">
      <div className="stat-valuee">{patientDetails.Sex}</div>
    </div>
    <div className="stat-card">
      <div className="stat-valuee">{patientDetails.Weight} kg</div>
    </div>
    <div className="stat-card">
      <div className="stat-valuee">{patientDetails.Height} cm</div>
    </div>
  </div>
</div>


      <div className="patient-info">

        <div className="important-info">      
        
        <div className="kitstitle">

        <p className="register" >   Registered Kits</p>


        <div className="kits">
  {(patientDetails.GenomicReport && patientDetails.GenomicReport !== "Purchase") && (
    <>
      <p className="patient-details">Whole Genome Sequencing</p>
      <p className="patient-details">Hereditary Cancer Screening</p>
      <p className="patient-details">Newborn Screening</p>
      <p className="patient-details">Carrier Screening</p>
      <p className="patient-details">Health Risks Screening</p>
      <p className="patient-details">Pharmacogenetic Screening</p>
    </>
  )}

  {(patientDetails.OMreport && patientDetails.OMreport !== "Purchase") && (
    <p className="patient-details">Oral Health Report</p>
  )}

  {(patientDetails.GMReport && patientDetails.GMReport !== "Purchase") && (
    <p className="patient-details">Gut Health Report</p>
  )}

  {!(patientDetails.GenomicReport && patientDetails.GenomicReport !== "Purchase") &&
   !(patientDetails.OMreport && patientDetails.OMreport !== "Purchase") &&
   !(patientDetails.GMReport && patientDetails.GMReport !== "Purchase") && (
    <p className="patient-details">No Omics Kits Registered</p>
  )}
</div>


        </div>

        </div>
        <div className="rstat-title">Clinicians</div>

      </div>
    </>
  ) : (
    <p></p>
  )}


</div>




{!selectedKit && (
  <>
{patientDetails && !patientDetails.Password && (
  <div className="aintactivate">
    <div className="notactivated">
      <div className="oops1">
        Oops!
      </div>
      <div className="oops2">
        This patient has not activated their account.
      </div>
    </div>
  </div>
)}


         <div className="middle-section">
              <div className="tab-container">
                {tabs.map(tab => (
                  <div
                    key={tab}
                    className={`tab ${tab === activeTab ? 'active-tab' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              {renderTabContent()}
            </div>


          
          {patientDetails ? (
          <div className="right-section">
<div class="top-box-container">
  <div class="top-box-overlay"></div>
  <div class="top-box">


    <div className="omics-title">Omics Kits Information</div>

    
    <div className="toptings">
      <div className="omics-kits">

      {patientDetails.GenomicReport && patientDetails.GenomicReport !== "Purchase" && (
      <div className="omics-kit" onClick={() => setSelectedKit('Genomic Sequencing')}>
        <div className="kit-icon"> <FontAwesomeIcon icon={faDna} /></div>
        <div className="kit-title">Genomic Sequencing </div>
      </div>
    )}

    {patientDetails.GMReport && patientDetails.GMReport !== "Purchase" && (
      <div className="omics-kit" onClick={() => setSelectedKit('Gut Health')}>
        <div className="kit-icon"> <FontAwesomeIcon icon={faBacteria} /></div>
        <div className="kit-title">Gut Health Microbiome </div>
      </div>
    )}


    {patientDetails.OMreport && patientDetails.OMreport !== "Purchase" && (
      <div className="omics-kit" onClick={() => setSelectedKit('Oral Health')}>
        <div className="kit-icon"> <FontAwesomeIcon icon={faGrinTongueWink} /></div>
        <div className="kit-title">Oral Health Microbiome </div>
      </div>
    )}      
   
      <div className="omics-kit" onClick={() => setSelectedKit('Cognitive')}>
        <div className="kit-icon"> <FontAwesomeIcon icon={faBrain} /></div>
        <div className="kit-title">Brain Health Analysis </div>
      </div>

    </div>
    </div>
  </div>
</div>

            


            <div className="bottom-box" style={{ overflowY: 'auto', minHeight: '20vh' }}>



              
          <div className="table-header">




          <div className="table-title">
      Past Diagnosis 
      <div className="add-button" onClick={() => setIsDiagnosisModalOpen(true)}>+</div>
    </div>          </div>

          {isDiagnosisModalOpen && (
            <div className="custom-modal-backdrop">
<div className="custom-modal">
    <div className="custom-modal-header">
        <h3>Diagnosis</h3>
        <div className="close-modal" onClick={() => setIsDiagnosisModalOpen(false)}>✖</div>
    </div>
    <div className="custom-modal-body">
    <textarea className="custom-modal-input"
   placeholder="Enter new diagnosis here..."
   value={newDiagnosis}
   onChange={(e) => setNewDiagnosis(e.target.value)}
        />         </div>
    <div className="custom-modal-footer">
        <button onClick={onClose} className="custom-modal-footer-button">Close</button>
<button className="custom-modal-footer-button primary" onClick={submitDiagnosis}>Add Treatment</button>
    </div>
</div>
</div>
  )}


  {Object.entries(diagnoses.reduce((acc, diagnosis) => {
      if (!acc[diagnosis.Date]) {
        acc[diagnosis.Date] = [];
      }
      acc[diagnosis.Date].push(diagnosis);
      return acc;
    }, {})).map(([date, diagnosisList]) => (
      <div key={date} className="diagnosis-group">
        <h4>{formatDate(date)}</h4>


        <div key={date} className="diagnosiss">

        {diagnosisList.map((diagnosis, index) => (
          <div key={index} className="diagnosis-card">
            <p>{diagnosis.Description}   <FontAwesomeIcon icon={faTrashAlt} className="deleteicon" onClick={() => deleteDiagnosis(diagnosis.DiagnosisID)} />
</p>
          </div>
        ))}          </div>

      </div>
    ))}


        </div>

          </div>
) : (
    <p></p>
  )}




          </>
)}




{selectedKit && (
  <div className="KitScreen">
                <div className="wallp" onClick={() => setSelectedKit('')}>  ←
                </div>

                {selectedKit === 'Genomic Sequencing' && <Genomics report={patientDetails.GenomicReport} user={user} />}
    {selectedKit === 'Gut Health' && <Gut report={patientDetails.GMReport} />}
    {selectedKit === 'Oral Health' && <OralHealth report={patientDetails.OMreport}/>}
    {selectedKit === 'Cognitive' && <Cognitive user={user}/>}

  </div>
)}



        </div>
      </div>
    </div>
  );
  
}

export default PatientInsights;
