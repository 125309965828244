import React from 'react';
import './oral.css';

function Oral({ searchQuery }) {
  return (
    <div className="Oral">
      {/* Oral-related content */}
      <p>Oral content based on search: {searchQuery}</p>
    </div>
  );
}

export default Oral;
