import React from 'react';
import { motion } from 'framer-motion';
import './Sections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faCloudArrowUp, faNetworkWired, faCircleDollarToSlot, faGraduationCap, faMailBulk, faMailReply, faMailReplyAll } from '@fortawesome/free-solid-svg-icons';
import backgroundVideo from './b9221a50b1fd75c8e9bc55a7e688955a-720p-preview.mp4';
const HeroSection = () => {
  return (
    <section id="introduction" className="section hero-section">
      <video className="hero-background" autoPlay loop muted playsInline>
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <div className="hero-content">
        <motion.div
          className="hero-text"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ margin: "-100px" }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            className="purpose-text"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 0.6 }}
            viewport={{ margin: "-100px" }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            PURPOSE OF SZA PORTAL
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ margin: "-100px" }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Cloud gateway to pioneering <br/> multi-omics research
          </motion.h1>
          <motion.div
            className="purpose-text1"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 0.6 }}
            viewport={{ margin: "-100px" }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Redefine bioinformatics— your discoveries start here.
          </motion.div>

          <motion.div
            className="hero-buttons"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ margin: "-100px" }}
            transition={{ delay: 0.8, duration: 0.8 }}
          >
          </motion.div>
        </motion.div>
      </div>
      <motion.div 
        className="info-bar"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.8 }}
      >
        <div className="info-item">

        <div className="info-item1">
        <FontAwesomeIcon icon={faMailBulk} className="it1"/>

        <div className="it2">Get Updated</div>





        </div>
        <div className="info-item2">
          
        <div className="info-item2i">2024-12-09</div>


          Oncology Screening Tools Public Release
          
          </div>
        <div className="info-item3">
        This tool provides advanced oncology screening capabilities by analyzing VCF files for genetic mutations, biomarkers, and other indicators, leveraging the latest technology to support healthcare professionals in accurate and efficient diagnostics.
        </div>








        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
