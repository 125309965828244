import React, { useState, useEffect } from 'react';
import './DashCalendar.css';

function DashCalendar({ user }) {
  const appointmentsToday = 0; 
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());
  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      slots.push(`${hour}:00`);
    }
    return slots;
  };

  useEffect(() => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    const remainingDays = [];

    for (let day = today.getDate(); day <= lastDayOfMonth; day++) {
      const date = new Date(today.getFullYear(), today.getMonth(), day);
      remainingDays.push(date);
    }

    setDays(remainingDays);
  }, []);
  
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


  return (
    <div className="calendarDashCalendar">
      <div className="calendarTopRow">
        <div className="calendarColumnLeft">
          <div className="calendarUpcomingAppointments">Upcoming Appointments</div>
          <div className="calendarAppointmentsToday">You have {appointmentsToday} Appointments today</div>
        </div>
        <div className="calendarCreateAppointmentButton">+ Create Appointment</div>
      </div>
      <div className="calendarDayScroll">
      {days.map((date, index) => (
          <div
            key={index}
            className={`calendarDayContainer ${selectedDay === date.getDate() ? 'calendarSelectedDay' : ''}`}
            onClick={() => setSelectedDay(date.getDate())}
          >
            <div>{date.getDate()}</div>
            <div className="calendarMonthName">{monthNames[date.getMonth()]}</div>
          </div>
        ))}
      </div>
      <div className="calendarMainContent">
        <div className="calendarTimeSlots">
          {generateTimeSlots().map((slot, index) => (
            <div key={index} className="calendarTimeSlot">
              <span className="calendarTimeLabel">{slot}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashCalendar;
