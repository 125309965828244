import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faCloudArrowUp, faNetworkWired, faCircleDollarToSlot, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import deviceImage from './low_poly_abstract_design_0702.jpg';
import './Sections.css';
import { useMotionValue, AnimatePresence } from 'framer-motion';
import Counter from './Counter'; // Make sure to import the Counter component

const InformationSection = () => {
  return (
    <section id="information" className="section information-section">
      <div className="full-width-container">
        <div className="image-container">
          
          <img src={deviceImage} alt="Single Cell Multiomics" className="full-width-img" />
          
          <motion.div 
            className="floating-stats-card"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: false, margin: "-100px" }}
            transition={{ 
              duration: 0.8,
              ease: [0.22, 1, 0.36, 1]
            }}
          >
            <motion.div 
              className="stats-header"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ 
                duration: 0.6,
                delay: 0.3,
                ease: [0.22, 1, 0.36, 1]
              }}
            >
              <FontAwesomeIcon icon={faDna} className="stats-icon" />
              <span>Processed Samples</span>
            </motion.div>
            <motion.p 
              className="stats-subtext"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ 
                duration: 0.6,
                delay: 0.5,
                ease: [0.22, 1, 0.36, 1]
              }}
            >
              Multi-omics datasets processed through our SZA Portal, leveraging state-of-the-art computational pipelines for comprehensive biological analysis
            </motion.p>
            <motion.div 
              className="stats-number"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ 
                duration: 0.6,
                delay: 0.7,
                ease: [0.22, 1, 0.36, 1]
              }}
            >
              <span className="number">
                <Counter from={7000} to={10500} duration={3} />
              </span>
            </motion.div>
          </motion.div>
        </div>

        <div className="infodeck-content">
          <div className="webinar-cards">
            {[
              {
                icon: faCloudArrowUp,
                title: "Upload Data",
                description: "Our platform handles all major multi-omics data formats including RNA-seq, ATAC-seq, ChIP-seq, Hi-C, and proteomics data. Seamlessly upload raw sequencing files (FASTQ, BAM, BCL) or processed matrices. Advanced preprocessing with automated quality checks, adapter trimming, and format validation ensures your data is analysis-ready."
              },
              {
                icon: faNetworkWired,
                title: "Run Analysis",
                description: "Access our extensive suite of computational pipelines covering RNA-seq, ATAC-seq, ChIP-seq, Hi-C, proteomics, and integrated multi-omics analysis. Each pipeline incorporates cutting-edge tools and best practices, offering automated QC, batch correction, and comprehensive downstream analysis with interactive visualizations and detailed reports."
              },
              {
                icon: faCircleDollarToSlot,
                title: "Zero Cost",
                description: "All features and computational resources are completely free for academic research. Our platform is grant-funded to democratize multi-omics analysis across the scientific community. Process unlimited samples with no restrictions on storage or computation time. Advanced analysis capabilities without the infrastructure costs."
              },
              {
                icon: faGraduationCap,
                title: "Get Support",
                description: "Our expert bioinformatics team provides comprehensive support for all aspects of multi-omics analysis. Get guidance on pipeline selection, parameter optimization, and results interpretation. We offer custom pipeline development, integration of new tools, and specialized analysis workflows tailored to your research needs."
              }
            ].map((card, index) => (
              <motion.div 
                key={card.title}
                className="webinar-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, margin: "-100px" }}
                transition={{ 
                  duration: 0.6,
                  delay: index * 0.15,
                  ease: [0.22, 1, 0.36, 1]
                }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="card-header">
                  <div className="card-icon blue">
                    <FontAwesomeIcon icon={card.icon}/>
                  </div>
                  <h3>{card.title}</h3>
                </div>
                <p>{card.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformationSection;
