import React, { useState, useEffect } from 'react';
import './Dispersion.css';
import Cookies from 'js-cookie';

function Dispersion() {
  const [fileTypes, setFileTypes] = useState({});

  useEffect(() => {
    const fetchFileTypes = async () => {
      try {
        const response = await fetch('service1.szapfs.org/api/file-types', {
          headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setFileTypes(data);
      } catch (error) {
        console.error('Error fetching file types:', error);
      }
    };

    fetchFileTypes();
  }, []);

  return (
    <div className="Dispersion">
      {Object.entries(fileTypes).map(([type, percentage]) => (
        <div key={type} className="Disp-item">
          <div className="Disp-Title">{type || 'Unknown'}</div>
          <div className="Disp-Prog">
            <div className="Disp-Prog1" style={{ width: `${percentage}%`, backgroundColor: "#2596be" }}>



            </div>
          </div>
          <div className="Disp-p">{percentage}%</div>
        </div>
      ))}
    </div>
  );
}

export default Dispersion;
