import React, { useState, useEffect } from 'react';
import './filedashboard.css';
import FileManager from '../filetransfer/FileDashboard/FileManager.js';
import Transaction from '../filetransfer/FileDashboard/Transaction.js';
import Dispersion from '../filetransfer/FileDashboard/Dispersion.js';

import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid } from 'recharts';
import Cookies from 'js-cookie';

const COLORS = ['#0088FE', '#00C49F'];

const FileDashboard = () => {
  const [stats, setStats] = useState({ diskSpacePercentage: 0, uploads: 0, downloads: 0, monthlyUploads: [], monthlyDownloads: [] });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('service1.szapfs.org/api/stats', {
          headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  const data = [
    { name: 'Used Space', value: stats.diskSpacePercentage },
    { name: 'Free Space', value: 100 - stats.diskSpacePercentage },
  ];

  return (
    <div className='totald'>
      <div className='FDIN'>
        <div className='FDINL'>
          <div className='FDINLT'>
            <FileManager />
          </div>
          <div className='FDINLB'>
            <Transaction />
          </div>
        </div>
        <div className='FDINR'>
          <div className='FDINRI'>
          <div className='FDINRIT'>
          <div className='FDINRITT'>Remaining Disk Space</div>
          <div className='FDINRITB'>  

          <ResponsiveContainer width="100%" height="100%">
  <PieChart>
    <defs>
      <linearGradient id="gradientBlue" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: 'rgba(37, 150, 190, 1.0)', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: 'rgba(37, 150, 190, 0.1)', stopOpacity: 0.1 }} />
      </linearGradient>
    </defs>
    <Pie
  data={data}
  cx="50%"
  cy="50%"
  labelLine={false}
  outerRadius={45} // Adjust the outerRadius to make the circle smaller
  dataKey="value"
>
  <Cell key="cell-0" fill="rgba(255, 255, 255, 0.0)" /> {/* Free Space */}
  <Cell key="cell-1" fill="url(#gradientBlue)" /> {/* Used Space */}

</Pie>

    <Tooltip />
  </PieChart>
</ResponsiveContainer>


              </div> </div>

           
            <div className='FDINRIT'>
              <div className='FDINRITT'>Uploads This Month</div>

              <div className='FDINRITB'>  
              
                <ResponsiveContainer width="100%" height= "100%" >
                <AreaChart data={stats.monthlyUploads}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2596be" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#2596be" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                  <Area type="monotone" dataKey="uploads" stroke="#2596be" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>        </div> </div>


            <div className='FDINRIT'>
              <div className='FDINRITT'>Downloads This Month</div>

              <div className='FDINRITB'>  
              
                <ResponsiveContainer width="100%" height= "100%" >
                <AreaChart data={stats.monthlyDownloads}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2596be" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#2596be" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                  <Area type="monotone" dataKey="downloads" stroke="#2596be" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>        </div>



            
            </div>
          </div>
          <div className='FDINRI2'>
          <Dispersion />



          </div>
          <div className='FDINRI3'>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDashboard;
