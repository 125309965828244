// Orders.js
import React from 'react';
import './Orders.css';
import email from './emailphoto.png';
import m from './m.png';

function Image() {
  return (
    <div>
            <div className="Orders">
      <div className="ordercard">Unauthorized</div>
      <img src={email} alt="Login Logo" />
      <img src={m} alt="Login Logo" />

    </div>
    </div>
  );
}

export default Image;
