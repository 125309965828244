import React, { useState, useEffect } from "react";
import "./Newborn.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFlask, faDna, faBaby, faTooth, faDroplet, faEllipsisV, faLayerGroup,
  faChevronCircleRight,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

const NewbornModal = ({ isOpen, onClose, onSave, initialData, currentFields }) => {
  const [selectedGenes, setSelectedGenes] = useState([]);
  const [selectedClinician, setSelectedClinician] = useState("Dr. Gözde YEŞİL SAYIN");
  const [reportData, setReportData] = useState(null);
  const [filteredReportData, setFilteredReportData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isOpen && initialData) {
      const genesFromInitialData = initialData.split(",").map((gene) => gene.trim());
      setSelectedGenes(genesFromInitialData);
  
      // Extract clinician from initialData
      const existingClinician = genesFromInitialData.find((gene) => gene.startsWith("!"));
      if (existingClinician) {
        setSelectedClinician(existingClinician.replace(/!/g, "").trim());
      }
    }
  }, [isOpen, initialData]);
  

  useEffect(() => {
    if (isOpen && currentFields?.Preset) {
      // Fetch the newborn report when modal opens
      fetch('https://service3.szapfs.org/grabnewbornreport', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Preset: currentFields.Preset }),
      })
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setFilteredReportData(data); // Initially, show all data
          console.log(currentFields)


        })
        .catch((error) => console.error('Error fetching report data:', error));
    }
  }, [isOpen, currentFields]);

  useEffect(() => {
    if (reportData) {
      const query = searchQuery.toLowerCase();
      const filtered = reportData.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(query)
        )
      );
      setFilteredReportData(filtered);
    }
  }, [searchQuery, reportData]);

  const handleGeneClick = (gene) => {
    if (!selectedGenes.includes(gene)) {
      setSelectedGenes([...selectedGenes, gene]);
    } else {
      setSelectedGenes(selectedGenes.filter((g) => g !== gene));
    }
  };

  const handleClinicianChange = (event) => {
    setSelectedClinician(event.target.value);
  };
  

  const formattedOutput = (() => {
    const existingClinician = selectedGenes.find((gene) => gene.startsWith("!"));
    const updatedGenes = existingClinician
      ? selectedGenes.map((gene) =>
          gene.startsWith("!") ? `!${selectedClinician}!` : gene
        )
      : [...selectedGenes, `!${selectedClinician}!`];
    return updatedGenes.join(", ");
  })();
  
  if (!isOpen) return null;

  return (
    <div className="newborn-modal-overlay">
      <div className="newborn-modal-container">
        <div className="NMT">
          <div className="NewbornProfile">
            <div className="NewbLeft">
              <div className="NewbLC">
                <FontAwesomeIcon icon={faBaby} />
              </div>
            </div>
            <div className="NCF">
              {currentFields?.PatientFirstName && (
                <div className="NCFName">{currentFields.PatientFirstName}</div>
              )}
              {currentFields?.PatientGender && (
                <div className="NCFSubName">{currentFields.PatientGender}</div>
              )}
              {currentFields?.PatientDateOfBirth && (
                <div className="NCFSubName1">{currentFields.PatientDateOfBirth}</div>
              )}
            </div>
            <div className="NCFR">
              <div className="NCFRt">
                Signing Clinician
                <div>
                  <select
                    id="signingClinician"
                    className="NCFRtd"
                    value={selectedClinician}
                    onChange={handleClinicianChange}
                  >
                    <option value="Dr. Gözde YEŞİL SAYIN">Dr. Gözde YEŞİL SAYIN</option>
                    <option value="Dr. Ahmet YEŞİLYURT">Dr. Ahmet YEŞİLYURT</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="newborn-modal-button"
            onClick={() => onSave(formattedOutput)}
          >
            X
          </div>
        </div>

        <div className="NMS">
          <input 
            type="text" 
            className="NMSsearch" 
            placeholder="Search..." 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="NMM">
          {filteredReportData && (
            <div className="newborn-report-list">
              {Object.values(
                filteredReportData.reduce((groups, row) => {
                  if (!groups[row.Genes]) {
                    groups[row.Genes] = {
                      Genes: row.Genes,
                      HGVSc: row.HGVSc,
                      HGVSp: row.HGVSp,
                      Zygosity: row.Zygosity,
                      Diseases: {},
                    };
                  }
                  if (!groups[row.Genes].Diseases[row.Disease]) {
                    groups[row.Genes].Diseases[row.Disease] = row.Inheritances;
                  }
                  return groups;
                }, {})
              ).map((group, index) => (
                <div
                  className="gene-group"
                  key={index}
                  onClick={() => handleGeneClick(group.Genes)}
                  style={{
                    border: selectedGenes.includes(group.Genes)
                      ? "3px solid #2595be7e"
                      : "3px solid white",
                  }}
                >
                  <div className="GRN">
                    {selectedGenes.includes(group.Genes) && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="floating-checkmark"
                      />
                    )}
                    <div className="grn-title">
                      <h3>{group.Genes}</h3>
                    </div>
                    <div className="grn-details">
                      <small className="grn-detail-title">HGVSc</small>
                      <div className="grn-detail-item">
                        <div className="grn-detail-value">{group.HGVSc}</div>
                      </div>
                      <small className="grn-detail-title">HGVSp</small>
                      <div className="grn-detail-item">
                        <div className="grn-detail-value">{group.HGVSp}</div>
                      </div>
                      <small className="grn-detail-title">Zygosity</small>
                      <div className="grn-detail-item">
                        <div className="grn-detail-value">{group.Zygosity}</div>
                      </div>
                    </div>
                  </div>
                  <div className="GRNR">
                  <div className="NWB-container">
  {Object.entries(group.Diseases).map(([disease, inheritances]) => (
    <div className="NWB-box" key={disease}>
      <strong>{disease}</strong>
      {!inheritances.includes("No matched") && !inheritances.includes("Not provided") && (
  <div className="NWB-inheritances">
    {inheritances}
  </div>
)}

    </div>
  ))}
</div>

                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="NMB">
          <div className="newborn-modal-input">
          {selectedGenes.length > 0
  ? selectedGenes
      .filter(gene => !gene.includes("!")) // Exclude genes inside exclamation points
      .map((gene, index) => (
        <span key={index} className="NWBgeneitem">
          {gene}
          {index < selectedGenes.filter(g => !g.includes("!")).length - 1 && " "}
        </span>
      ))
  : ""}

          </div>
        </div>
      </div>
    </div>
  );
};

export default NewbornModal;
