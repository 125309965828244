import React from 'react';
import './Sidebar.css';
import logo from './BLS.png';
import useUserData from '../useUserData'; // Correct import path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faFolderClosed, faHome, faUserFriends } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ setActiveComponent, slideOutSidebar }) => {
  const user = useUserData();

  return (
    <div className='sidebarcontent'>
      <div className="SBFL">
        <div className="SBFLL">
          <img src={logo} alt="Logo" className="cover" />
        </div>
        <div className="SBFLR" onClick={slideOutSidebar}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>
      </div>
      <div className="SBC">
        <div className='SBF' onClick={() => setActiveComponent('FileDashboard')}>  
        <div className="SBCIN"> <FontAwesomeIcon icon={faHome} />         </div> Dashboard 
        </div>
        <div className='SBF' onClick={() => setActiveComponent('ManageProjects')}> 
        <div className="SBCIN">  <FontAwesomeIcon icon={faFolderClosed} /> </div> Manage Projects
        </div>
        <div className='SBF' onClick={() => setActiveComponent('ManageAccounts')}>  
        <div className="SBCIN">  <FontAwesomeIcon icon={faUserFriends} /> </div> Manage Accounts
        </div>

        
      </div>
      <div className="SBBF">
      <div className="SBBFI">

      </div>     
      </div>
    </div>
  );
};

export default Sidebar;
