import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './Tools.css';
import generiskproImage from '../LandingContent/Generiskpro.png';
import Oncorisk1 from '../LandingContent/Oncorisk1.png';
import Oncorisk2 from '../LandingContent/Oncorisk2.png';
import Oncorisk3 from '../LandingContent/Oncorisk3.png';

import WGS1 from '../LandingContent/WGS1.png';
import WGS2 from '../LandingContent/WGS2.png';
import WGS3 from '../LandingContent/WGS3.png';

const toolCards = [
  {
    title: "GeneRiskPro",
    subtitle: "Advanced statistical analysis for identifying significantly altered genes and pathways between experimental conditions",
    webImage: generiskproImage,
    pdfImages: [
      WGS1,
      WGS2,
      WGS3
    ],
    link: "/invalid"
  },
  {
    title: "OncoRisk",
    subtitle: "Comprehensive pathway analysis leveraging multiple databases to uncover enriched biological processes in tumour samples",
    webImage: generiskproImage,
    pdfImages: [
      Oncorisk1,
      Oncorisk2,
      Oncorisk3
    ],
    link: "/invalid"
  },

];

const ToolsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const carouselRef = useRef(null);

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setCurrentPdfIndex((prev) => 
        prev === toolCards[currentIndex].pdfImages.length - 1 ? 0 : prev + 1
      );
    }, 3000);

    return () => clearTimeout(fadeTimeout);
  }, [currentPdfIndex, currentIndex]);

  const handleNext = () => {
    if (currentIndex < toolCards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const getIndicatorClass = (index) => {
    if (index === currentIndex) return 'active';
    
    const distance = index - currentIndex;
    if (distance < 0) {
      return `before-${Math.min(Math.abs(distance), 3)}`;
    } else {
      return `after-${Math.min(Math.abs(distance), 3)}`;
    }
  };

  return (
    <section id="tools" className="section tools-section">
      <div className="tools-header">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, margin: "-100px" }}
          transition={{ duration: 0.6 }}
          className='tools-title'
        >
          Our Tools
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false, margin: "-100px" }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className='tools-description'

        >
          Explore our suite of advanced bioinformatics tools designed to accelerate your research. 
          From differential expression analysis to multi-omics integration, our platform provides 
          comprehensive solutions for complex biological data analysis.
        </motion.p>
      </div>
        
      <div className="tools-container">
        <div className="carousel-wrapper">
          <div 
            className="tools-carousel"
            ref={carouselRef}
            style={{ 
              transform: `translateX(calc(-${currentIndex * 100}% - ${currentIndex * 4}vh))`
            }}
          >
            {toolCards.map((tool, index) => (
              <motion.div
                key={tool.title}
                className="tool-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false, margin: "-100px" }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                whileHover={{ scale: 1.02 }}
              >
                <div className="tool-left">
                  <div className="tool-header">
                    <div className="tool-titleu">{tool.title}</div>
                    <p className="tool-subtitle">{tool.subtitle}</p>
                  </div>
                  <div className="tool-web-preview">
                    <img src={tool.webImage} alt={`${tool.title} web interface`} />
                  </div>
                  <Link to={tool.link} className="tool-link">
                    Learn More
                  </Link>
                </div>
                <div className="tool-right">
                  <div className="tool-pdf-preview">
                    {tool.pdfImages.map((img, imgIndex) => (
                      <img 
                        key={imgIndex}
                        src={img} 
                        alt={`${tool.title} PDF report ${imgIndex + 1}`} 
                        style={{
                          opacity: currentIndex === index && (
                            imgIndex === currentPdfIndex ? 1 :
                            imgIndex === (currentPdfIndex === 0 ? tool.pdfImages.length - 1 : currentPdfIndex - 1) ? 0 : 0
                          ),
                          transition: 'opacity 1.5s ease-in-out',
                          zIndex: currentIndex === index && imgIndex === currentPdfIndex ? 1 : 0
                        }}
                      />
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="carousel-controls">
          <button 
            className="carousel-nav-button" 
            onClick={handlePrev}
            style={{ opacity: currentIndex === 0 ? 0 : 1 }}
          >
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          <div className="carousel-indicators">
            {toolCards.map((_, index) => (
              <button
                key={index}
                className={`indicator-dot ${getIndicatorClass(index)}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
          <button 
            className="carousel-nav-button" 
            onClick={handleNext}
            style={{ opacity: currentIndex === toolCards.length - 1 ? 0 : 1 }}
          >
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsSection;
