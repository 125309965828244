import React, { useState, useEffect } from 'react';
import './PatientInfoQuick.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import ClinicalInsights from './ClinicalInsights';


function PatientInfoQuick({ userId }) {
  const [editableDetails, setEditableDetails] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [treatments, setTreatments] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [notes, setNotes] = useState([]);
  const [notesDates, setNotesDates] = useState([]);
  const [selectedNotesDate, setSelectedNotesDate] = useState(null);
  const [diagnoses, setDiagnoses] = useState([]);
  const [treatmentAdded, setTreatmentAdded] = useState(false);
  const [selectedTab, setSelectedTab] = useState('PatientHistory');




  useEffect(() => {
    fetch(`https://service3.szapfs.org/getTreatments/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTreatments(data);
        const treatmentDates = data.map(treatment => treatment.Date);
        setDates(treatmentDates);

      })
      .catch((error) => console.error('Error fetching treatments:', error));
  }, [userId, treatmentAdded]);

  useEffect(() => {
    fetch(`https://service3.szapfs.org/getNotes/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setNotes(data);
        const notesDates = data.map(note => note.Date);
        setNotesDates(notesDates);

      })
      .catch((error) => console.error('Error fetching notes:', error));
  }, [userId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    fetch(`https://service3.szapfs.org/getDiagnoses/${userId}`)
      .then((response) => response.json())
      .then((data) => setDiagnoses(data))
      .catch((error) => console.error('Error fetching diagnoses:', error));
  }, [userId]);




  useEffect(() => {
    fetch(`https://service3.szapfs.org/platformGetPatientInsights?userId=${userId}`)
      .then(response => response.json())
      .then(data => setPatientDetails(data))
      .catch(error => {
        console.error('There was an error fetching the patient details:', error);
      });
  }, [userId]);

  const renderData = (title, data, itemRenderer) => (
    <div className="QuickyHolder">
      <div className="QuickyHolderTop">{title}</div>
      <div className="QuickyHolderBottom">
        {data.map((item, index) => itemRenderer(item, index))}
      </div>
    </div>
  );

  const renderTreatment = (treatment, index) => (
    <div className="treatmentItem" key={index}>
      <div className="treatmentDescription">{treatment.Description}</div>
      <div className="treatmentDate">{formatDate(treatment.Date)}</div>
    </div>
  );

  const renderNote = (note, index) => (
    <div className="treatmentItem" key={index}>
      <div className="treatmentDescription">{note.Description}</div>
      <div className="treatmentDate">{formatDate(note.Date)}</div>
    </div>
  );

  const renderDiagnosis = (diagnosis, index) => (
    <div className="treatmentItem" key={index}>
      <div className="treatmentDescription">{diagnosis.Description}</div>
      <div className="treatmentDate">{formatDate(diagnosis.Date)}</div>
    </div>
  );




  return (
  <div className="PatientInfoQuick">
    {patientDetails.First_Name && patientDetails.Last_Name ? (
      <div className="QuickyPFP">

<div className="QUICKYTOPHEADER">

        {patientDetails.profile_picture ? (
            <div className="QUICKYTOPHEADER-WRAPPER">

          <img src={/^https?:\/\//.test(patientDetails.profile_picture) ? patientDetails.profile_picture: `https://service3.szapfs.org/profile_pics/${patientDetails.profile_picture}`}alt="Profile"className="Profile-Quick-pfp"/>           </div>) : (
          <div className="QUICKYTOPHEADER-INIT">

            <div className="Profile-Quick-pfp">

            {`${patientDetails.First_Name.charAt(0).toUpperCase()}${patientDetails.Last_Name.charAt(0).toUpperCase()}`}

            </div>

          </div>
        )}


      <div className="QUICKYRIGHTSDIE">
      <div className="NamesQuick">
      <div >

  {`${patientDetails.First_Name.charAt(0).toUpperCase() + patientDetails.First_Name.slice(1).toLowerCase()} ${patientDetails.Last_Name.charAt(0).toUpperCase() + patientDetails.Last_Name.slice(1).toLowerCase()}`}
    </div>   
    
    <div className="MessageIcon">
    <FontAwesomeIcon icon={faMessage} />
    </div>

     </div>

    <div className="EmailsQuick">
  {`${patientDetails.Email.charAt(0).toUpperCase() + patientDetails.Email.slice(1).toLowerCase()}`}
    </div>
    <div className="EmailsQuick">
  {`Age: ${patientDetails.Age}`}
    </div>

    <div className="MetricsBottom">
    <div className="MetricsTitle">

    <div className="MetricsT">
        Gender
    </div>
    <div className="MetricsT">
        Weight
    </div>
    
    <div className="MetricsT">
        Height
    </div>

    
    </div>


    <div className="MetricsContent">

<div className="MetricsC">
    {patientDetails.Sex}
</div>
<div className="MetricsC">
{patientDetails.Weight} kg

</div>

<div className="MetricsC">
{patientDetails.Height} cm

</div>


    </div>


    </div>

</div>
</div>

<div className="QuickyPDFPBottom">
<div className="QuickyPDFChoose">
  <div
    className="Choose1"
    onClick={() => setSelectedTab('PatientHistory')}
    style={{
      color: selectedTab === 'PatientHistory' ? '#bebdbd' : 'white',
      backgroundColor: selectedTab === 'PatientHistory' ? 'white' : 'transparent',
    }}
  >
    Patient History
  </div>
  <div
    className="Choose1"
    onClick={() => setSelectedTab('ClinicalInsights')}
    style={{
      color: selectedTab === 'ClinicalInsights' ? '#bebdbd' : 'white',
      backgroundColor: selectedTab === 'ClinicalInsights' ? 'white' : 'transparent',
    }}
  >
    Clinical Insights
  </div>
</div>


      <div className="QuickyPDFContent">
        {selectedTab === 'PatientHistory' && (
          <>
            {renderData('Treatments', treatments, renderTreatment)}
            {renderData('Notes', notes, renderNote)}
            {renderData('Diagnoses', diagnoses, renderDiagnosis)}
          </>
        )}
        {selectedTab === 'ClinicalInsights' && <ClinicalInsights userId={userId} />}
      </div>
    </div>



      </div>



    ) : (
      ''
    )}
  </div>
);

}

export default PatientInfoQuick;
