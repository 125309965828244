import React from 'react';
import './genomics.css';
import ExpandableItemO from './ExpandableItemO';
import Gright from './Gright';  // Import the Gright component

function Genomics({ searchQuery, addSearchQuery }) {
  const handleContentClick = (content) => {
    addSearchQuery(content);
  };

  return (
    <div className="GenomicsO">
      <div className="GenomicsLeftO">
        {searchQuery.length === 0 ? (
          <div className="GenomicsL">
            <div className="GenomicsLo">Please Search In The Following Formats:</div>
            <div className="GenomicsLt">
              <div className="GenomicsLt">Gene:</div>
              <div className="GenomicsLtr" onClick={() => handleContentClick('H1-0')}>
                H1-0
              </div>
            </div>
            <div className="GenomicsLt">
              <div className="GenomicsLt">Band:</div>
              <div className="GenomicsLtr" onClick={() => handleContentClick('1q21.1')}>
                1q21.1
              </div>
            </div>
            <div className="GenomicsLt">
              <div className="GenomicsLt">Position:</div>
              <div className="GenomicsLtr" onClick={() => handleContentClick('20:50400208-58472677')}>
                20:50400208-58472677
              </div>
            </div>
            
          
            


          </div>
        ) : (
          searchQuery.map((query, index) => (
            <ExpandableItemO key={index} title={query} content={query} />
          ))
        )}
      </div>
      <div className="GenomicsRightO">
        <Gright /> {/* Include Gright component here */}
      </div>
    </div>
  );
}

export default Genomics;
