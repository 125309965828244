import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChartLine, faDna, faHospitalUser, faShieldAlt, faUserMd, faChartPie, faFlask, faClock, faUsers, faAward, faChevronDown, faHospital, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import HeroSection from './LandingPageSections/HeroSection';
import InformationSection from './LandingPageSections/InformationSection';
import ToolsSection from './LandingPageSections/ToolsSection';
import AboutSection from './LandingPageSections/AboutSection';
import './LandingPage.css';
import './LandingPageSections/Sections.css';
import logo from './2222.png';

function LandingPage() {
    const navigate = useNavigate();
    const token = Cookies.get('token');
    const [isScrolled, setIsScrolled] = useState(false);
    const [activeSection, setActiveSection] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [showScrollTop, setShowScrollTop] = useState(false);

    const sections = [
        'introduction',
        'information',
        'tools',
        'about'
    ];

    useEffect(() => {
        const handleWheel = (e) => {
            e.preventDefault();
            if (isScrolling) return;

            const direction = e.deltaY > 0 ? 1 : -1;
            const nextSection = Math.max(0, Math.min(sections.length - 1, activeSection + direction));
            
            if (nextSection !== activeSection) {
                scrollToSection(nextSection);
            }
        };

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;

            // Update navbar
            setIsScrolled(scrollPosition > 50);

            // Show/hide scroll to top button
            setShowScrollTop(scrollPosition > windowHeight);

            // Find current section
            const newSection = Math.floor((scrollPosition + windowHeight / 2) / windowHeight);
            setActiveSection(Math.min(newSection, sections.length - 1));
        };

        window.addEventListener('wheel', handleWheel, { passive: false });
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection, isScrolling, sections.length]);

    const scrollToSection = (index) => {
        if (isScrolling) return;
        
        setIsScrolling(true);
        setActiveSection(index);
        
        const element = document.getElementById(sections[index]);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        
        setTimeout(() => {
            setIsScrolling(false);
        }, 1000);
    };

    const scrollToTop = () => {
        scrollToSection(0);
    };

    const handleScrollClick = () => {
        if (activeSection === 0) {
            scrollToSection(1);
        } else {
            scrollToSection(0);
        }
    };

    return (
        <div className="landing-container">
            <nav className={`sza-navbar ${isScrolled ? 'scrolled' : ''}`}>
                <img src={logo} alt="SZA Portal" className="sza-nav-logo" />
                <div className="sza-nav-buttons">
                    {token ? (
                        <Link to="/dashboard" className="sza-nav-button primary">
                            Go to Dashboard
                        </Link>
                    ) : (
                        <Link to="/login" className="sza-nav-button primary">
                            Log In
                        </Link>
                    )}
                </div>
            </nav>

            <div className="sections-container">
                <HeroSection />
                <InformationSection />
                <ToolsSection />
                <AboutSection />
            </div>

            <div className="navigation-dots">
                <motion.div 
                    className="nav-progress"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <motion.div 
                        className="progress-line" 
                        style={{ 
                            height: `${activeSection * 4.35}vh`,
                        }} 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    />
                    <motion.div 
                        className="current-dot"
                        style={{
                            top: `${activeSection === 0 ? 0.4 : activeSection * 4.35}vh`,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    />
                </motion.div>
                <motion.div 
                    className="nav-titles"
                    initial="hidden"
                    animate="visible"
                    variants={{
                        visible: {
                            transition: {
                                staggerChildren: 0.1,
                                delayChildren: 0.8,
                            }
                        },
                        hidden: {}
                    }}
                >
                    {sections.map((section, index) => (
                        <motion.div
                            key={index}
                            variants={{
                                visible: { opacity: index > activeSection ? 0.3 : 1, x: 0 },
                                hidden: { opacity: 0, x: -20 }
                            }}
                            transition={{ duration: 0.5 }}
                            className={`nav-title ${
                                index === activeSection ? 'active' : 
                                index < activeSection ? 'before' : 'after'
                            }`}
                            onClick={() => scrollToSection(index)}
                        >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </motion.div>
                    ))}
                </motion.div>
            </div>

            <motion.button
                className={`scroll-button ${activeSection > 0 ? 'other-page' : ''}`}
                onClick={handleScrollClick}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.2, duration: 0.8 }}
            >
                {activeSection === 0 ? (
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5L12 19M12 19L19 12M12 19L5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                ) : (
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 19L12 5M12 5L19 12M12 5L5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                )}
            </motion.button>

            <AnimatePresence>
                {showScrollTop && (
                    <motion.div
                        className="scroll-top-button"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        onClick={scrollToTop}
                    >
                        <FontAwesomeIcon icon={faArrowUp} />
                    </motion.div>
                )}
            </AnimatePresence>


            <footer className="landing-footer">
                <div className="footer-content">
                    <div className="footer-section">
                        <img src={logo} alt="Logo" className="footer-logo" />
                        <p>Advanced medical tools for healthcare professionals</p>
                    </div>
                    <div className="footer-section">
                        <h3>Products</h3>
                        <ul>
                            <li onClick={() => navigate('/oncology')}>Oncology Tools</li>
                            <li onClick={() => navigate('/generiskpro')}>Generisk Pro</li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Company</h3>
                        <ul>
                            <li>About Us</li>
                            <li>Contact</li>
                            <li>Privacy Policy</li>
                            <li>Terms of Service</li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Connect</h3>
                        <div className="social-links">
                            {/* Add social media icons here */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 SZA Portal. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;