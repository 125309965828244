import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  BarChart, Bar, PieChart, Pie, Cell, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar
} from 'recharts';
import './CohortAnalysis.css';

function Cohort({ user }) {
  return (
    <div className="CohortFullSection">
      Unauthorized
      
   
    </div>
  );
}

export default Cohort;
