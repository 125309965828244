import React, { useState } from 'react';
import './smr.css';
import RunMutation from './RunMutation';

const SmrComponent = ({ selectedMutations, closeMutationModal }) => {
    const [runMutationData, setRunMutationData] = useState(null);

    const handleRunClick = (mutation) => {
        setRunMutationData(mutation);
    };

    const closeRunMutationModal = () => {
        setRunMutationData(null);
    };

    return (
        <>
            {selectedMutations.map((item, index) => (
                <div 
                    key={`mutation-modal-${index}`}
                    className="mutationOmodal"
                    style={{
                        position: 'fixed',
                        left: `${item.position.x}px`,
                        top: `${item.position.y}px`,
                    }}
                >
                    <div className="MTOPS">         
                        <div onClick={() => closeMutationModal(index)} className="MTOPSL">X</div>
                        <div className="MTOPSM">{item.mutation.chr}:{item.mutation.start}-{item.mutation.end}</div>
                        <div className="MTOPSR">
                            <div 
                                className="MTOPSMBUTTON"
                                onClick={() => handleRunClick(item.mutation)}
                            >
                                Run                            </div>
                        </div>
                    </div>
                    <div className="MTOPSB">
                        <div className="MTOPSB-row">
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Pathogenicity</div>
                                <div className="MTOPSB-content">{item.mutation.pathogenicity}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Genotype</div>
                                <div className="MTOPSB-content">{item.mutation.genotype}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Inheritance</div>
                                <div className="MTOPSB-content">{item.mutation.inheritance}</div>
                            </div>
                        </div>
                        <div className="MTOPSB-row">
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Decipher ID</div>
                                <div className="MTOPSB-content">{item.mutation.variant_id}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Patient ID</div>
                                <div className="MTOPSB-content">{item.mutation.patient_id}</div>
                            </div>
                            <div className="MTOPSB-item">
                                <div className="MTOPSB-title">Variant Class</div>
                                <div className="MTOPSB-content">{item.mutation.variant_class}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {runMutationData && (
                <RunMutation
                    mutation={runMutationData}
                    closeRunMutationModal={closeRunMutationModal}
                />
            )}
        </>
    );
};

export default SmrComponent;
