import React from 'react';
import './FmProgressLoader.css';

const FmProgressLoader = ({ progress }) => {
  // Round the progress to 1 decimal place
  const roundedProgress = progress.toFixed(1);

  return (
    <div className="fmprogress-container">
      <div className="fmprogress-bar" style={{ width: `${roundedProgress}%` }}>
        {roundedProgress}%
      </div>
    </div>
  );
};

export default FmProgressLoader;
