import React, { useState } from 'react';
import './SettingsModal.css';
import logo from './2222.png';
import { faDna, faBacteria, faPeopleArrows, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenomicOption from './GenomicOption';
import MetagenomicOption from './MetagenomicOption';
import BulkAnalysis from './BulkAnalysis';
import GenomeReportGeneration from './GenomeReportGeneration';
import Cohort from './Cohort';

const SettingsModal = ({ user, closeSettingsModal }) => {
  const [selected, setSelected] = useState('Genomic');
  const [subSelected, setSubSelected] = useState('GenomicModal');
  const [isGenomicExpanded, setIsGenomicExpanded] = useState(true);

  const handleSelection = (section) => {
    setSelected(section);
    if (section === 'Genomic') {
      setIsGenomicExpanded(true);
      setSubSelected('GenomicModal');
    } else if (section === 'GenomeReportGeneration') {
      setIsGenomicExpanded(false);
      setSubSelected('GenomeReportGenerationModal');
    } else if (section === 'Cohort') {
      setIsGenomicExpanded(false);
      setSubSelected('Cohort');
    } 
    
    else {
      setIsGenomicExpanded(false);
    }
  };
  const handleSubSelection = (subSection) => {
    setSubSelected(subSection);
  };

  return (
    <div className="settingspopup">
      <div className="optionsidebar">
      <div className="settingsclosemodal" onClick={closeSettingsModal}>✖</div>

        <div className="optionslogo">
          <img src={logo} alt="Logo" />
        </div>
        
        <div className={`optionsselect ${selected === 'Genomic' ? 'selected' : ''}`} onClick={() => handleSelection('Genomic')}>
          <FontAwesomeIcon icon={faDna} className="optionicon" />
          Genomic Section
        </div>
        
        <div className={`suboptions ${isGenomicExpanded ? 'expanded' : 'collapsed'}`}>
          <div className={`suboptionsselect ${subSelected === 'GenomicModal' ? 'selected' : ''}`} onClick={() => handleSubSelection('GenomicModal')}>
            Data Upload
          </div>
          <div className={`suboptionsselect ${subSelected === 'BulkAnalysis' ? 'selected' : ''}`} onClick={() => handleSubSelection('BulkAnalysis')}>
            Cohort Bulk Analysis
          </div>
          <div className={`suboptionsselect ${subSelected === 'GenomeReportGeneration' ? 'selected' : ''}`} onClick={() => handleSubSelection('GenomeReportGeneration')}>
            Report Generation
          </div>
        </div>

        <div className={`optionsselect ${selected === 'Metagenomic' ? 'selected' : ''}`} onClick={() => handleSelection('Metagenomic')}>
          <FontAwesomeIcon icon={faBacteria} className="optionicon" />
          Metagenomic Section
        </div>
        
        <div style={{ marginTop: '0vh' }}className={`optionsselect ${selected === 'Cohort' ? 'selected' : ''}`} onClick={() => handleSelection('Cohort')}>
          <FontAwesomeIcon icon={faPeopleGroup} className="optionicon" />
          Cohort Analysis Section
        </div>
      </div>

      <div   className="optionscontent">
        {selected === 'Genomic' && subSelected === 'GenomicModal' && <GenomicOption user={user} />}
        {selected === 'Genomic' && subSelected === 'BulkAnalysis' && <BulkAnalysis user={user} />}
        {selected === 'Genomic' && subSelected === 'GenomeReportGeneration' && <GenomeReportGeneration user={user} />}

        {selected === 'Metagenomic' && <MetagenomicOption user={user} />}
        {selected === 'Cohort' && <Cohort user={user} />}

      </div>
    </div>
  );
}

export default SettingsModal;
