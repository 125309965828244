import React from 'react';
import './PrivacyPolicy.css'; 

const PrivacyPolicy = () => {
    return (
        <div className='PPage'>
                    <div className='PPageIn'>

            <h1>Privacy Policy</h1>
            <p>Last updated: 18th October, 2022</p>

            <p>
                We, at SZA Longevity Inc. value individuality, we value each and every one of you who use our product.
                We appreciate the value of your trust and have chosen to do so by committing to respect your fundamental rights,
                including the right to privacy and data protection.
            </p>

            <p>
                As such, this Privacy Policy is designed to explain when and why we collect personal data about you,
                how we use it, the conditions under which we can disclose it to others, how we keep it safe and secure,
                and your rights and choices in relation to your information.
            </p>

            <p>
                It is important that you read this Privacy Policy together with any other Policy we will provide on certain,
                specific occasions when we are collecting or processing personal data about you, so that you are fully aware
                of how and why we are using your data.
            </p>

            <h2>Scope</h2>
            <p>
                This Privacy Policy applies to your access to and use of the SZALongevity® mobile device application and related services.
                It also describes the personal data we collect from these services and how it is used, including information we collect
                when you register for use of the application.
            </p>

            <h2>Who are we?</h2>
            <p>
                SZA Longevity Inc. is one of the global leaders in advancing the human longevity concept, on a mission to discover and
                harness the technological and biological unlocks that amplify span of life, health and high-performance.
            </p>

            <p>
                In this Policy, ‘SZA Longevity’, ‘we’, ‘us’ or ‘our’ means:
                SZA Longevity Inc. 02 West Broadway Suite 400 San Diego,
                CA 92101, USA
            </p>

            <p>
                Any questions regarding this Policy and our privacy practices should be sent to <a href="mailto:info@szalongevity.com">info@szalongevity.com</a>
            </p>

            <h2>For what purposes is your data processed?</h2>
            <p>
                Your data is processed for the following purposes:
            </p>

            <ul>
                <li>
                    <strong>Account registration:</strong> To register your user account. When registering your account, we process basic data such as your device ID and device type information to generate your unique account ID. We also ask you to create a password to keep your account secure.
                </li>
                <li>
                    <strong>Service delivery:</strong> To enable you to use our solution. Operating our solution entails that we process your IP address. By virtue of having this IP address, we will implicitly get insight as to the country and city from where you are accessing the service.
                </li>
                <li>
                    <strong>Analytics:</strong> To improve our service and generate insight for future products/services. The analytics platforms we leverage to monitor application traffic require basic device information to operate effectively such as IP address, device ID, and type.
                </li>
                <li>
                    <strong>Customer support:</strong> To manage our relationship with users on an ongoing basis. As a responsible mobile application provider, we believe it is our duty to support our customers in making the most out of our solution. To enlist our assistance, you can just e-mail us at the address above please, make sure to include your account ID in the subject line so we can review your message and respond accordingly.
                </li>
            </ul>

            <h2>On what basis do we process your data?</h2>
            <ul>
                <li>
                    <strong>Performance of a contract:</strong> When necessary to perform a contract to which you are a party or to take steps at your request before entering into a contract. For example, to register your user account.
                </li>
                <li>
                    <strong>Legal obligation:</strong> When necessary to perform a legal obligation. For example, to retain payment information for the purpose of complying with potential tax audits.
                </li>
                <li>
                    <strong>Consent:</strong> When you have consented to the specific processing activity. For example, to monitor the traffic to our application by virtue of placing a cookie on your device.
                </li>
            </ul>

            <h2>For how long do we retain your data?</h2>
            <ul>
                <li>
                    <strong>Account registration:</strong> To maintain your user account for as long as you want it to remain active and for a period of three years thereafter for the exercise or defense of legal claims.
                </li>
                <li>
                    <strong>Service delivery:</strong> To continue providing the service you subscribed to for as long as your account remains active and for a period of three years thereafter for the exercise or defense of legal claims.
                </li>
                <li>
                    <strong>Analytics:</strong> To derive insight from your interactions with our application or until you withdraw your consent.
                </li>
                <li>
                    <strong>Customer support:</strong> To manage our relationship with you for so long as your account remains active and for a period of three years thereafter for the exercise or defense of legal claims.
                </li>
            </ul>

            <h2>Who can receive your data?</h2>
            <p>
                In fulfilling the purposes outlined above, we expect to share your data with the following organizations:
            </p>
            <ul>
                <li>Google: In-app payment processing.</li>
                <li>Apple: In-app payment processing.</li>
                <li>Authorities: Regulatory compliance.</li>
            </ul>

            <h2>What are your rights?</h2>
            <p>
                When it comes to your data, you have been afforded the rights listed below and you can exercise them by contacting us at <a href="mailto:info@szalongevity.com">info@szalongevity.com</a>:
            </p>
            <ul>
                <li>
                    <strong>Right of access:</strong> You can request a copy of the personal data we hold about you. For example, if you created an account in our application, you can request that we send you a copy of the information we hold about you. However, if we are unable to associate the information with you, it can be excluded from our response.
                </li>
                <li>
                    <strong>Right to rectification:</strong> If we have entered your personal data incorrectly, you can request that we fix it.
                </li>
                <li>
                    <strong>Right to erasure (‘right to be forgotten’):</strong> You can request that we delete all your personal data. For example, you can request that we delete your account and any communication you have exchanged with us when contacting us for customer support purposes. However, be mindful that there are circumstances when such requests can be denied, including when we need to continue processing your data to comply with legal obligations imposed on us or for the exercise or defense of legal claims.
                </li>
                <li>
                    <strong>Right to restriction of processing:</strong> In the case we enter into an argument regarding the processing of your data, you can request that we stop processing it until the argument is resolved. For example, you can request we stop processing your data because its accuracy is contested. However, the restriction will last until this particular issue is resolved as opposed to indefinitely.
                </li>
            </ul>
            <h2>Health Connect Data</h2>
<p>
    <strong>a. Collection and Usage of Health Data:</strong> We value the privacy and security of our users. Our mobile application requests access to health-related permissions, specifically the ability to read active calories burned, exercise sessions, heart rate, sleep sessions, steps, total calories burned, and speed records. This data is collected for the sole purpose of enhancing the user experience and providing features related to health and fitness.
</p>
<p>
    <strong>b. Purpose of Health Data Access:</strong> Our app utilizes health-related permissions to track active calories burned, exercise sessions, heart rate, sleep sessions, steps, total calories burned, and speed. This data is processed locally on the user's device and is not shared with any third parties.
</p>
<p>
    <strong>c. User Consent:</strong> By using our app, you explicitly consent to the collection and usage of health-related data for the purposes outlined in this privacy policy. You have the option to grant or deny these permissions within the app settings.
</p>
<p>
    <strong>d. Security Measures:</strong> We implement robust security measures to protect the confidentiality and integrity of health-related data. This includes encryption and secure storage.
</p>
<p>
    <strong>e. Data Retention:</strong> We retain health-related data only for as long as necessary to fulfill the purposes outlined in this privacy policy. Users can request the deletion of their data.
</p>
<p>
    <strong>f. Third-Party Services:</strong> Our app does not share health-related data with any third-party services, advertisers, or external entities. We do not engage in the sale or exchange of user data.
</p>



            <h2>Changes to this Privacy Policy</h2>
            <p>
                We try to keep this Privacy Policy up to date at all times but, should we introduce any changes, we will endeavor to list them in this section for ease of reference.
            </p>

        </div>               <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>     </div>

    );
};

export default PrivacyPolicy;
