import React, { useState, useEffect } from 'react';
import './Cohort.css';
import Genomics from './Genomics';
import GeneGraphAnalysis from './GeneGraphAnalysis';
import PatientInfoQuick from './PatientInfoQuick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faFileCsv, faPen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

function BulkAnalysis({ user }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [expandedResults, setExpandedResults] = useState({});
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [editingMode, setEditingMode] = useState(false);
  const [editingTemplates, setEditingTemplates] = useState({});
  const [newTemplateName, setNewTemplateName] = useState('');
  const [patientDetails, setPatientDetails] = useState({});
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalTables, setTotalTables] = useState(0);
  const getProfilePicUrl = imageName => {
    // Check if imageName is a complete URL
    const isUrl = /^https?:\/\//.test(imageName);
    return isUrl ? imageName : `https://service3.szapfs.org/profile_pics/${imageName}`;
  };
  const getInitials = (firstName, lastName) => {
    const initials = [
        firstName ? firstName.charAt(0) : '',
        lastName ? lastName.charAt(0) : ''
    ].filter(Boolean).join('').toUpperCase(); // Use filter to remove empty strings

    return initials || 'N/A'; // Returns 'N/A' if both firstName and lastName are missing
};
  useEffect(() => {
    const clinicianId = user.id;
    fetch(`https://service3.szapfs.org/fetchTemplatesWithGenes/${clinicianId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.templates) {
          setTemplates(data.templates); // Save fetched templates
        }
      })
      .catch(error => console.error('Error fetching templates with genes:', error));
  }, [user.id]);

  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipStyle, setTooltipStyle] = useState({ display: 'none' });

  const showTooltip = (event, firstName, lastName) => {
    const tooltipText = `${firstName} ${lastName}`;
    setTooltipContent(tooltipText);

    // Find the closest parent grid-item-right element
    const gridItemRight = event.currentTarget.closest('.grid-item-right');
    const rect = gridItemRight.getBoundingClientRect();
    const tooltipX = rect.left + rect.width / 2;
    const tooltipY = rect.top - 15; // Positioning above the grid-item-right element

    setTooltipStyle({
      display: 'block',
      position: 'fixed',
      left: `${tooltipX}px`,
      top: `${tooltipY}px`,
      transform: 'translateX(-50%)', // Center the tooltip horizontally
    });
  };

  const hideTooltip = () => {
    setTooltipStyle({
      display: 'none',
    });

    console.log('hideTooltip:', {
      tooltipStyle: {
        display: 'none',
      }
    });
  };


  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        console.log('Fetching patient data...');
        const response = await fetch('https://service3.szapfs.org/genediseasedataanalysis/all-patient-data');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responseData = await response.json();
        console.log('Fetched data:', responseData); // Debug logging
        setData(responseData.data);
        setTotalTables(responseData.totalTables);
        setLoading(false);
        console.log('Patient data fetched successfully');
      } catch (error) {
        console.error('Error fetching patient data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchPatientData();
  }, []);

  useEffect(() => {
    if (tags.length === 0) {
      setSearchResults([]);
      return;
    }

    const resultsMap = new Map();

    data.forEach(({ gene, tables }) => {
      tags.forEach(tag => {
        if (gene && gene.toLowerCase() === tag.toLowerCase()) { // Ensure gene is defined and direct case-insensitive match
          if (!resultsMap.has(tag)) {
            resultsMap.set(tag, { keyword: tag, matchType: 'Gene', tables: new Set() });
          }
          const entry = resultsMap.get(tag);
          if (tables) {
            tables.forEach(table => entry.tables.add(table));
          }
        }
      });
    });

    const results = Array.from(resultsMap.values()).map(entry => ({
      keyword: entry.keyword,
      matchType: entry.matchType,
      tables: Array.from(entry.tables),
      count: Array.from(entry.tables).length
    }));

    console.log('Search results:', results); // Debug logging
    setSearchResults(results);
  }, [tags, data]);

  const handleAddTag = (event) => {
    if (event.key === 'Enter' && searchQuery.trim() !== '') {
      if (!tags.includes(searchQuery.trim())) {
        setTags([...tags, searchQuery.trim()]);
      }
      setSearchQuery('');
    } else if (event.key === 'Backspace' && searchQuery === '' && tags.length > 0) {
      setTags(tags.slice(0, -1));
    }
  };

  const handleTagClick = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const toggleExpand = async (keyword) => {
    setExpandedResults(prev => {
      const newExpandedResults = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      newExpandedResults[keyword] = !prev[keyword];
      return newExpandedResults;
    });

    if (!expandedResults[keyword]) {
      const tables = searchResults.find(result => result.keyword === keyword).tables;
      const response = await fetch('https://service3.szapfs.org/fetchPatientInformationFromGENOMEREPORTS', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ genomeReports: tables })
      });

      if (response.ok) {
        const patientData = await response.json();
        console.log('Fetched patient details:', patientData); // Debug logging

        const filteredPatientData = Object.entries(patientData).reduce((acc, [table, patients]) => {
          const filteredPatients = patients
            .filter(patient => patient.First_Name && patient.Last_Name) // Ensure both names are present
            .sort((a, b) => (a.First_Name ? -1 : 1)); // Sort with named patients at the top
          if (filteredPatients.length > 0) {
            acc[table] = filteredPatients;
          }
          return acc;
        }, {});

        setPatientDetails(prev => ({
          ...prev,
          ...filteredPatientData
        }));
      } else {
        console.error('Error fetching patient information:', response.statusText);
      }
    }
};

  
  const handleTableClick = (table) => {
    setSelectedTable(table);
    setSelectedUser(null);
  };
  
  const handleUserClick = (userId) => {
    setSelectedUser(userId);
  };

  const handleTemplateClick = (template) => {
    if (selectedTemplates.includes(template.TemplateID)) {
      setSelectedTemplates(selectedTemplates.filter(id => id !== template.TemplateID));
      setTags(tags.filter(tag => !template.Genes.includes(tag)));
    } else {
      setSelectedTemplates([...selectedTemplates, template.TemplateID]);
      const newTags = template.Genes.filter(gene => !tags.includes(gene));
      setTags([...tags, ...newTags]);
    }
  };
  const isTemplateHighlighted = (template) => {
    return tags.every(tag => template.Genes.includes(tag));
  };
  

  const handleEditTemplate = (templateId, templateName) => {
    setEditingTemplates(prev => ({
      ...prev,
      [templateId]: templateName
    }));
  };

  const handleSaveTemplateName = (templateId) => {
    const newTemplateName = editingTemplates[templateId];
    fetch(`https://service3.szapfs.org/updateTemplateName/${templateId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ newTemplateName })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTemplates(templates.map(template => template.TemplateID === templateId ? { ...template, TemplateName: newTemplateName } : template));
          setEditingTemplates(prev => {
            const newState = { ...prev };
            delete newState[templateId];
            return newState;
          });
        }
      })
      .catch(error => console.error('Error updating template name:', error));
    setEditingMode(!editingMode);
  };

  const handleDeleteTemplate = (templateId) => {
    fetch(`https://service3.szapfs.org/deleteTemplate/${templateId}`, { method: 'DELETE' })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTemplates(templates.filter(template => template.TemplateID !== templateId));
        }
      })
      .catch(error => console.error('Error deleting template:', error));
  };

  const handleSaveNewTemplate = () => {
    const templateName = newTemplateName || 'New Template';
    fetch('https://service3.szapfs.org/saveTemplateWithGenes', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clinicianId: user.id, templateName, genes: tags })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setTemplates([...templates, { TemplateID: data.templateId, TemplateName: templateName, Genes: tags }]);
          setNewTemplateName('');
        }
      })
      .catch(error => console.error('Error saving new template:', error));
  };

  const toggleEditingMode = () => {
    setEditingMode(!editingMode);
  };

  const handleReturn = () => {
    setSelectedTable(null);
    setSelectedUser(null);
  };
  
  return (
    <div className="cohort-container">
      <div className="cohort-left">
        <div className="cohort-left-top">
          <div className="something-unique-search-bar">
            <div className="searchshort">
              {tags.map((tag, index) => (
                <div className="tag" key={index} onClick={() => handleTagClick(index)}>
                  {tag}
                  <span className="tag-close-icon">x</span>
                </div>
              ))}
              <input
                type="text"
                placeholder="Search Gene"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value.toUpperCase())}
                onKeyDown={handleAddTag}
              />
            </div>
          </div>
        </div>
  
        <div className="cohort-left-mid">

        <div className="cohort-left-mid-edit">

          <div className="editbuttontemplate" onClick={toggleEditingMode}>
          <FontAwesomeIcon icon={editingMode ? faArrowLeft : faPen}  />
          </div>

          </div>

          <div className="cohort-left-mid-templates">
  <div className="cohort-left-mid-templates-each">
    {templates.map((template, index) => (
      <div
        key={index}
        className={`templatedefault ${selectedTemplates.includes(template.TemplateID) ? 'selected' : ''} ${isTemplateHighlighted(template) ? 'highlight' : ''}`}
      >
        {editingMode ? (
          <div className="template-editing-container">
            <span className="delete-template" onClick={() => handleDeleteTemplate(template.TemplateID)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
            <div className="cohorttemplate">
              <input
                className="templatenameinput"
                type="text"
                value={editingTemplates[template.TemplateID] !== undefined ? editingTemplates[template.TemplateID] : template.TemplateName}
                onChange={(e) => handleEditTemplate(template.TemplateID, e.target.value)}
              />
            </div>
            <div className="savebuttontemp" onClick={() => handleSaveTemplateName(template.TemplateID)}>
              <FontAwesomeIcon icon={faSave} />
            </div>
          </div>
        ) : (
          <span onClick={() => handleTemplateClick(template)}>{template.TemplateName}</span>
        )}
      </div>
    ))}
  </div>
</div>



          <div className="cohort-left-mid-save">
          <div className="editbuttontemplate">

          
            {tags.length > 0 ? (
              <div onClick={handleSaveNewTemplate}>             <FontAwesomeIcon icon={faSave}  />
              </div>
            ) : (
              <div className="csv-button"> <FontAwesomeIcon icon={faFileCsv}  /></div>
            )}
          </div>
          </div>


        </div>
  
        <div className="search-results-un">
      {searchResults.length === 0 ? (
        <div className="resultsnone">No results</div>
      ) : (
        searchResults.map((result, index) => (
          <div key={index} className="search-result">
            <div 
              className="result-summary" 
              onClick={() => toggleExpand(result.keyword)}
            >
  
  <div className="ending">
      <span>{result.count}</span>
      <span>{" / "}</span>
      <span>{totalTables}</span>
  </div>
  <div className="keywordstyle">
  
  <span>{result.keyword}</span>
  </div>
  
  <div className="ending">
  
  <span>{((result.count / totalTables) * 100).toFixed(2)}%</span>
  
  </div>
  
            </div>
            <>
        <div className={`result-details ${expandedResults[result.keyword] ? 'expanded' : ''}`}>
          <div className="grid-container">
            {result.tables
              .sort((a, b) => {
                const aHasNamedPatients = patientDetails[a] && patientDetails[a].some(patient => patient.First_Name && patient.Last_Name);
                const bHasNamedPatients = patientDetails[b] && patientDetails[b].some(patient => patient.First_Name && patient.Last_Name);
  
                if (aHasNamedPatients && !bHasNamedPatients) {
                  return -1;
                }
                if (!aHasNamedPatients && bHasNamedPatients) {
                  return 1;
                }
                return 0;
              })
              .map((table, i) => (
                <div key={i} className="grid-item">
                  <div onClick={() => handleTableClick(table)} className="tableidname">
                    <span>{table}</span>
                  </div>
  
                  <div className="grid-item-right">
                    {patientDetails[table] && patientDetails[table].length > 0 ? (
                      <div>
                        <div
                          key={0}
                          onClick={() => handleUserClick(patientDetails[table][0].User_ID)}
                          onMouseEnter={(e) => showTooltip(e, patientDetails[table][0].First_Name, patientDetails[table][0].Last_Name)}
                          onMouseLeave={hideTooltip}
                        >
                          {patientDetails[table][0].profile_picture ? (
                            <img
                              src={getProfilePicUrl(patientDetails[table][0].profile_picture)}
                              alt="Profile"
                              className="patient-profile-pic-gene"
                            />
                          ) : (
                            <div
                              className="patient-initials-wrapper-gene"
                              style={{
                                background: 'linear-gradient(to bottom, #20558a, #2596be)',
                                opacity: 0.9,
                              }}
                            >
                              <div className="patient-initials-gene">
                                {getInitials(patientDetails[table][0].First_Name, patientDetails[table][0].Last_Name)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <span> </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div id="tooltip" className="tooltipå" style={tooltipStyle}>{tooltipContent}</div>
      </>
          </div>
        ))
      )}
    </div>


      </div>
  
      <div className="cohort-right">
        <div className="cohort-right-bottom">
          {(selectedUser || selectedTable) && (
                  <div className="returnbutton" onClick={handleReturn}>

             <FontAwesomeIcon icon={faArrowLeft}  />

             </div>

          )}
          {selectedUser ? (
                  <div className="FullPatient">

            <PatientInfoQuick user={user} userId={selectedUser} onClose={() => setSelectedUser(null)} />
            </div>

          ) : selectedTable ? (
            <Genomics user={user} report={selectedTable} onClose={() => setSelectedTable(null)} />
          ) : (
            <GeneGraphAnalysis data={data} />
            )}
        </div>
      </div>
    </div>
  );
  
}

export default BulkAnalysis;
