import React from 'react';
import './metabolomics.css';

function Metabolomics({ searchQuery }) {
  return (
    <div className="Metabolomics">
      {/* Metabolomics-related content */}
      <p>Metabolomics content based on search: {searchQuery}</p>
    </div>
  );
}

export default Metabolomics;
