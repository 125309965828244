import React, { useState, useEffect, useMemo } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer,
  BarChart, Bar
} from 'recharts';
import './GeneGraphAnalysis.css';

function GeneGraphAnalysis() {
  const [top50ClinVar, setTop50ClinVar] = useState([]);
  const [top100ClinVar, setTop100ClinVar] = useState([]);
  const [leastClinVar, setLeastClinVar] = useState([]);

  useEffect(() => {
    console.log('Fetching data from endpoint...');
    fetch('https://service3.szapfs.org/genediseasedataanalysis/clinvar-count')
      .then(response => response.json())
      .then(data => {
        console.log('Data fetched:', data);
        setTop50ClinVar(data.top50ClinVar);
        setTop100ClinVar(data.top100ClinVar);
        setLeastClinVar(data.leastClinVar);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSmallValues = value => {
    const MIN_THRESHOLD = 0.01;
    return value < MIN_THRESHOLD ? MIN_THRESHOLD : value;
  };

  const areaData = useMemo(() => top50ClinVar.map(({ clinvar, alleleFrequencyInhouse }) => ({
    clinvar,
    alleleFrequencyInhouse: handleSmallValues(alleleFrequencyInhouse)
  })), [top50ClinVar]);

  const barDataTop100 = useMemo(() => top100ClinVar.map(({ clinvar, alleleFrequencyInhouse }) => ({
    clinvar,
    alleleFrequencyInhouse: handleSmallValues(alleleFrequencyInhouse)
  })), [top100ClinVar]);

  const barDataLeast50 = useMemo(() => leastClinVar.map(({ clinvar, alleleFrequencyInhouse }) => ({
    clinvar,
    alleleFrequencyInhouse: handleSmallValues(alleleFrequencyInhouse)
  })), [leastClinVar]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltipå">
          <div className="labelå">{`${label}  `}</div>
          <div className="labelt">{`Allele Frequency Inhouse: ${payload[0].value.toFixed(2)* 100}% `}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="gene-graph-analysis-container">
      <div className="gene-graph-analysis-text">
        Top 50 Allele Frequencies
      </div>
      <ResponsiveContainer width="90%" height={170}>
        <AreaChart data={areaData} isAnimationActive={true}>
          <XAxis dataKey="clinvar" tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <YAxis tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2596be" stopOpacity={1}/>
              <stop offset="100%" stopColor="#2596be" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="alleleFrequencyInhouse"
            stroke="#2596be"
            fill="url(#colorCount)"
            animationDuration={800}
          />
        </AreaChart>
      </ResponsiveContainer>

      <div className="gene-graph-analysis-text">
        Top 100 Allele Frequencies
      </div>
      <ResponsiveContainer width="90%" height={170}>
        <BarChart data={barDataTop100} isAnimationActive={true}>
          <XAxis dataKey="clinvar" tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <YAxis tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="colorCountBarTop" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2596be" stopOpacity={0.7}/>
              <stop offset="100%" stopColor="#2596be" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Bar dataKey="alleleFrequencyInhouse" fill="url(#colorCountBarTop)" />
        </BarChart>
      </ResponsiveContainer>

      <div className="gene-graph-analysis-text">
        Least 50 Allele Frequencies
      </div>
      <ResponsiveContainer width="90%" height={170}>
        <BarChart data={barDataLeast50} isAnimationActive={true}>
          <XAxis dataKey="clinvar" tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <YAxis tick={{ fontSize: 7 }} axisLine={false} tickMargin={15} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="colorCountBarLeast" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2596be" stopOpacity={0.7}/>
              <stop offset="100%" stopColor="#2596be" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Bar dataKey="alleleFrequencyInhouse" fill="url(#colorCountBarLeast)" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GeneGraphAnalysis;
