import React from 'react';
import './metagenomics.css';

function MetagenomicOption() {
  return (
    <div className='metafullsection'>
      Unauthorized

    </div>
  );
}

export default MetagenomicOption;
